import { useState, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  IconButton,
} from '@mui/material';
import { Alert } from '@mui/material';
import {
  apiKeySelector,
  apiNameSelector,
  creationDateSelector,
  ExpiryDateSelector,
} from 'store/slices/apiSlices/selector';
import { generateApiKey } from 'store/slices/apiSlices/thunks';
import { ControlledInput } from 'components';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, SubmitHandler } from 'react-hook-form';
import PrimaryButton from 'components/buttons/PrimaryButton';
import CustomModal from 'components/Modal';

type ApiFormValues = {
  apiKeyName: string;
};

function ApiTab() {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ApiFormValues>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClipboardSnackbarOpen, setIsClipboardSnackbarOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const apiKey = useSelector(apiKeySelector) || localStorage.getItem('apiKey');
  const apiName = useSelector(apiNameSelector) || localStorage.getItem('apiName');
  const createdAt = useSelector(creationDateSelector) || localStorage.getItem('createdAt');
  const expiresAt = useSelector(ExpiryDateSelector) || localStorage.getItem('expiresAt');

  useEffect(() => {
    if (apiKey) {
      localStorage.setItem('apiKey', apiKey);
      localStorage.setItem('apiName', apiName || '');
      localStorage.setItem('createdAt', createdAt || '');
      localStorage.setItem('expiresAt', expiresAt || '');
    }
  }, [apiKey, apiName, createdAt, expiresAt]);

  const handleGenerateKey: SubmitHandler<ApiFormValues> = ({ apiKeyName }) => {
    setIsGenerating(true);
    dispatch(generateApiKey(apiKeyName));
    setIsModalOpen(true);
    reset();
    setIsGenerating(false);
  };

  const handleCopyToClipboard = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      setIsClipboardSnackbarOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setIsClipboardSnackbarOpen(false);
  };

  return (
    <div className='w-full'>
      <div className='p-5 md:p-10 shadow-sm '>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>API</p>
      </div>
      <div className='p-8 dark:bg-dark-card-bg-old'>
        <div className='w-full rounded-md shadow p-4 bg-white'>
          <form onSubmit={handleSubmit(handleGenerateKey)}>
            <p className='text-gray-400 text-sm mb-3'>Enter a name to generate a unique API key</p>
            <div className='w-full flex gap-2 pb-6'>
              <ControlledInput
                control={control}
                name='apiKeyName'
                label='Name'
                error={!!errors.apiKeyName}
                placeholder='Name...'
                helperText={errors.apiKeyName ? 'This field is required' : ''}
              />
              <PrimaryButton
                type='submit'
                disabled={isGenerating}
                className='min-w-fit'
                variant='neutral'
              >
                {isGenerating ? 'Generating...' : 'Generate New Key'}
              </PrimaryButton>
            </div>
          </form>
        </div>

        {apiKey && (
          <CustomModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            className='max-w-max max-h-max dark:bg-darkBg mt-[10%]'
          >
            <div>
              <div className='mb-10 text-center'>
                <p className='text-[20px] pt-2 font-semibold mb-5'>API Key</p>
                <p className='p-4 border rounded-sm shadow-sm'>{apiKey}</p>
              </div>
              <div className='flex justify-center'>
                <button
                  className='py-3 px-7 bg-[#DFEDFF] hover:text-red-600 dark:bg-btn-dark dark:text-white rounded-[4px] text-darkBg text-sm md:text-base cursor-pointer mr-3'
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='py-3 px-7 bg-accent hover:bg-blue-800 rounded-[4px] text-white text-sm md:text-base cursor-pointer'
                  onClick={handleCopyToClipboard}
                >
                  Copy
                </button>
              </div>
            </div>
          </CustomModal>
        )}

        <Snackbar
          open={isClipboardSnackbarOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            icon={false}
            sx={{
              width: '100%',
              backgroundColor: 'primary.main',
              color: 'white',
            }}
          >
            API Key copied to clipboard!
          </Alert>
        </Snackbar>

        {apiKey && (
          <div className='w-full mt-4 bg-slate-100 text-sm text-gray-600 py-6'>
            <div className='p-4'>
              <p className='text-base mb-4'>
                <strong>Details</strong>
              </p>
              <p className='text-sm font-light'>
                <strong className='text-gray-500'>Name: </strong>
                {apiName ? apiName.charAt(0).toUpperCase() + apiName.slice(1) : ''}
              </p>
              <p className='text-sm font-light'>
                <strong className='text-gray-500'>Creation Date: </strong>
                {new Date(createdAt || '').toLocaleDateString('en-US')}
              </p>
              <p className='text-sm pb-6 font-light'>
                <strong className='text-gray-500'>Expiry Date: </strong>
                {new Date(expiresAt || '').toLocaleDateString('en-US')}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ApiTab;
