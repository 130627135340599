import classNames from 'classnames';

import Avatar, { TAvatarProps } from './Avatar';

export default function UserAvatar(props: TAvatarProps) {
  return (
    <Avatar
      className={classNames('rounded-sm', {
        'w-[40px] h-[40px]': !props.mediaId,
        'max-h-[40px] max-w-[40px]': !!props.mediaId,
      })}
      {...props}
    />
  );
}
