import { useState } from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ControlledInput } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ImageUpload from './ImageUpload';

const venueSchema = yup.object({
  name: yup.string().required('Venue name is required'),
  capacity: yup.number().required('Capacity is required').positive().integer(),
});

interface AddVenuesPageProps {
  onVenueSave: (venue: { name: string; capacity: number; floorPlan?: string }) => void;
  handleImageUpload: (image: string) => void;
}

const AddVenuesPage = ({ onVenueSave, handleImageUpload }: AddVenuesPageProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(venueSchema),
  });

  const [floorPlan, setFloorPlan] = useState<string | undefined>(undefined);

  const onSubmit = (data: any) => {
    const { name, capacity } = data;
    onVenueSave({
      name,
      capacity,
      floorPlan,
    });
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='mb-4'>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Add New Venue</p>
        </div>

        <div className='mt-7 px-2'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 mb-8'>
              <ControlledInput
                required
                name='name'
                label='Venue Name'
                control={control}
                error={!!errors.name}
                placeholder='Venue Name*'
                helperText={errors?.name?.message ?? ''}
              />

              <ControlledInput
                required
                name='capacity'
                label='Venue Capacity'
                control={control}
                error={!!errors.capacity}
                placeholder='Venue Capacity*'
                helperText={errors?.capacity?.message ?? ''}
                type='number'
              />
            </div>
            <ImageUpload onImageUpload={handleImageUpload} />

            <div className='mt-7'>
              <PrimaryButton className='w-full' variant='neutral' type='submit'>
                Add Venue
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddVenuesPage;
