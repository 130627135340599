import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from 'components/buttons/PrimaryButton';
import UploadBannerPhoto from 'components/shared/UploadBannerPhoto';
import { useAppDispatch } from 'hooks';
import { postNewTeam } from 'store/thunks';
import { TCreateTeamForm, TCreateTeamParams } from 'store/slices/teamsSlice/type';
import { createTeamSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';

interface AddTeamateProps {
  onClose: () => void;
  getAllTeamMembers: any;
}

const AddTeamate: React.FC<AddTeamateProps> = ({ onClose, getAllTeamMembers }) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState('');
  const [file, setFile] = useState('');
  const [getFileImg] = useState('');

  const {
    control: createTeamControl,
    handleSubmit: createTeamSubmit,
    formState: { errors },
  } = useForm<TCreateTeamForm>({
    resolver: yupResolver(createTeamSchema),
  });

  const onAddNewTeam = async (values: TCreateTeamForm) => {
    const params: TCreateTeamParams = {
      avaliability: values?.avaliability || '',
      position: values?.position || '',
      contact_info: values?.contactInfo || '',
      name: values?.name,
      media: file,
    };

    try {
      setIsSubmitting(true);
      await dispatch(postNewTeam(params));
      getAllTeamMembers();
      onClose();
    } catch (err: any) {
      // console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Add New Team</p>
          <div className='flex items-center pr-4 gap-6'>
            <IconButton>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className='mt-7'>
          <div className='flex flex-col gap-4'>
            <UploadBannerPhoto
              label='Upload Team Mascot'
              onChange={(value) => {
                setImage(value);
              }}
              getFileImg={getFileImg}
              getFile={setFile}
              value={image}
              required
              className='w-full md:w-1/2 '
            />
          </div>

          <div className='flex flex-col gap-8 mt-8'>
            <ControlledInput
              required
              control={createTeamControl}
              name='name'
              label='Team Name'
              error={!!errors.name}
              placeholder='Team Name*'
              helperText={(errors?.name?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={createTeamControl}
              name='position'
              label='Position'
              error={!!errors.position}
              placeholder='Position*'
              helperText={(errors?.position?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={createTeamControl}
              name='avaliability'
              label='Avaliability'
              error={!!errors.avaliability}
              placeholder='Avaliability*'
              helperText={(errors?.avaliability?.message as string) ?? ''}
            />
            <ControlledInput
              required
              control={createTeamControl}
              name='contactInfo'
              label='Contact Info'
              error={!!errors.contactInfo}
              placeholder='Contact Info*'
              helperText={(errors?.contactInfo?.message as string) ?? ''}
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        className='w-full md:w-fit self-end mt-2'
        variant='neutral'
        onClick={createTeamSubmit(onAddNewTeam)}
        disabled={isSubmitting}
      >
        Add Team
      </PrimaryButton>
    </div>
  );
};

export default AddTeamate;
