import React from 'react';
import * as THREE from 'three';
import { Seat } from './SeatMap';

interface Viewer3DProps {
  imageSrc: string;
  seatPositions: Seat[];
  toggleSeatStatus: (index: number) => void;
}

const Viewer3D: React.FC<Viewer3DProps> = ({ imageSrc, seatPositions, toggleSeatStatus }) => {
  const texture = new THREE.TextureLoader().load(imageSrc);

  return <div className='w-full mb-4 perspective'></div>;
};

export default Viewer3D;
