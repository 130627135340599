import React, { useRef, useCallback } from 'react';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ChangeCircleOutlined from '@mui/icons-material/ChangeCircleOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Switch } from '@mui/material';
import ControlledInput from 'components/shared/ControlledInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import Canvas from 'components/polygonAnnotations/Canvas';
import { TDeviceHistoryResponse, TDevicesBoundaryEdit } from 'store/slices/devicesSlice/type';
import { editBoundarySchema } from 'constants/Schemas';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Knob } from 'primereact/knob';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import {
  controlsSelector,
  devicesLoadingSelector,
  endStreamSelector,
  startStreamSelector,
  streamKeySelector,
} from 'store/slices/devicesSlice/selectors';
import devicesPlaceholderImg from 'assets/images/devicesPlaceholderImg.png';
import {
  devicesPanTilt,
  endStream,
  getBatchUrls,
  getDevicesHistoryByID,
  postDevicesVertices,
  startStream,
} from 'store/thunks';
import offline from 'assets/images/offline.png';
import { TDevice } from 'store/slices/devicesSlice/type';
import { TMediaFolder, TMediaParams } from 'api/media/types';

import CustomModal, { TModalProps } from '.';

import { devicesPlaceholderImg1 } from 'assets/images';
import PolygonDrawer, {
  PolygonData,
  Point,
  PolygonDrawerRef,
} from 'components/polygonAnnotations/PolygonDrawer';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PauseIcon,
  PlayIcon,
} from '@heroicons/react/24/outline';
import PolygonCard from 'components/polygonAnnotations/PolygonCard';

const MIN = 0;
const MAX = 100;

export type TDeviceModalProps = {
  device?: TDevice;
  deviceId?: number;
  deviceName?: string;
  mediaUrl?: string;
  modalType?: 'edit' | 'view';
  totalCount?: number;
};

export default function DeviceModal({
  deviceId,
  deviceName,
  mediaUrl,
  isOpen,
  onClose,
  modalType,
}: TDeviceModalProps & Omit<TModalProps, 'children'>) {
  //hooks
  const devicesLoading = useAppSelector(devicesLoadingSelector);
  const dispatch = useAppDispatch();
  const startStreaming = useAppSelector(startStreamSelector);
  const endStreaming = useAppSelector(endStreamSelector);
  const streamKey = useAppSelector(streamKeySelector);
  const panTiltControls = useAppSelector(controlsSelector);
  const [drawShape, setDrawShape] = useState(false);
  const [points, setPoints] = useState([]);
  const [getOriginalWH, setOriginalWH] = useState<any>();
  const [getWHOnScreen, setWHOnScreen] = useState<any>();
  const [isPolyComplete, setPolyComplete] = useState<boolean>(false);
  const [isLive, setIsLive] = useState(false);
  const [panVal, setPanVal] = useState<number>(MIN);
  const [tiltVal, setTiltVal] = useState<number>(MIN);
  const [images, setImages] = useState<
    { imgUrl: string; inferenceType: string; headcount: number;thumbnailUrl:string; isHighResLoaded:boolean; artifactType: 'image' | 'video' }[]
  >([]);
  const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0);
  const [currentMediaUrl, setCurrentMediaUrl] = useState(mediaUrl);
  const [artifactType, setArtifactType] = useState<'image' | 'video'>('image');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [deviceDetails, setDeviceDetails] = useState<any>(null);
  const [currentPoints, setCurrentPoints] = useState<Point[]>([]);
  const [polygons, setPolygons] = useState<PolygonData[]>([]);

  const [polygonsData, setPolygonsData] = useState<PolygonData[] | null>(null);
  const polygonDrawerRef = useRef<PolygonDrawerRef>(null);

  const handlePolygonComplete = (polygon: PolygonData[]) => {
    setPolygonsData(polygon);
    console.log('Polygon Data:', polygon);
  };

  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [atEndOfGallery, setAtEndOfGallery] = useState(false);
  const [atStartOfGallery, setAtStartOfGallery] = useState(true);
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [savedPolygons, setSavedPolygons] = useState<any[]>([]);

  const handleComplete = () => {
    if (polygonDrawerRef.current) {
      polygonDrawerRef.current.completePolygon();
    }
  };


  const inferenceTypeMapping: { [key: string]: string } = {
    crowd_counting: 'Audience Metrics',
    heat_map: 'Heat Map',
    object_tracking: 'Object Tracking',
  };

  const {
    control: deviceEditControl,
    handleSubmit: deviceControlSubmit,
    formState: { errors },
    reset,
  } = useForm<TDevicesBoundaryEdit>({
    resolver: yupResolver(editBoundarySchema),
  });

  const handleImageSelect = (imgUrl: string) => {
    setCurrentMediaUrl(imgUrl);
  };

  useEffect(() => {
    setCurrentMediaUrl(mediaUrl);
  }, [mediaUrl]);

  const getAllDevicesHistory = async (offset = 0) => {
    if (!deviceId) {
      console.error('Device ID is not available');
      return;
    }

    setLoading(true);

    const options = {
      limit: 10,
      offset,
      id: deviceId,
    };

    try {
      const response = await dispatch(getDevicesHistoryByID(options)).unwrap();
      const { data, total_count }: TDeviceHistoryResponse = response;

      const artifact_type = data.map((item) => item?.artifact_type);
      const mediaIds = data.map((item) => item?.image_url_id).join(',');
      const inferenceTypes = data.map((item) => item?.inference_type);
      const headcounts = data.map((item) => item?.headcount);

      const mediaFolder: TMediaFolder = 'analytics';
      const ThumbnailParams: TMediaParams = {
        folder: mediaFolder,
        media_url_ids: mediaIds,
        thumbnail:true
      };
      const ImagesParams: TMediaParams = {
        folder: mediaFolder,
        media_url_ids: mediaIds,
        thumbnail:false
      };

      const [thumbnails, fullSizeImages] = await Promise.all([
        dispatch(getBatchUrls(ThumbnailParams)).unwrap(),
        dispatch(getBatchUrls(ImagesParams)).unwrap(),
      ]);

      const combinedData = fullSizeImages.map((item, index) => ({
        imgUrl: item.url,
        thumbnailUrl: thumbnails[index]?.url || devicesPlaceholderImg,
        inferenceType: inferenceTypes[index] || 'Audience Metrics',
        headcount: headcounts[index] || 0,
        artifactType: artifact_type[index],
        isHighResLoaded:false
      }));

      const filteredData = combinedData.filter((image, index) => !loadedImages.has(index));

      setImages((prevImages) => [...prevImages, ...filteredData]);
      setCurrentMediaUrl(combinedData[0]?.imgUrl || undefined);
      setTotalHistoryCount(total_count);
    } catch (err: any) {
      setError(err.message || 'Error fetching device history');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && modalType === 'view') {
      if (!deviceId) {
        console.error('Device ID is required');
        return;
      }

      getAllDevicesHistory();
    }
  }, [isOpen, deviceId]);

  //functions
  const originalImageWidth = getOriginalWH?.width;
  const originalImageHeight = getOriginalWH?.height;

  const relativeCoordinates = useMemo(
    () =>
      getWHOnScreen?.width && getWHOnScreen?.height
        ? points?.map((coord: any) => {
            return [
              (coord[0] * originalImageWidth) / getWHOnScreen.width,
              (coord[1] * originalImageHeight) / getWHOnScreen.height,
            ];
          })
        : [],
    [getWHOnScreen?.height, getWHOnScreen?.width, originalImageHeight, originalImageWidth, points],
  );

  const roundedCoordinates = useMemo(
    () =>
      relativeCoordinates?.map((coord: any) => {
        return [parseFloat(coord[0]?.toFixed(2)), parseFloat(coord[1]?.toFixed(2))];
      }),
    [relativeCoordinates],
  );

  // const polyCoordinates = JSON.stringify(polygonsData, null, 2);
  // const polyCoordinates = polygons.map((p) => p.points);
  const polyCoordinates = JSON.stringify(polygons.map((p) => p.points));

  // const handlePanChange = (e: { value: number }) => {
  //   setPanVal(e.value);
  // };

  // const handleTiltChange = (e: { value: number }) => {
  //   setTiltVal(e.value);
  // };

  const handlePanChange = useCallback((e: { value: number }) => {
    setPanVal((prevPanVal) => {
      // Only update if the new value is different
      return e.value !== prevPanVal ? e.value : prevPanVal;
    });
  }, []);

  const handleTiltChange = useCallback((e: { value: number }) => {
    setTiltVal((prevTiltVal) => {
      // Only update if the new value is different
      return e.value !== prevTiltVal ? e.value : prevTiltVal;
    });
  }, []);

  const onSendBoundary = async (values: TDevicesBoundaryEdit) => {
    const hasValidPolygon = polygons?.some((polygon) => polygon.points?.length > 2);

    if (hasValidPolygon) {
      let sanitizedVertices: [number, number][] = [];

      try {
        const normalizePoint = (point: any): [number, number] => {
          if (point && typeof point === 'object') {
            if ('x' in point && 'y' in point) {
              return [
                typeof point.x === 'number' ? point.x : parseFloat(point.x),
                typeof point.y === 'number' ? point.y : parseFloat(point.y),
              ];
            }
            if (Array.isArray(point) && point.length === 2) {
              return [
                typeof point[0] === 'number' ? point[0] : parseFloat(point[0]),
                typeof point[1] === 'number' ? point[1] : parseFloat(point[1]),
              ];
            }
          }
          throw new Error(`Invalid point format: ${JSON.stringify(point)}`);
        };

        if (typeof polyCoordinates === 'string') {
          const parsedCoordinates = JSON.parse(polyCoordinates);
          sanitizedVertices = parsedCoordinates.flat().map(normalizePoint);
        } else if (Array.isArray(polyCoordinates)) {
          const flattenedCoordinates = (polyCoordinates as any[][]).flat();
          sanitizedVertices = flattenedCoordinates.map(normalizePoint);
        } else {
          throw new Error('Invalid polyCoordinates type. Expected string or array.');
        }

        const obj = {
          device_id: deviceId,
          vertices: sanitizedVertices,
          name: values?.name,
          description: values?.description,
          entity_type: 'people',
        };

        try {
          await dispatch(postDevicesVertices(obj));
          console.log('Request payload:', obj);

          // Add to the saved polygons list
          setSavedPolygons((prev) => [...prev, obj]);

          // Reset the form and drawing states
          reset({ name: '', description: '' });
          setPolygons([]);
          setCurrentPoints([]);
          setDrawShape(false); // Stop drawing
          toast.success('Boundary saved successfully.');
        } catch (err: any) {
          console.error('Error sending boundary:', err);
          toast.error('Failed to save boundary. Please try again.');
        }
      } catch (error) {
        console.error('Error processing vertices:', error);
        toast.error('Invalid vertices format. Please redraw the polygon.');
      }
    } else {
      toast.error('No polygon captured. Please draw a polygon or close the shape.');
    }
  };

  // const undo = () => {
  //   setPoints(points.slice(0, -1));
  //   setPolyComplete(false);
  //   reset({
  //     name: '',
  //     description: '',
  //   });
  // };

  const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const obj: any = {
      id: deviceId,
      pan: panVal,
      tilt: tiltVal,
    };
    if ((panVal || tiltVal) && deviceId) {
      dispatch(devicesPanTilt(obj));
    }
  }, [deviceId, dispatch, panVal, tiltVal]);

  // useEffect(() => {
  //   let str = '';
  //   const flatRoundedCoords: number[] = roundedCoordinates?.flat();
  //   flatRoundedCoords?.forEach(
  //     (value: number, index: number) =>
  //       (str += value + `${flatRoundedCoords?.length - 1 === index ? '' : '-'}`),
  //   );

  //   const values: any = {
  //     name: `${deviceName || 'Shape'}-${uuidv4()}`,
  //     description: `${deviceName || 'Shape'}-${uuidv4()}`,
  //   };
  //   if (isPolyComplete) {
  //     reset(values);
  //   }
  // }, [deviceName, isPolyComplete, reset, roundedCoordinates]);

  useEffect(() => {
    let str = '';

    // Initialize parsedCoordinates as an empty array
    let parsedCoordinates: any[] = [];

    // Parse and validate polyCoordinates
    if (polyCoordinates) {
      try {
        parsedCoordinates = JSON.parse(polyCoordinates);
      } catch (error) {
        console.error('Error parsing polyCoordinates:', error);
      }
    }

    // Construct the string from parsedCoordinates
    if (Array.isArray(parsedCoordinates) && parsedCoordinates.length > 0) {
      str = parsedCoordinates.map((polygon: any) => JSON.stringify(polygon)).join('-');
    }

    // Construct default values
    const values: { name: string; description: string } = {
      name: `${deviceName || 'Shape'}-${uuidv4()}`,
      description: `${deviceName || 'Shape'}-${uuidv4()}`,
    };

    // Reset form if currentPoints is empty
    if (currentPoints.length === 0) {
      reset(values);
    }
  }, [deviceName, currentPoints, reset, polyCoordinates]);

  useEffect(() => {
    if (deviceId) {
      if (isLive && !startStreaming) {
        dispatch(startStream(deviceId));
      } else if (!isLive && !endStreaming) {
        dispatch(endStream(deviceId));
      }
    }
  }, [isLive, dispatch, deviceId, startStreaming, endStreaming]);

  const handleScrollClick = (direction: 'forward' | 'backward') => {
    const gallery = document.getElementById('thumbnailGallery');
    if (!gallery) return;
    const scrollAmount = gallery.clientWidth; 
    const maxScrollPosition = gallery.scrollWidth - gallery.clientWidth; 
  
    const newPosition =
      direction === 'forward'
        ? Math.min(currentScrollPosition + scrollAmount, maxScrollPosition) 
        : Math.max(currentScrollPosition - scrollAmount, 0); 
  
  
    gallery.scrollTo({
      left: newPosition,
      behavior: 'smooth',
    });
  
    setCurrentScrollPosition(newPosition);
  
    setAtEndOfGallery(newPosition >= maxScrollPosition);
    setAtStartOfGallery(newPosition <= 0);
  };
  
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const gallery = event.target as HTMLElement;
    setCurrentScrollPosition(gallery.scrollLeft);

    setAtEndOfGallery(gallery.scrollLeft + gallery.clientWidth >= gallery.scrollWidth);
    setAtStartOfGallery(gallery.scrollLeft <= 0);
  };

  const handleImageLoad = (index: number, isHighRes: boolean ) => {
    if (isHighRes) {
      // Handle high-res image load
      setImages((prevImages) =>
        prevImages.map((item, i) =>
          i === index ? { ...item, isHighResLoaded: true } : item
        )
      );
    } 
      // Handle thumbnail load
      setLoadedImages((prev) => {
        const updated = new Set(prev);
        updated.add(index);
        return updated;
      });
    
  };
  
  useEffect(() => {
    if (loadedImages.size === images.length && images.length > 0) {
      setLoading(false);
    }
  }, [loadedImages, images.length, setLoading]);

  const handleEditPolygon = (index: number) => {
    const polygonToEdit = savedPolygons[index];
    reset({
      name: polygonToEdit.name,
      description: polygonToEdit.description,
    });
    setPolygons([polygonToEdit.vertices]);
    setDrawShape(true); // Allow re-drawing
  };

  const handleDeletePolygon = (index: number) => {
    setSavedPolygons((prev) => prev.filter((_, i) => i !== index));
    toast.success('Polygon deleted successfully.');
  };


  return (
    <div>
      {modalType === 'edit' ? (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          className='max-w-max h-full min-h-fit 2xl:max-h-fit lg:min-w-[900px] 2xl:min-w-[1200px] bg-white dark:bg-darkBg dark:opacity-100'
          // className='max-w-max h-full min-h-fit 2xl:max-h-fit lg:min-w-[900px] 2xl:min-w-[1200] dark:bg-darkBg'
        >
          <div
            id='clearScrollbar'
            className='overflow-y-auto bg-white dark:bg-black dark:bg-opacity-95'
            // className='overflow-y-auto'
          >
            <div className='flex justify-between items-center mb-1'>
              <p className='text-[24px] font-semibold'>Device Control</p>
              <CancelPresentationIcon className='opacity-50 cursor-pointer' onClick={onClose} />
            </div>
            <div className='flex items-center justify-between mt-5 p-1 rounded-md bg-gray-50 dark:bg-black dark:bg-opacity-10  pl-2'>
              <p className='text-[15px] font-semibold'>{isLive ? 'Go Offline' : 'Go Live'}</p>
              <Switch
                checked={isLive}
                onChange={(e) => {
                  const newLiveStatus = e.target.checked;
                  setIsLive(newLiveStatus);

                  if (deviceId) {
                    if (newLiveStatus) {
                      dispatch(startStream(deviceId));
                    } else {
                      dispatch(endStream(deviceId));
                    }
                  }
                }}
                color='primary'
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isLive ? (
              <>
                <div className='flex gap-3 lg:min-w-[900px] 2xl:min-w-[1200]'>
                  {/* left side - Canvas or image when live */}
                  <div
                    className='col-span-4 mt-2  w-3/4 bg-white shadow-md rounded-lg mx-auto'
                    style={{
                      minHeight: '500px',
                      height: '500px',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                    id=''
                  >
                    {!devicesLoading && drawShape === true ? (
                      <div className='absolute inset-0'>
                        <PolygonDrawer
                          // videoSource={streamKey || devicesPlaceholderImg}
                          //meetingId={currentMediaUrl}
                          src={isLive && currentMediaUrl ? currentMediaUrl : devicesPlaceholderImg}
                          ref={polygonDrawerRef}
                          type={isLive && currentMediaUrl ? 'video' : 'image'}
                          currentPoints={currentPoints}
                          setCurrentPoints={setCurrentPoints}
                          polygons={polygons}
                          setPolygons={setPolygons}
                          onPolygonComplete={handlePolygonComplete}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: getWHOnScreen?.height || '719px',
                          width: '100%',
                          minHeight: '719px',
                          minWidth: '560px',
                        }}
                      >
                        <div className='absolute inset-0 flex justify-center items-center'>
                          <CircularProgress size='6em' />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* right side - Device controls */}
                  {panTiltControls === false && (
                    <div className='col-span-2 mt-2 w-1/4 h-full shadow-md rounded-lg'>
                      <p className='text-[15px] p-3 border-[#F2F2F2] border-2 dark:border-border-dark-background'>
                        Device Control:
                      </p>
                      {/* Pan and Tilt controls */}
                      <div className='flex flex-col gap-8 justify-center items-center py-6'>
                        {/* Pan Knob */}
                        <div
                          className='flex flex-col items-center'
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onTouchStart={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <p className='font-semibold pb-2'>Pan</p>
                          <Knob
                            value={panVal}
                            onChange={handlePanChange}
                            size={window.innerWidth < 768 ? 80 : 120}
                            min={MIN}
                            max={MAX}
                            valueTemplate='{value}'
                            onWheel={(e) => e.preventDefault()}
                            onTouchMove={(e) => e.preventDefault()}
                          />
                          <p className='font-semibold mt-2'>Value: {panVal}</p>
                        </div>

                        {/* Tilt Knob */}
                        <div
                          className='flex flex-col items-center'
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onTouchStart={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <p className='font-semibold pb-2'>Tilt</p>
                          <Knob
                            value={tiltVal}
                            onChange={handleTiltChange}
                            size={window.innerWidth < 768 ? 80 : 120}
                            min={MIN}
                            max={MAX}
                            valueTemplate='{value}'
                            onWheel={(e) => e.preventDefault()}
                            onTouchMove={(e) => e.preventDefault()}
                          />
                          <p className='font-semibold mt-2'>Value: {tiltVal}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`flex flex-row justify-between items-center gap-4`}>
                  <div
                    className={`flex items-center gap-2 ${drawShape ? 'mt-2 pt-3' : 'mt-8 pt-4'}`}
                  >
                    {
                      <>
                        <div
                          className='lg:flex hidden items-center bg-brand rounded-md p-2 gap-2 cursor-pointer dark:border-none hover:border-brand border dark:bg-dark-card-bg-old transition-all'
                          onClick={handleComplete}
                        >
                          <p className='text-white font-semibold whitespace-nowrap text-[11px] tracking-wider dark:text-white'>
                            Complete
                          </p>
                          <ChangeCircleOutlined className='text-white dark:text-brand' />
                        </div>
                        <div
                          className='lg:flex hidden items-center bg-[#F0F7FF] rounded-md p-2 gap-2 cursor-pointer dark:border-none hover:border-brand border dark:bg-dark-card-bg-old transition-all'
                          onClick={() => {
                            setPolygons([]);
                            setCurrentPoints([]);
                          }}
                          // onClick={() => undo()}
                        >
                          <p className='text-accent font-semibold whitespace-nowrap text-[11px] tracking-wider dark:text-white'>
                            Clear
                          </p>
                          <ChangeCircleOutlined className='text-brand dark:text-white' />
                        </div>
                      </>
                    }
                  </div>

                  {/* Polygon Drawer Button */}
                  <div className='mt-2 flex justify-center'>
                    <PrimaryButton variant='primary' onClick={() => setDrawShape((prev) => !prev)}>
                      {drawShape ? 'Stop Drawing' : 'Draw Polygon'}
                    </PrimaryButton>
                  </div>
                </div>
                <div>
                  {drawShape && (
                    <>
                    <div className='col-span-2 mt-2 w-full h-full shadow-md rounded-lg'>
                      <p className='text-[24px] font-semibold mt-5'>Fill in boundary details:</p>
                      <div className='flex flex-row  gap-2 justify-between pt-5'>
                        <ControlledInput
                          required
                          control={deviceEditControl}
                          name='name'
                          label='Name'
                          error={!!errors.name}
                          placeholder='Name'
                          helperText={errors?.name?.message ?? ''}
                        />
                        <ControlledInput
                          required
                          control={deviceEditControl}
                          name='description'
                          label='Description'
                          error={!!errors.description}
                          placeholder='Description'
                          helperText={errors?.description?.message ?? ''}
                        />
                        <ControlledInput
                          required
                          control={deviceEditControl}
                          name='entity_type'
                          label='Entity Type'
                          error={!!errors.entity_type}
                          placeholder='Entity Type'
                          helperText={errors?.entity_type?.message ?? ''}
                          defaultValue='Audience Metrics'
                          disabled
                        />
                        <PrimaryButton
                          variant='neutral'
                          onClick={deviceControlSubmit(onSendBoundary)}
                        >
                          Save
                        </PrimaryButton>
                      </div>
                    </div>
                    <>
                      <div
                        className='lg:flex hidden items-center bg-brand rounded-md p-2 gap-2 cursor-pointer dark:border-none hover:border-brand border dark:bg-dark-card-bg-old transition-all'
                        onClick={handleComplete}
                      >
                        <p className='text-white font-semibold whitespace-nowrap text-[11px] tracking-wider dark:text-white'>
                          Complete
                        </p>
                        <ChangeCircleOutlined className='text-white dark:text-brand' />
                      </div>
                      <div
                        className='lg:flex hidden items-center bg-[#F0F7FF] rounded-md p-2 gap-2 cursor-pointer dark:border-none hover:border-brand border dark:bg-dark-card-bg-old transition-all'
                        onClick={() => {
                          setPolygons([]);
                          setCurrentPoints([]);
                        }}
                        // onClick={() => undo()}
                      >
                        <p className='text-accent font-semibold whitespace-nowrap text-[11px] tracking-wider dark:text-white'>
                          Clear
                        </p>
                        <ChangeCircleOutlined className='text-brand dark:text-white' />
                      </div>
                    </>
                    </>
                  )}

                  {/* List of Saved Polygons */}
                  <div className="mt-5 w-full">
      {savedPolygons.length > 0 ? (
        <div className="relative">
          <h3 className="text-lg font-bold mb-4">Saved Polygons</h3>

              <PolygonCard
                //key={}
                polygons={savedPolygons}
                //index={index}
                deviceEditControl={deviceEditControl}
                handleEditPolygon={handleEditPolygon}
                handleDeletePolygon={handleDeletePolygon}
              />

         

        </div>
      ) : (
        <p className="text-gray-500"></p>
      )}
    </div>
                </div>
              </>
            ) : (
              <div className='flex flex-col justify-center items-center h-[900px] lg:min-w-[900px] 2xl:min-w-[1200]'>
                <img src={offline} alt='offline' className='h-[400px] -mt-96' />
                <p className='text-[18px] font-semibold text-gray-600'>
                  Your device is offline, go offline to view live feed
                </p>
              </div>
            )}
          </div>
        </CustomModal>
      ) : null}

      {modalType === 'view' ? (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          className='max-w-lg h-full 2xl:max-h-fit min-w-[400px] md:min-w-[700px] lg:min-w-[1000px] max-h-screen dark:bg-darkBg rounded-md shadow-lg pt-2 bg-gray-100 -mt-2 overflow-x-scroll no-scrollbar'
        >
          <div id='clearScrollbar' className='space-y-2 -mx-3'>
            <div className='flex items-center justify-between gap-8 rounded'>
              <div className='bg-[rgba(0,0,0,0.7)] flex font-bold items-center gap-2 dark:bg-gray-700 rounded-md p-2 text-sm dark:text-gray-200 relative group'>
                <div className=' flex items-center gap-1'>
                  <span className='text-white'>
                    {(deviceName && deviceName.charAt(0).toUpperCase() + deviceName.slice(1)) ||
                      'Device Name'}
                    :
                  </span>
                  <span className='opacity-95 text-green-400'>
                    {currentMediaUrl
                      ? images.find((media) => media.imgUrl === currentMediaUrl)?.headcount
                      : '0'}
                  </span>
                </div>
                <span className='z-10 min-w-[30px] absolute top-full mt-1 transform translate-x-0 px-2 py-1 bg-gray-800 text-white text-[9px] rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap'>
                  Device Name and Count
                </span>
              </div>
              <div className='flex gap-2'>
                <div className=' flex flex-row'>
                  <div className='relative group'>
                    {currentMediaUrl &&
                    images.find((media) => media.imgUrl === currentMediaUrl)?.inferenceType ? (
                      <div className='relative '>
                        <div className='bg-[rgba(0,0,0,0.7)] text-white dark:bg-gray-700 rounded-md p-2 text-sm'>
                          <p>
                            {
                              inferenceTypeMapping[
                                images.find((media) => media.imgUrl === currentMediaUrl)
                                  ?.inferenceType || 'Audience Metrics'
                              ]
                            }
                          </p>
                        </div>
                        <span className='z-10 min-w-fit absolute top-full mt-1 right-0 transform translate-x-0 px-2 py-1 bg-gray-800 text-white text-[10px] rounded opacity-0 group-hover:opacity-100 transition-opacity'>
                      Inference Type
                    </span>
                      </div>
                      
                    ) : null}

                    
                  </div>
                </div>
                <CancelPresentationIcon
                  className='opacity-50 cursor-pointer hover:text-red-600 hover:opacity-85'
                  onClick={onClose}
                />
              </div>
            </div>

            {/* Canvas section */}
            <section className='relative bg-white dark:bg-gray-800 mt-2'>
              <img
                className='object-cover w-full h-[520px] rounded-xl'
                src={currentMediaUrl || devicesPlaceholderImg}
                alt='Main Media'
              />

              {/* Action Bar */}
              {currentMediaUrl &&
                images.find(
                  (media) => media.imgUrl === currentMediaUrl && media.artifactType === 'video',
                ) && (
                  <div className='p-2 absolute bottom-4 left-1/2 transform -translate-x-1/2 flex items-center gap-4 rounded-sm'>
                    <div className='flex items-center gap-4'>
                      <button className='p-3 bg-white opacity-80 rounded-full shadow-md hover:bg-gray-200 '>
                        <PauseIcon className='w-6 h-6 text-black opacity-100 hover:text-red-500' />
                      </button>

                      <button className='p-3 bg-white opacity-80 rounded-full shadow-md hover:bg-gray-200 '>
                        <PlayIcon className='w-6 h-6 text-black opacity-100 hover:text-red-500' />
                      </button>
                    </div>
                  </div>
                )}
            </section>

            {/* Thumbnail section */}
            <section className="relative">
            <div className="flex items-center justify-between w-full relative">
              {images.length > 6  && (
                <button
                  onClick={() => handleScrollClick('backward')}
                  className="h-10 w-10 bg-black opacity-80 rounded-full  text-white shadow-sm hover:bg-gray-700 flex items-center justify-center"
                >
                  <ChevronLeftIcon className="w-5 h-5 absolute" />
                </button>
              )}
              {/* Thumbnail Gallery */}
              <div className="flex justify-center flex-1">
                {loading ? (
                  <div
                    id="skeletonGallery"
                    className="flex gap-2 overflow-x-scroll no-scrollbar px-2 max-h-36 relative"
                  >
                    {[...Array(images.length)].map((_, index) => (
                      <div
                        key={index}
                        className="h-28 w-36 bg-gray-200 animate-pulse rounded-2xl"
                      ></div>
                    ))}
                  </div>
                ) : images.length > 0 ? (
                  <div
                    id="thumbnailGallery"
                    className="flex gap-2 overflow-x-scroll no-scrollbar px-1"
                    onScroll={handleScroll}
                  >
                    {images.map((item, index) => (
                      <>
                    <img
                      key={index}
                      src={item.thumbnailUrl || devicesPlaceholderImg}
                      alt={`Thumbnail ${index + 1}`}
                      className={`object-cover h-28 w-36  rounded-2xl cursor-pointer text-xs ${!item.isHighResLoaded ? 'opacity-100' : 'hidden'}`}
                      onClick={() => handleImageSelect(item.imgUrl)}
                      onLoad={() => handleImageLoad(index, false)} 
                    />
                  <img
                    key={index}
                    src={item.imgUrl || ""}
                    alt={`High-Res ${index + 1}`}
                    className={`object-cover h-28 w-36  rounded-2xl cursor-pointer text-xs ${item.isHighResLoaded ? 'opacity-100' : 'hidden'}`}
                    onLoad={() => handleImageLoad(index, true)} 
                    onClick={() => handleImageSelect(item.imgUrl)}
                    onError={(e) => {
                      console.error("Failed to load high-res image", e);
                    }}
                  />
                  </>
                    ))}
                  </div>
                ) : (
                  <img
                    src={currentMediaUrl || devicesPlaceholderImg}
                    alt="latest device image"
                    className="object-cover h-20 w-20 rounded-lg cursor-pointer"
                  />
                )}
              </div>
              {images.length > 6  && (
                <button
                  onClick={() => handleScrollClick('forward')}
                  className="h-10 w-10 bg-black opacity-80 rounded-full text-white shadow-sm hover:bg-gray-700 flex items-center justify-center"
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              )}
            </div>
          </section>
          </div>
        </CustomModal>
      ) : null}
    </div>
  );
}
