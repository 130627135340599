import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { TPaymentMethod } from 'types/global/paymentMethods';

export const currentSubscriptionPlanSelector = (state: RootState) =>
  state.subscriptions.currentSubscriptionPlan;

export const isSubscriptionFree = (state: RootState) => state.subscriptions.freeSubscriptionTrial;
export const subscriptionsSelector = (state: RootState) => state.subscriptions;

export const currentSubscriptionPlanErrorSelector = (state: RootState) =>
  state.subscriptions.currentSubscriptionPlanError;

export const currentSubscriptionPlanLoadingSelector = (state: RootState) =>
  state.subscriptions.currentSubscriptionPlanLoading;

export const userSubscriptionsSelector = (state: RootState) =>
  state.subscriptions.userSubscriptions;

export const userActiveSubscriptionSelector = (state: RootState) =>
  state.subscriptions.userSubscriptions?.data?.[0];

export const userPaymentMethodInActiveSubscriptionSelector = (state: RootState) => {
  const userPaymentMethodAll = userPaymentMethodsSelector(state);
  const userActiveSubscription = userActiveSubscriptionSelector(state);

  return userPaymentMethodAll?.data?.find(
    (payment: TPaymentMethod) => payment?.id === userActiveSubscription?.default_payment_method,
  );
};

export const userSubscriptionsPlansData = (state: RootState) =>
  state.subscriptions.userSubscriptions?.data;

export const userSubscriptionsPlanSelector = createSelector(
  [userSubscriptionsPlansData],
  (userSubscriptionsPlans) => userSubscriptionsPlans?.map((plan) => plan?.metadata?.plan),
);

export const userSubscriptionsTrialPlansDataName = (state: RootState) =>
  state.subscriptions.userSubscriptionsTrial?.data?.[0]?.metadata?.plan;

export const userSubscriptionsTrialPlan = (state: RootState) =>
  state.subscriptions.userSubscriptionsTrial;

const userSubscriptionsIntervalDataSelector = (state: RootState) =>
  state.subscriptions.userSubscriptions?.data;

export const userSubscriptionsIntervalSelector = createSelector(
  [userSubscriptionsIntervalDataSelector],
  (userSubscriptionsIntervalData) =>
    userSubscriptionsIntervalData?.map((interval) => interval?.plan?.interval),
);

const userSubscriptionsDataSelector = (state: RootState) =>
  state.subscriptions.userSubscriptions?.data;

export const userSubscriptionsAmountSelector = createSelector(
  [userSubscriptionsDataSelector],
  (userSubscriptionsData) => userSubscriptionsData?.map((plan) => plan?.plan?.amount),
);

export const userSubscriptionsErrorSelector = (state: RootState) =>
  state.subscriptions.userSubscriptionsError;

export const userSubscriptionsLoaderSelector = (state: RootState) =>
  state.subscriptions.userSubscriptionsLoader;

export const userPaymentMethodsSelector = (state: RootState) =>
  state.subscriptions.allPaymentMethods;

export const userFirstPaymentMethodSelector = (state: RootState) =>
  state.subscriptions.allPaymentMethods?.data?.[0];

export const userDefaultPaymentMethodSelector = (state: RootState) =>
  state.subscriptions.defaultPaymentMethod;

export const invoicesHistorySelector = (state: RootState) => state.subscriptions.invoicesHistory;

export const defaultPaymentMethodLoaderSelector = (state: RootState) =>
  state.subscriptions.defaultPaymentMethodLoader;

export const paymentMethodsLoaderSelector = (state: RootState) =>
  state.subscriptions.allPaymentMethodsLoader;

export const invoicesHistoryLoaderSelector = (state: RootState) =>
  state.subscriptions.invoicesHistoryLoader;

export const currentPaymentMethodSelector = (state: RootState) => {
  const userDefaultPaymentMethod = userDefaultPaymentMethodSelector(state);
  const userPaymentMethodAll = userPaymentMethodsSelector(state);

  return userPaymentMethodAll?.data?.find(
    (payment: TPaymentMethod) => payment?.id === userDefaultPaymentMethod,
  );
};
