import { FC, Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Container from 'components/shared/Container';
import Footer from 'components/shared/Footer';
import NavBar from 'components/shared/NavBar';
import BlurBlue from 'components/style/BlurBlue';
import WorkSmarter from 'components/home/WorkSmarter';
import { Routes } from 'types';

const MinistrySection = lazy(() => import('containers/industries/MinistrySection'));
const ProductionSection = lazy(() => import('containers/industries/ProductionSection'));
const HospitalitySection = lazy(() => import('containers/industries/HospitalitySection'));
const ConstructionSection = lazy(() => import('containers/industries/ConstructionSection'));
const TransportSection = lazy(() => import('containers/industries/TransportSection'));
const RetailSection = lazy(() => import('containers/industries/RetailSection'));
const ContactForm = lazy(() => import('components/contact-us/ContactForm'));
const CustomModal = lazy(() => import('components/Modal'));

import IndustryNav from '../industries/IndustryNavigation';
import HeroSection from '../industries/HeroSection';

import { TIndustryTypeProps } from './types/industryType';

function renderSection(path: Routes) {
  switch (path) {
    case Routes.Industriespage:
      return (
        <Suspense fallback={null}>
          <RetailSection />
        </Suspense>
      );
    case Routes.Transport:
      return (
        <Suspense fallback={null}>
          <TransportSection />
        </Suspense>
      );
    case Routes.Construction:
      return (
        <Suspense fallback={null}>
          <ConstructionSection />
        </Suspense>
      );
    case Routes.Hospitality:
      return (
        <Suspense fallback={null}>
          <HospitalitySection />
        </Suspense>
      );
    case Routes.Production:
      return (
        <Suspense fallback={null}>
          <ProductionSection />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={null}>
          <MinistrySection />
        </Suspense>
      );
  }
}

const IndustryLayout: FC<TIndustryTypeProps> = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const location = useLocation();

  function onOpenContactForm() {
    setIsContactFormOpen(true);
  }

  function onCloseContactForm() {
    setIsContactFormOpen(false);
  }

  useEffect(() => {
    if (location.pathname) {
      const mainSection = document.getElementsByTagName('main')[0];
      mainSection.scrollTo(0, 0); // Scroll to top of main section when we switch sections
    }
  }, [location.pathname]);

  return (
    <div className='w-full mx-auto  overflow-hidden font-Roboto'>
      <div>
        <BlurBlue />
        <NavBar />
        <HeroSection />
      </div>

      <div className=' transition-all duration-300 relative z-30 mt-10 '>
        <Container className='md:grid grid-cols-11 gap-x-10 lg:gap-x-20  '>
          <aside className='col-span-5 lg:col-span-4 bg-card-bg dark:bg-dark-card-bg h-max transition-all duration-300 px-7 '>
            <IndustryNav />
          </aside>
          <main className='col-span-6 lg:col-span-7 pt-4 max-h-[1200px] overflow-y-scroll'>
            {renderSection(location.pathname as Routes)}
          </main>
        </Container>
      </div>
      <WorkSmarter onClickScheduleCall={onOpenContactForm} />
      <Footer />
      <Suspense fallback={null}>
        <CustomModal
          isOpen={isContactFormOpen}
          onClose={onCloseContactForm}
          className='!p-0 w-[90vw] md:!w-[75vw] h-auto mx-auto mt-2'
          useClassNameOnly={true}
        >
          <ContactForm
            isModal
            containerClassName='!px-0 !w-full relative'
            contentContainerClassName='bg-pill-bg dark:bg-industry-hero-dark dark:text-white rounded-[10px] flex flex-col-reverse lg:flex-row gap-8'
            onClose={onCloseContactForm}
          />
        </CustomModal>
      </Suspense>
    </div>
  );
};

export default IndustryLayout;
