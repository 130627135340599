import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';

import { TMediaFolder } from 'api/media/types';
import { getSignedMediaUrl } from 'api/media';

export type TAvatarProps = Partial<{
  image: string;
  fullName: string;
  firstName: string;
  lastName: string;
  className: string;
  alt: string;
  mediaId: string;
  mediaFolder: TMediaFolder;
  mediaType: string;
  onClick: () => void;
}>;

const Avatar: React.FC<TAvatarProps> = ({
  image,
  fullName,
  firstName,
  lastName,
  alt,
  className,
  mediaId,
  mediaFolder = 'profile-info',
  onClick,
}) => {
  const names = fullName?.split(' ');
  const userFirstName = names?.[0] ?? firstName ?? '';
  const userLastName = names?.[1] ?? lastName ?? '';
  const [mediaUrl, setMediaUrl] = useState<string>();

  useEffect(() => {
    if (mediaId && mediaFolder) {
      getSignedMediaUrl({ folder: mediaFolder,
         media_url_id: mediaId,
        thumbnail:false })
        .then((response) => {
          setMediaUrl(response.data.url);
        })
        .catch((e) => {});
    }
  }, [mediaFolder, mediaId]);

  if (image) {
    return (
      <img
        src={image}
        alt={alt}
        className={classNames(`object-fit w-[40px] rounded-full`, className)}
        onClick={onClick}
      />
    );
  }

  if (!image && mediaUrl) {
    return (
      <img
        src={mediaUrl}
        alt={alt}
        className={classNames(`object-fit`, className, {
          'w-[40px] rounded-full': !className,
        })}
        onClick={onClick}
        onError={() => {
          // If we can't load the image, fallback to name avatar
          setMediaUrl(undefined);
        }}
      />
    );
  }

  if (userFirstName || userLastName) {
    return (
      <div
        className={classNames(
          `flex h-[40px] w-[40px] items-center justify-center rounded-full border border-grey-outline bg-[#F1F7FF] text-xs font-bold text-accent`,
          className,
        )}
        onClick={onClick}
      >
        <span>
          {userFirstName?.[0]?.toLocaleUpperCase()}
          {userLastName?.[0]?.toLocaleUpperCase()}
        </span>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        `flex h-[40px] w-[40px] items-center justify-center rounded-full border border-grey-outline bg-[#F1F7FF] text-xs font-bold text-accent`,
        className,
      )}
      onClick={onClick}
    >
      <PersonIcon color='info' />
    </div>
  );
};

export default Avatar;

Avatar.defaultProps = {
  image: '',
  firstName: '',
  lastName: '',
  alt: '',
  className: '',
};
