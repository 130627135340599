import { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';

import { useAppDispatch, useUserCredentials } from 'hooks';
import PublicRoute from 'components/shared/PublicRoute';
import ProtectedRoute from 'components/shared/ProtectedRoute';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import ProtectedWithSession from 'components/shared/ProtectedWithSession';
import { parseJwt } from 'utils/parseJwt';
import Layout from 'components/layout/Layout';
import LandingLayout from 'components/layout/LandingLayout';
import { getAllOrganisations as getOrganizations } from 'store/thunks';
import { Routes, RoutesProps } from '../types';

import routes from './routes';

const RouterProvider = () => {
  const { error } = useUserCredentials();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (
      error?.message?.includes('401') ||
      error?.message?.includes('500') ||
      error?.message === 'Network Error'
    ) {
      <Navigate to={Routes.Landing} replace={true} />;
    }
  }, [error?.message, error?.status]);

  const urlParams = new URLSearchParams(window.location.search);

  const accessTokenRef =
    urlParams.get('access_token') || BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const refreshTokenRef = urlParams.get('refresh_token');

  const tokenData = parseJwt(accessTokenRef as string);

  useEffect(() => {
    if (accessTokenRef && refreshTokenRef) {
      BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessTokenRef));
      BrowserStorageService.set(BrowserStorageKeys.RefreshToken, JSON.stringify(refreshTokenRef));
      BrowserStorageService.set(BrowserStorageKeys.CurrentOrganizationId, tokenData?.org_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  const renderRoutes = routes.map(
    ({
      path,
      component,
      isLanding,
      withNavbar,
      isProtected,
      tabNavigatorOptions,
      tabNavigatorTitle,
      withLogoHeader = false,
      withTabNavigator = false,
      isProtectedWithSession = false,
    }: RoutesProps) => {
      const RoutesWrapper =
        isProtected && isProtectedWithSession
          ? ProtectedWithSession
          : isProtected && !isProtectedWithSession
          ? ProtectedRoute
          : PublicRoute;

      const LayoutItem = isLanding ? LandingLayout : Layout;

      return (
        <Route
          key={path}
          path={path}
          element={
            <RoutesWrapper>
              <LayoutItem
                isLanding={isLanding}
                withNavbar={withNavbar}
                withLogoHeader={withLogoHeader}
                withTabNavigator={withTabNavigator}
                tabNavigatorTitle={tabNavigatorTitle}
                tabNavigatorOptions={tabNavigatorOptions}
              >
                {component}
              </LayoutItem>
            </RoutesWrapper>
          }
        />
      );
    },
  );

  return (
    <BrowserRouter>
      <RouterRoutes>{renderRoutes}</RouterRoutes>
    </BrowserRouter>
  );
};

export default RouterProvider;
