import { FC, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SvgIconProps } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { NavItem } from 'components/shared/NavItem';
import { Routes } from 'types';
import { useAppSelector, usePermissions } from 'hooks';

import { TSettingsProps } from './types/profileType';
import './styles/dashboard.scss';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { TUserRole } from 'store/slices/authSlice/types';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { subscriptionTypeSelector } from 'store/slices/organizationsSlice/selectors';
import { useState } from 'react';

export type TRoutes = {
  title: string;
  icon?: React.FC<SvgIconProps>;
  isLogout?: boolean;
  route?: string;
  action?: () => void;
  id?: string;
};

const Settings: FC<TSettingsProps> = ({ children }) => {
  const userData = useAppSelector(userDataSelector);
  const subscriptionType = useAppSelector(subscriptionTypeSelector);
  const { user_roles } = userData ?? {};
  const location = useLocation();
  const { isAllowedToManageBillingSettings, isAllowedToManageInviteSettings } = usePermissions();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const isRouteActive = (route: string) => {
    return location.pathname.includes(route);
  };

  const userWritePermission = user_roles?.some((user: TUserRole) => {
    return user?.role?.toLowerCase() === userRoleEnums.OWNER?.toLowerCase();
  });

  const settingsRoutes: TRoutes[] = useMemo(
    () => [
      {
        title: 'My Profile',
        route: Routes.ProfileSettings,
      },
      ...(isAllowedToManageInviteSettings
        ? [
            {
              id: 'invites',
              title: 'Invites',
              route: Routes.InvitesSettings,
            },
          ]
        : []),
      {
        id: 'notifications',
        title: 'Notifications',
        route: Routes.NotificationSettings,
      },
      {
        id: 'organization',
        title: 'My Organization',
        route: Routes.OrganizationSettings,
      },
      ...(isAllowedToManageBillingSettings
        ? [
            {
              id: 'billing',
              title: 'Billing',
              route: Routes.BillingSettings,
            },
          ]
        : []),
      ...(userWritePermission && subscriptionType === 'api'
        ? [
            {
              id: 'api',
              title: 'API',
              route: Routes.APISettings,
            },
          ]
        : []),
    ],
    [
      isAllowedToManageBillingSettings,
      isAllowedToManageInviteSettings,
      userWritePermission,
      subscriptionType,
    ],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className='flex gap-1'>
        <p className='text-[24px] font-semibold'>Settings</p>
        <button
          className='lg:hidden  rounded-full '
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <KeyboardArrowDownIcon
            className='text-black dark:text-white'
            aria-describedby='userDropdownElem'
          />
        </button>
      </div>
      <div className='flex gap-3  mt-5 md:mt-10 '>
        <div className=' w-[400px] bg-white p-3 lg:flex flex-col gap-3 hidden dark:bg-dark-card-bg'>
          {settingsRoutes.map(({ id, title, route }) => (
            <NavItem
              key={title}
              title={title}
              active={isRouteActive(route as string)}
              route={route}
              id={id}
            />
          ))}
        </div>
        {!isDropdownOpen ? (
          ''
        ) : (
          <div className='relative' ref={dropdownRef}>
            <div className='absolute top-0 left-0 w-[200px] bg-white p-3 flex flex-col gap-3 dark:bg-dark-card-bg z-50 h-full min-h-fit shadow-sm rounded'>
              {settingsRoutes.map(({ id, title, route }) => (
                <NavItem
                  key={title}
                  id={id}
                  title={title}
                  active={isRouteActive(route as string)}
                  route={route}
                />
              ))}
            </div>
          </div>
        )}
        <div
          className='w-full bg-white rounded-t-[4px] overflow-scroll dark:bg-dark-card-bg'
          id='clearScrollbar'
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Settings;
