import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreateInvitation,
  TGetAllInvitedUsersResponse,
  TGetInviteParams,
  TInviteUser,
  TInvitedUser,
  TUpdateEmailInvitationParams,
  TUpdateInvitationParams,
  TUpdateUserResponse,
  TUserInvitesResponse,
} from 'store/slices/invitationsSlice/types';

export const createInvitationRequest = (options: TCreateInvitation[]) =>
  client.post(endpoints.InvitationService.createInvitation(), options);

export const inviteUserRequest = (body: TInviteUser, org_id?: number) => {
  return client.post(endpoints.InvitationService.invitedUser(org_id || ''), body);
};

export const getAllInvitedUsersRequest = ({
  limit,
  offset,
}: TGetInviteParams): Promise<AxiosResponse<TGetAllInvitedUsersResponse>> => {
  return client.get(endpoints.InvitationService.getInvitations(), { params: { limit, offset } });
};

export const getInvitationByIdRequest = (id: number) =>
  client.get(endpoints.InvitationService.getInvitationById(id));

export const getUserInvitesRequest = ({
  limit,
  offset,
}: TGetInviteParams): Promise<AxiosResponse<TUserInvitesResponse>> =>
  client.get(endpoints.InvitationService.getUserInvitations(), { params: { limit, offset } });

export const updateInvitationStatusRequest = (params: TUpdateInvitationParams) =>
  client.put(endpoints.InvitationService.updateInvitationStatus(params));

export const updateEmailInvitationStatusRequest = (
  params: TUpdateEmailInvitationParams,
): Promise<AxiosResponse<TInvitedUser>> =>
  client.put(endpoints.InvitationService.updateEmailInvitationStatus(), {}, { params });

export const deactivateUserRequest = (email: string): Promise<AxiosResponse<TUpdateUserResponse>> =>
  client.put(`${endpoints.InvitationService.deactivateUser()}?email=${encodeURIComponent(email)}`);

export const reactivateUserRequest = (email: string): Promise<AxiosResponse<TUpdateUserResponse>> =>
  client.put(`${endpoints.InvitationService.reactivateUser()}?email=${encodeURIComponent(email)}`);
