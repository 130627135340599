import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LoadingBar from 'react-top-loading-bar';
import classNames from 'classnames';


import PrimaryButton from 'components/buttons/PrimaryButton';
import Avatar from 'components/shared/Avatar';
import { useAppDispatch, useAppSelector, useTopLoader } from 'hooks';
import { TGetRolesParams } from 'store/slices/authSlice/types';
import {
  acceptUserInvitation,
  deactivateUser,
  getAllInvitedUsers,
  getAllRoles,
  getUserInvites,
  inviteUser,
  reactivateUser,
  rejectUserInvitation,
  removeInvitesUser,
} from 'store/thunks';
import { invitesValidationSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';
import { ControlledSelect } from 'components';
import { Colors } from 'types';
import { userRoleEnums } from 'components/shared/EmployeeStatus/types';
import { getAllInvitedUsersSelector } from 'store/slices/invitationsSlice/selectors';
import { TInviteStatus, TInviteUserValue } from 'store/slices/invitationsSlice/types';
import { TOrganization } from 'store/slices/organizationsSlice/types';
import { userDataSelector, userEmailSelector } from 'store/slices/authSlice/selectors';
import { loaderOptions } from 'constants/sizeOptions';
import UserAvatar from 'components/shared/UserAvatar';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import toast from 'react-hot-toast';

interface profileUserProps {
  userEmail: string;
  userFullname: string;
  userId: number;
  index: number;
  status: TInviteStatus;
  userRole: string;
}

const InvitesTabs = () => {
  const userData = useAppSelector(userDataSelector);
  const invitesSent = useAppSelector(getAllInvitedUsersSelector);
  const [roles, setRoles] = useState<{ id: number; value: number; label: string }[]>([]);
  const dispatch = useAppDispatch();
  const { progress, loaderHandler, resetLoader } = useTopLoader();
  const {
    control: invitesControl,
    handleSubmit: inviteSubmit,
    formState: { errors },
    reset,
  } = useForm<TInviteUserValue>({
    resolver: yupResolver(invitesValidationSchema),
  });
  useEffect(() => {
    if (errors?.full_name?.message) {
      toast.error(errors.full_name.message); 
    }
    if (errors?.email?.message) {
      toast.error(errors.email.message); 
    }
  }, [errors]);

  const onInvite = async (values: TInviteUserValue) => {
    const obj = [
      {
        email: values?.email,
        full_name: values?.full_name,
        role_id:
          values.role_id ?? roles.find((role) => role.label === userRoleEnums.TEAMMEMBER)?.id,
      },
    ];
    try {
      await loaderHandler(dispatch(inviteUser(obj)));
      reset({
        full_name: '',
        email: '',
        role_id: undefined, 
      });
    
    } catch (err: any) {
      throw err as AxiosError;
    }
  };

  const getUsers = async () => {
    const options = {
      limit: 10,
      offset: 0,
    };
    await dispatch(getAllInvitedUsers(options));
  };

  async function getRoles() {
    const options = {
      type: 'organization',
      limit: 10,
      offset: 0,
    };

    const response = await dispatch(getAllRoles(options as TGetRolesParams)).unwrap();
    setRoles(response?.map((role) => ({ id: role?.id, value: role?.id, label: role?.role })) ?? []);
  }

  async function getInvitesReceived() {
    await dispatch(getUserInvites({ limit: 10, offset: 0 }));
  }

  useEffect(() => {
    // If we have user data, fetch data for the dashboard
    if (userData?.member_info) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.member_info]);

  useEffect(() => {
    getRoles();
    getInvitesReceived();
  }, []);
  return (
    <>
      {progress > 0 && (
        <LoadingBar
          color={loaderOptions.color}
          progress={progress}
          height={loaderOptions.height}
          loaderSpeed={1600}
          onLoaderFinished={resetLoader}
        />
      )}
      <div className='p-5 md:p-10 shadow-sm '>
        <p className='p-3 text-[18px] md:text-[24px] font-semibold'>Invites</p>
      </div>
      <div className='p-10 flex flex-col gap-6'>
        <div
          className='grid grid-cols-1 md:flex md:flex-row gap-4 md:gap-2 max-w-5xl '
          id='inviteATeamMember'
        >
          <ControlledInput
            required
            control={invitesControl}
            name='full_name'
            label='Name'
            error={!!errors.full_name}
            placeholder='Full Name*'
            helperText={(errors?.full_name?.message as string) ?? ''}
          />
            
            <ControlledInput
            required
            control={invitesControl}
            name='email'
            label='Email'
            error={!!errors.email}
            placeholder='Enter Email*'
            helperText={(errors?.email?.message as string) ?? ''}
          />
            <ControlledSelect
              name="role_id"
              control={invitesControl}
              options={roles}
              label="Role"
              defaultValue=""
              border={`1px solid ${Colors.SOFT_SILVER}`}
            
            />
              <div className="w-full relative">
          <div className="absolute top-[-87px] right-0 ml-[-15px] md:top-[-17px] md:left-0 flex items-center bg-gray-200 rounded-full h-4 w-4 mb-1">
            <div className="relative group flex justify-center items-center w-full h-full">
              <span className="text-[10px] text-gray-500 cursor-pointer leading-none">
                <p>?</p>
              </span>
              <div
                className="group-hover:block hidden absolute top-full mt-2 sm:left-auto sm:right-0 md:left-auto md:right-0 transform translate-x-0 z-[9999]"
              >
                <div className="relative">
                  <div
                    className="pt-1 bg-gray-600 text-[10px] dark:bg-gray-800 text-white rounded-lg py-2 px-3 shadow-lg w-full max-w-xs sm:max-w-sm md:w-[150px] overflow-auto"
                  >
                    <span>
                      <strong>Owner:</strong> This role grants the user full access to all functions, including managing events, teams, devices, billing, organizations, and APIs (if applicable).<br /><br />
                      <strong>Team Lead:</strong> This role provides access to all functions except billing and organization management.<br /><br />
                      <strong>Team Member:</strong> This role allows the user to view teams and events only.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PrimaryButton className="w-full" variant="neutral" onClick={inviteSubmit(onInvite)}>
            Invite Team Member
          </PrimaryButton>
        </div>
        </div>
        {/* {pendingUserInvites?.length > 0 ? (
          <>
            <div>
              <p className='text-[18px] font-semibold'>
                Invites Received
                <span className='text-[#999999]'> ({pendingUserInvites?.length})</span>
              </p>
            </div>

            <div className='flex flex-col gap-5 '>
              {pendingUserInvites?.map((invite) => (
                <InviteReceived
                  id={invite?.id}
                  key={invite?.id}
                  organization={invite?.organization}
                />
              ))}
            </div>
          </>
        ) : null} */}
        {invitesSent?.length ? (
          <>
            <div id='settingsTeamMemberSection'>
              <p className='text-[18px] font-semibold'>
                Invites Sent<span className='text-[#999999]'> ({invitesSent?.length})</span>
              </p>
            </div>

            <div className='flex flex-col border'>
              {invitesSent?.map((user, index) => (
                <InviteSent
                  key={user?.id}
                  index={index}
                  userFullname={user?.full_name}
                  userEmail={user?.email}
                  userId={user?.id}
                  status={user.status}
                  userRole={
                    roles.find((role) => role.id === user?.role_id)?.label || 'Unknown Role'
                  }
                />
              ))}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const InviteSent: React.FC<profileUserProps> = ({
  userFullname,
  userEmail,
  userId,
  index,
  status: initialStatus,
  userRole,
}) => {
  const dispatch = useAppDispatch();
  // const email = useAppSelector(userEmailSelector);
  const { progress, loaderHandler, resetLoader } = useTopLoader();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [inviteStatus, setInviteStatus] = useState<TInviteStatus>(initialStatus);

  const revokeInvite = async (status: TInviteStatus) => {
    try {
      if (status === 'pending') {
        await loaderHandler(dispatch(removeInvitesUser(userId)));
        setInviteStatus('revoked');
      } else if (status === 'accepted') {
        await loaderHandler(dispatch(deactivateUser(userEmail)));
        setInviteStatus('deactivated');
      } else if (status === 'deactivated') {
        await loaderHandler(dispatch(reactivateUser(userEmail)));
        setInviteStatus('accepted');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      resetLoader();
    }
  };

  return (
    <>
      {progress > 0 && (
        <LoadingBar
          color={loaderOptions.color}
          progress={progress}
          height={loaderOptions.height}
          loaderSpeed={1600}
          onLoaderFinished={resetLoader}
        />
      )}
      <div
        className={classNames('flex flex-row items-center gap-2 md:gap-4 p-2', {
          'bg-white dark:bg-dark-card-bg-old': index % 2 === 0,
          'bg-card-bg dark:bg-dark-card-bg': index % 2 === 1,
        })}
      >
        <div className='basis-1/5 md:basis-1/12'>
          <Avatar fullName={userFullname} image='' alt='profile image' />
        </div>
        <div className='flex flex-col line-clamp-1 gap-2 basis-2/5 md:basis-8/12'>
          <p className='font-semibold capitalize truncate w-[170px] md:w-full'>
            {userFullname} <span className='text-xs text-accent'>{userRole}</span>
          </p>
          <p className='text-[#222222] opacity-50 truncate w-[170px] md:w-full dark:text-white'>
            {userEmail}
          </p>
        </div>
        <div className='flex flex-row items-center gap-2 justify-center basis-2/5 md:basis-3/12'>
          <div className='flex flex-row items-center'>
            <p className='capitalize text-sm p-1 px-2 rounded bg-dark-sub-title dark:bg-dark-card-bg-old text-center min-w-24'>
              {inviteStatus}
            </p>
          </div>
          <button
            className='cursor-pointer flex flex-row items-center justify-center md:gap-2'
            onClick={() => setOpenDeleteModal(true)}
            aria-label={`Open modal for ${inviteStatus}`}
          >
            {inviteStatus === 'deactivated' ? (
              <CheckCircleOutlinedIcon className='flex text-green-700 md:hidden' />
            ) : inviteStatus === 'accepted' || inviteStatus === 'pending' ? (
              <ClearOutlinedIcon className='flex text-red-500 md:hidden' />
            ) : null}

            {inviteStatus === 'deactivated' ? (
              <p className='hidden text-green-700 font-semibold md:flex min-w-20'>Reactivate</p>
            ) : inviteStatus === 'accepted' ? (
              <p className='hidden text-red-700 font-semibold md:flex min-w-20'>Deactivate</p>
            ) : inviteStatus === 'pending' ? (
              <p className='hidden text-red-500 font-semibold md:flex min-w-20'>Remove</p>
            ) : null}
          </button>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={
          inviteStatus === 'accepted'
            ? 'Deactivate Invite?'
            : inviteStatus === 'deactivated'
            ? 'Reactivate Invite?'
            : 'Remove Invite?'
        }
        description={
          inviteStatus === 'accepted'
            ? 'Confirming will deactivate this invite.'
            : inviteStatus === 'deactivated'
            ? 'Confirming will reactivate the invite.'
            : 'Confirming will permanently remove the invite.'
        }
        onDelete={() => {
          revokeInvite(inviteStatus);
          setOpenDeleteModal(false);
        }}
        deleteText={
          inviteStatus === 'accepted'
            ? 'Deactivate'
            : inviteStatus === 'deactivated'
            ? 'Reactivate'
            : 'Remove'
        }
      />
    </>
  );
};

function InviteReceived({ id, organization }: { id: number; organization: TOrganization }) {
  const dispatch = useAppDispatch();
  const { name, email, media_url_id } = organization ?? {};

  const acceptInvite = useCallback(
    async () => await dispatch(acceptUserInvitation({ id, organizationName: name })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, name],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rejectInvite = useCallback(async () => await dispatch(rejectUserInvitation(id)), [id]);

  return (
    <div className='flex flex-row items-center gap-4'>
      <div className='basis-1/5 md:basis-1/12'>
        <UserAvatar
          fullName={name}
          mediaFolder='organization'
          mediaId={media_url_id as string}
          alt='organization icon'
        />
      </div>
      <div className='flex flex-col gap-2 basis-2/5 md:basis-8/12'>
        <p className='font-semibold capitalize truncate w-[150px] md:w-full'>{name}</p>
        <p className='text-[#222222] opacity-50 truncate w-[150px] md:w-full'>{email}</p>
      </div>
      <div className='flex flex-row justify-center gap-2 md:gap-6 basis-2/5 md:basis-3/12'>
        <button
          className='cursor-pointer flex flex-row items-center justify-center md:gap-2'
          onClick={acceptInvite}
        >
          <DoneOutlinedIcon className='flex text-accent md:hidden' />
          <p className='hidden text-accent font-semibold md:flex'>Accept</p>
        </button>
        <button
          className='cursor-pointer flex flex-row items-center justify-center md:gap-2'
          onClick={rejectInvite}
        >
          <ClearOutlinedIcon className='flex text-red-500 md:hidden' />
          <p className='hidden text-red-500 font-semibold md:flex'>Reject</p>
        </button>
      </div>
    </div>
  );
}

export default InvitesTabs;
