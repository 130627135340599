import React, { useState } from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ControlledInput } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ImageUpload from './ImageUpload';

const sectionSchema = yup.object({
  name: yup.string().required('Section name is required'),
  capacity: yup.number().required('Capacity is required').positive().integer(),
});

interface Section {
  id: string;
  name: string;
  capacity: number;
  image?: string; // Add an image property to the section
}

interface SectionsPageProps {
  onSectionsUpdate: (sections: Section[]) => void;
}

const SectionsPage: React.FC<SectionsPageProps> = ({ onSectionsUpdate }) => {
  const {
    control,
    handleSubmit,
    reset, // Added reset to clear the form after submission
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sectionSchema),
  });

  const [sections, setSections] = useState<Section[]>([]);
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(undefined);

  const onSubmit = (data: any) => {
    const { name, capacity } = data;
    const newSection: Section = {
      id: Date.now().toString(),
      name,
      capacity: Number(capacity),
      image: uploadedImage, // Attach the uploaded image to the new section
    };

    const updatedSections = [...sections, newSection];
    setSections(updatedSections);
    onSectionsUpdate(updatedSections);

    // Clear the form and reset the uploaded image
    reset();
    setUploadedImage(undefined);
  };

  const handleDeleteSection = (id: string) => {
    const updatedSections = sections.filter((section) => section.id !== id);
    setSections(updatedSections);
    onSectionsUpdate(updatedSections);
  };

  const handleImageUpload = (image: string) => {
    setUploadedImage(image);
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='mb-4'>
        <div className='flex justify-between'>
          <p className='font-semibold text-[24px]'>Manage Sections</p>
        </div>

        <div className='mt-7 px-2'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 mb-8 px-4'>
              <ControlledInput
                required
                name='name'
                label='Section Name'
                control={control}
                error={!!errors.name}
                placeholder='Section Name*'
                helperText={errors?.name?.message ?? ''}
              />

              <ControlledInput
                required
                name='capacity'
                label='Section Capacity'
                control={control}
                error={!!errors.capacity}
                placeholder='Section Capacity*'
                helperText={errors?.capacity?.message ?? ''}
                type='number'
              />
            </div>
            <ImageUpload onImageUpload={handleImageUpload} />

            <div className='mt-7'>
              <PrimaryButton className='w-full' variant='neutral' type='submit'>
                Add Section
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>

      <div>
        <p className='font-semibold text-[24px]'>Sections</p>
        <div className='mt-4'>
          {sections.length === 0 ? (
            <p>No sections added yet.</p>
          ) : (
            <ul>
              {sections.map((section) => (
                <li key={section.id} className='mb-4'>
                  {section.image && (
                    <img
                      src={section.image}
                      alt='Section'
                      className='w-[300px] h-[200px] object-cover'
                    />
                  )}
                  <div className='flex justify-between items-center mt-2 px-4'>
                    <p className='text-lg font-semibold'>{section.name}</p>
                    <button
                      onClick={() => handleDeleteSection(section.id)}
                      className='ml-4 bg-red-600 text-white px-2 p-1 rounded-sm text-xs'
                    >
                      Delete
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionsPage;
