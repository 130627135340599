// timeZoneUtils.ts

/**
 * Returns the timezone based on the provided city or state.
 * Falls back to 'UTC' if no match is found.
 * Extend the cityToTimezoneMap and stateToTimezoneMap as needed.
 */

export const getTimeZoneFromCityState = (city: string, state: string): string => {
  // Cities grouped by time zones
  const timezoneCities: { [timezone: string]: string[] } = {
    'America/New_York': [
      'New York',
      'Philadelphia',
      'Boston',
      'Miami',
      'Atlanta',
      'Charlotte',
      'Washington',
      'Columbus',
      'Baltimore',
      'Cleveland',
      'Pittsburgh',
      'Buffalo',
      'Tampa',
      'Raleigh',
      'Jersey City',
      'Orlando',
      'St. Petersburg',
      'Newark',
    ],
    'America/Chicago': [
      'Chicago',
      'Houston',
      'Dallas',
      'San Antonio',
      'Austin',
      'Fort Worth',
      'Nashville',
      'Memphis',
      'Milwaukee',
      'Kansas City',
      'Oklahoma City',
      'Southlake',
      'Indianapolis',
      'Louisville',
      'Cincinnati',
      'Minneapolis',
      'St. Louis',
      'Madison',
      'Des Moines',
    ],
    'America/Los_Angeles': [
      'Los Angeles',
      'San Diego',
      'San Jose',
      'Seattle',
      'Portland',
      'Las Vegas',
      'Sacramento',
      'Fresno',
      'Oakland',
      'Long Beach',
      'Bakersfield',
      'Anaheim',
      'Riverside',
      'Stockton',
      'Chula Vista',
      'Irvine',
      'San Bernardino',
    ],
    'America/Denver': [
      'Denver',
      'Salt Lake City',
      'El Paso',
      'Albuquerque',
      'Boise',
      'Tucson',
      'Colorado Springs',
      'Grand Junction',
      'Cheyenne',
      'Pueblo',
    ],
    'America/Phoenix': [
      'Phoenix',
      'Tucson',
      'Mesa',
      'Chandler',
      'Glendale',
      'Scottsdale',
      'Gilbert',
      'Peoria',
      'Tempe',
    ],
    'America/Detroit': ['Detroit', 'Flint', 'Lansing'],
    'America/Indiana/Indianapolis': ['Indianapolis', 'Fort Wayne', 'Evansville'],
    'America/Kentucky/Louisville': ['Louisville', 'Lexington'],
    'Pacific/Honolulu': ['Honolulu', 'Hilo', 'Kailua'],
  };

  // States grouped by time zones
  const timezoneStates: { [timezone: string]: string[] } = {
    'America/New_York': [
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Maine',
      'Maryland',
      'Massachusetts',
      'New Hampshire',
      'New Jersey',
      'New York',
      'North Carolina',
      'Ohio',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'Vermont',
      'Virginia',
      'West Virginia',
      'Michigan',
      'Indiana',
      'Kentucky',
      'Tennessee',
      'North Dakota',
      'South Dakota',
      'Alabama',
    ],
    'America/Chicago': [
      'Alabama',
      'Arkansas',
      'Illinois',
      'Iowa',
      'Kansas',
      'Louisiana',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Nebraska',
      'North Dakota',
      'Oklahoma',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Wisconsin',
      'Missouri',
      'Montana',
      'Wyoming',
      'Nebraska',
      'Kansas',
      'Louisiana',
    ],
    'America/Los_Angeles': [
      'California',
      'Nevada',
      'Oregon',
      'Washington',
      'Alaska',
      'Hawaii',
      'Idaho',
      'Arizona',
      'Utah',
      'Colorado',
      'Wyoming',
      'Montana',
      'New Mexico',
      'North Dakota',
    ],
    'America/Denver': [
      'Colorado',
      'Montana',
      'New Mexico',
      'Utah',
      'Wyoming',
      'Nebraska',
      'Idaho',
      'Arizona',
    ],
    'America/Phoenix': ['Arizona'],
    'America/Anchorage': ['Alaska'],
    'Pacific/Honolulu': ['Hawaii'],
  };

  // Find time zone by city
  for (const [timezone, cities] of Object.entries(timezoneCities)) {
    if (cities.includes(city)) {
      return timezone;
    }
  }

  // Find time zone by state
  for (const [timezone, states] of Object.entries(timezoneStates)) {
    if (states.includes(state)) {
      return timezone;
    }
  }

  // Default to UTC if no match
  return 'UTC';
};
