import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useDarkMode } from 'hooks';
import { FC } from 'react';

const PrimaryStepper: FC<{
  steps: string[];
  activeStep: number;
}> = ({ steps, activeStep }) => {
  const { darkMode } = useDarkMode();
  return (
    <Stepper activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            className='flex flex-col md:flex-row whitespace-nowrap gap-2 md:gap-0'
            sx={{
              '& .MuiStepLabel-label.Mui-active': {
                color: darkMode ? 'white' : '',
              },
              '& .MuiStepLabel-label.Mui-completed': {
                color: darkMode ? 'white' : '',
              },
              '& .MuiStepLabel-label': {
                color: darkMode ? 'white' : '',
              },
            }}
          >
            {step}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default PrimaryStepper;
