import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TAnalyticsDateParams,
  TAnalyticsDateResponse,
  THeadCountIntervalParams,
  TSeatingDensityResponse,
  TSumHeadCountParams,
  TSumHeadCountResponse,
} from 'store/slices/dashboardSlice/type';
const {
  getCurrentHeadCount,
  getSumHeadCount,
  getTotalHeadCountReport,
  getHeadCountIntervalReport,
  getHeadCountAtIntervals,
} = endpoints.AnalyticsService;

export const getCurrentHeadCountRequest = (): Promise<
  AxiosResponse<{ current_count: number; percent_change: number }>
> => client.get(getCurrentHeadCount());

export const getSumHeadCountRequest = ({ deviceId, days = 7 }: TSumHeadCountParams) => {
  return client.get(getSumHeadCount(deviceId, days));
};

export const getTotalHeadCountReportRequest = ({ deviceId, days = 7 }: TSumHeadCountParams) => {
  return client.get(getTotalHeadCountReport(days, deviceId), {
    headers: { responseType: 'blob' },
  });
};

export const getHeadCountAtIntervalsRequest = ({
  deviceId,
  interval,
  hours = 2,
  event_ids,
}: THeadCountIntervalParams) => {
  const options = {
    params: { deviceId, interval, hours, event_ids },
  };
  return client.get(getHeadCountAtIntervals(), options);
};

export const getHeadCountIntervalReportRequest = ({
  deviceId,
  interval = 5,
  hours = 2,
}: THeadCountIntervalParams) => {
  const options = {
    params: { deviceId, interval, hours },
    headers: { responseType: 'blob' },
  };
  return client.get(getHeadCountIntervalReport(), options);
};

export function getSeatingDensityRequest(
  event_id: number,
): Promise<AxiosResponse<TSeatingDensityResponse>> {
  return client.get(endpoints.AnalyticsService.getSeatingDensity(), { params: { event_id } });
}

export function getAnalyticsByDateTimeRequest(
  params: TAnalyticsDateParams,
): Promise<AxiosResponse<TSumHeadCountResponse>> {
  return client.get(endpoints.AnalyticsService.getAnalyticsByDatetime(), {
    params,
  });
}
