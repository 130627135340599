import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';
import { WeatherSvg } from 'weather-icons-animated';

import { Tabs } from 'components/Tabs/Tab';
import { OverViewCard, OverViewCardProps } from 'components/Cards/OverViewCard';
import { OngoingEventCard } from 'components/Cards/OngoingEventCard';
import { UpcomingEventCard } from 'components/Cards/UpcomingEventCard';
import { DevicesTable } from 'components/tables/DevicesTable';
import { DeviceCard } from 'components/Cards/DeviceCard';
import { DisplayType, Routes } from 'types';
import { getCurrentHeadCount } from 'store/slices/dashboardSlice/thunks';
import { getDevices, getDevicesCounts } from 'store/slices/devicesSlice/thunks';
import {
  useAppDispatch,
  useAppSelector,
  useDarkMode,
  useDeviceControlModal,
  useUserOrganizationsList,
} from 'hooks';
import { getActivePosts, getAllEvents, getCurrentEvents, getEventCounts } from 'store/thunks';
import { capitalizeFirstLetter } from 'utils';
import { TDevice } from 'store/slices/devicesSlice/type';
import { TEvent, TEventCountsResponse } from 'api/events/types';
import { getLocationCoordinates, getWeather } from 'store/slices/weather/thunks';
import { getWeatherIcon } from 'helpers/utils';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { stateOptions } from 'constants/States';
import {
  EmptyStateDevices,
  EmptyStateDevicesDark,
  EventsEmptyState,
  EventsEmptyStateDark,
} from 'assets/icons';
import { TWeather } from 'store/slices/weather/types';
import { devicesCountsSelector } from 'store/slices/devicesSlice/selectors';
import DeviceControlModal from 'components/Modal/DeviceControlModal';
import CustomModal from 'components/Modal';
import { futureEventSelector, pastEventsSelector } from 'store/slices/eventsSlice/selectors';

import ActivityFeed from './activityFeed';
import { EEventType } from './types/profileType';
import HeadCountsGraph from './headCountsGraph';
import PercentageOccupancyGraph from './PercentageOccupancyGraph';
import moment from 'moment-timezone';
import { getTimeZoneFromCityState } from './utils';

dayjs.extend(relativeTime);

export enum onlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

const Dashboard = () => {
  const { darkMode } = useDarkMode();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [weather, setWeather] = useState<TWeather>({});
  const userData = useAppSelector(userDataSelector);
  const {
    total_count: devicesCount,
    online_count: onlineDevicesCount,
    offline_count: offlineDevicesCount,
  } = useAppSelector(devicesCountsSelector);
  const pastEvents = useAppSelector(pastEventsSelector);
  const futureEvents = useAppSelector(futureEventSelector);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { currentOrganization, getAllOrganizations } = useUserOrganizationsList();
  const [displayDeviceType, setDisplayDeviceType] = useState<DisplayType>('cards');
  const [headCount, setHeadCount] = useState<number>(0);
  const [percentageHeadCountChange, setPercentageHeadCountChange] = useState<number>(0);
  const [devices, setDevices] = useState<TDevice[]>([]);
  const [deviceStatus, setDeviceStatus] = useState<string>('All devices');
  const [activeEventTab, setActiveEventTab] = useState<EEventType>(EEventType.UPCOMING);
  const [eventTypeCounts, setEventTypeCounts] = useState<TEventCountsResponse | undefined>({
    total_events_sum: 0,
    future_events_sum: 0,
    past_events_sum: 0,
  });
  const [currentEvents, setCurrentEvents] = useState<TEvent[]>();
  const { deviceToShow, isDeviceModalOpen, openDeviceModal, closeDeviceModal } =
    useDeviceControlModal();
  const [organizationTime, setOrganizationTime] = useState('');

  const eventsInView = useMemo(
    () =>
      activeEventTab === EEventType.UPCOMING ? futureEvents.data : pastEvents.data.slice(0, 10),
    [activeEventTab, futureEvents.data, pastEvents.data],
  );

  const getCurrentWeather = useCallback(async () => {
    const response = await dispatch(
      getWeather({
        lat: weather.latitude as number,
        lon: weather.longitude as number,
        appid: process.env.REACT_APP_WEATHER_API_KEY as string,
        units: 'imperial',
        exclude: 'minutely,hourly,daily,alerts',
      }),
    ).unwrap();

    setWeather((prevWeather) => ({
      ...prevWeather,
      temperature: response.current.temp,
      id: response.current.weather[0].id,
    }));
  }, [dispatch, weather.latitude, weather.longitude]);

  const getCoordinates = useCallback(async () => {
    const response = await dispatch(
      getLocationCoordinates({
        q: `${currentOrganization?.city},${currentOrganization?.state}, USA`,
        appid: process.env.REACT_APP_WEATHER_API_KEY as string,
      }),
    ).unwrap();

    const location = response?.[0] ?? {};

    setWeather((prevWeather) => ({
      ...prevWeather,
      latitude: location.lat,
      longitude: location.lon,
    }));
  }, [currentOrganization?.city, currentOrganization?.state, dispatch]);

  const goToEvents = useCallback(() => {
    navigate(Routes.DashboardEvents);
  }, [navigate]);

  const goToDevices = useCallback(() => {
    navigate(Routes.DashboardDevices);
  }, [navigate]);

  const overViewDetails: OverViewCardProps[] = useMemo(
    () => [
      {
        title: 'Realtime Count',
        icon: GroupOutlinedIcon,
        value: headCount,
        growth: {
          value: percentageHeadCountChange,
          time: '',
        },
        onClick: () => navigate(Routes.DashboardAnalytics),
      },
      {
        title: 'Events',
        value: eventTypeCounts?.total_events_sum ?? 0,
        icon: BubbleChartOutlinedIcon,
        detail: {
          ...(eventTypeCounts?.future_events_sum
            ? { positive: `${eventTypeCounts?.future_events_sum} upcoming` }
            : {}),
        },
        onClick: goToEvents,
      },
      {
        title: 'Devices',
        value: devicesCount,
        icon: DevicesOtherOutlinedIcon,
        detail: {
          positive: onlineDevicesCount ? `${onlineDevicesCount} Online` : '',
          negative: offlineDevicesCount ? `${offlineDevicesCount} Offline` : '',
        },
        onClick: () => navigate(Routes.DashboardDevices),
      },
    ],
    [
      devicesCount,
      eventTypeCounts?.future_events_sum,
      eventTypeCounts?.total_events_sum,
      goToEvents,
      headCount,
      navigate,
      offlineDevicesCount,
      onlineDevicesCount,
      percentageHeadCountChange,
    ],
  );

  const getHeadCount = useCallback(async () => {
    try {
      const response = await dispatch(getCurrentHeadCount()).unwrap();
      setHeadCount(response?.current_count ?? 0);
      setPercentageHeadCountChange(
        Number(parseFloat(String(response?.percent_change ?? 0)).toFixed(2)),
      );
    } catch (error: any) {
      const err = error as Error;
    }
  }, []);

  const getAllDevices = useCallback(async () => {
    const options = {
      limit: 10,
      offset: 0,
    };
    try {
      const response: any = await dispatch(getDevices(options)).unwrap();
      setDevices(response?.data ?? []);
    } catch (error: any) {
      const err = error as Error;
      setDevices([]);
    }
  }, []);

  useEffect(() => {
    const data = {
      total_events_sum: +futureEvents.total_count + +pastEvents.total_count,
      future_events_sum: futureEvents.total_count,
      past_events_sum: pastEvents.total_count,
    };

    setEventTypeCounts(data);

    // No upcoming event, default active tab is past events
    if (!data?.future_events_sum && data?.past_events_sum) {
      setActiveEventTab(EEventType.PAST);
    }
  }, [futureEvents.total_count, pastEvents.total_count]);

  const getOngoingEvents = useCallback(async () => {
    const response = await dispatch(
      getCurrentEvents({
        datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        sort_by: 'start_time',
      }),
    ).unwrap();

    setCurrentEvents(response?.data?.slice(0, 3));
  }, []);

  const getCountsofDevices = useCallback(async () => {
    await dispatch(getDevicesCounts());
  }, []);

  function onPressFullScreen() {
    setIsFullScreen((prevState) => !prevState);
  }

  useEffect(() => {
    // If we have user data, fetch data for the dashboard
    if (userData?.member_info) {
      batch(() => {
        getHeadCount();
        getAllDevices();
        getOngoingEvents();
        getCountsofDevices();
        dispatch(getActivePosts({}));
      });
    }
  }, [
    dispatch,
    getAllDevices,
    getCountsofDevices,
    getHeadCount,
    getOngoingEvents,
    userData?.member_info,
  ]);

  const organizationStateName = useMemo(
    () => stateOptions.find((state) => state.value === currentOrganization?.state)?.label,
    [currentOrganization?.state],
  );

  const filteredDevices = useMemo(() => {
    switch (deviceStatus) {
      case onlineStatus.ONLINE:
        return devices.filter(
          (device) => device.status.toLowerCase() === onlineStatus.ONLINE.toLowerCase(),
        );
      case onlineStatus.OFFLINE:
        return devices.filter(
          (device) => device.status.toLowerCase() === onlineStatus.OFFLINE.toLowerCase(),
        );
      default:
        return devices;
    }
  }, [deviceStatus, devices]);

  useEffect(() => {
    // If user has city, state, country
    // Fetch geographical coordinates
    if (currentOrganization?.city && currentOrganization?.state) getCoordinates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization?.city, currentOrganization?.state]);

  useLayoutEffect(() => {
    getAllOrganizations();
  }, []);

  useLayoutEffect(() => {
    dispatch(
      getAllEvents({
        timeframe: 'Future',
        sort_by: 'start_date',
      }),
    );
    dispatch(
      getAllEvents({
        timeframe: 'Past',
        sort_by: 'start_date',
      }),
    );
  }, []);

  useLayoutEffect(() => {
    // If we have user's latitude and logitude
    // Get the weather at their location
    if (weather.latitude && weather.longitude) getCurrentWeather();
  }, [getCurrentWeather, weather.latitude, weather.longitude]);

  const getOrganizationTime = (city: string, state: string) => {
    const timezone = getTimeZoneFromCityState(city, state);
    return moment().tz(timezone).format('h:mm A • MMM D, YYYY');
  };

  useEffect(() => {
    if (currentOrganization?.city && currentOrganization?.state) {
      const time = getOrganizationTime(currentOrganization.city, currentOrganization.state);
      setOrganizationTime(time);
    }
  }, [currentOrganization]);

  return (
    <div>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='flex flex-col gap-2'>
          <p className='font-extrabold text-[24px]'>Overview</p>
        </div>

        {/* Weather */}
        {typeof weather.temperature === 'number' ? (
          <div
            className='flex flex-col md:flex-row md:gap-5 bg-accent dark:bg-darkBg text-white p-4 mb-2 border-2 dark:border-none rounded-lg w-auto'
            id='weather'
          >
            <div>
              {currentOrganization?.city && organizationStateName ? (
                <p className='font-semibold capitalize'>
                  {currentOrganization?.city}, {organizationStateName}
                </p>
              ) : null}
              <p className='text-white opacity-70 dark:text-white'>{organizationTime}</p>
            </div>
            <div className='flex flex-row items-start gap-2'>
              <p className='text-[36px] m-0 flex items-center font-semibold'>
                {weather.temperature.toFixed(0)}
                <sup className='text-[16px]'>&#8457;</sup>
              </p>
              <WeatherSvg
                state={getWeatherIcon(weather.id)}
                height={38}
                width={38}
                className='mt-2'
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className='mt-5 xl:mt-0 mb-10 flex flex-col justify-between md:flex-row gap-7 py-1 w-full h-full'>
        <div className='overflow-x-auto no-scrollbar p-1 md:p-0 h-[180px] md:h-[230px] w-full md:w-1/2'>
          <div className='flex gap-5 items-center col-span-3 h-full ' id='overviewDetails'>
            {overViewDetails.map((d, index) => (
              <OverViewCard {...d} key={index} />
            ))}
          </div>
        </div>

        <div
          className=' col-span-3 bg-white rounded-[14px] p-3 md:p-6 shadow-md md:shadow-none w-full md:w-1/2 dark:bg-dark-card-bg'
          id='eventsGraph'
        >
          <PercentageOccupancyGraph />
        </div>
      </div>

      {/* Events and Analytics Table */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 my-10'>
        <div
          className='flex flex-col md:bg-white p-2 px-4 rounded-[14px] w-full dark:bg-dark-card-bg'
          id='eventsDetails'
        >
          <div className='my-2 flex justify-between items-center px-2'>
            <p className='font-extrabold text-[24px]'>Events</p>
            <button
              className={classNames(
                'inline text-accent font-semibold text-[15px] cursor-pointer dark:text-dark-text',
                {
                  hidden: !eventTypeCounts?.total_events_sum,
                },
              )}
              onClick={goToEvents}
            >
              See all
            </button>
          </div>
          {currentEvents?.length && (
            <>
              <p className='font-semibold'>Ongoing events</p>
              <div className='my-5 flex gap-5 w-full overflow-x-auto no-scrollbar cursor-all-scroll py-1 '>
                {currentEvents?.map(({ id, event_title, start_date, start_time }) => {
                  const startedAt = start_date + 'T' + start_time;
                  const relativeTime = dayjs(startedAt).fromNow();

                  return (
                    <OngoingEventCard
                      key={id}
                      eventID={id}
                      title={event_title}
                      relativeTime={relativeTime}
                    />
                  );
                })}
              </div>
            </>
          )}
          {eventTypeCounts?.total_events_sum ? (
            <div className='my-10'>
              <Tabs
                tabs={[
                  ...(eventTypeCounts?.future_events_sum && futureEvents.total_count
                    ? [{ text: 'Upcoming Events', value: futureEvents.total_count }]
                    : []),
                  ...(eventTypeCounts?.past_events_sum && pastEvents.total_count
                    ? [{ text: 'Past Events', value: pastEvents.total_count }]
                    : []),
                ]}
                currentTab={activeEventTab}
                setCurrentTab={(tabText) => {
                  setActiveEventTab(tabText as EEventType);
                }}
              />
              <div
                className={classNames(
                  'grid gap-6 lg:gap-7 max-h-[330px] md:max-h-fit overflow-y-auto mt-6 py-2',
                  eventsInView?.length > 0 && eventsInView?.length < 2
                    ? 'grid-cols-1'
                    : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2',
                )}
                id='clearScrollbar'
              >
                {eventsInView?.length > 0
                  ? eventsInView
                      .slice(0, 6)
                      .map(({ id, event_title, start_time, start_date, total_head_count }) => {
                        const formattedTime = start_date + 'T' + start_time;
                        const localTime = dayjs(formattedTime).format('MMM D, YYYY - h:mm A');
                        return (
                          <UpcomingEventCard
                            key={id}
                            eventID={id}
                            title={event_title}
                            time={localTime}
                            count={total_head_count}
                          />
                        );
                      })
                  : null}
              </div>
            </div>
          ) : null}
          {!eventTypeCounts?.total_events_sum && (
            <div className='flex flex-col items-center justify-center grow'>
              {darkMode ? (
                <EventsEmptyStateDark style={{ height: '12em', width: '17em' }} />
              ) : (
                <EventsEmptyState style={{ height: '12em', width: '17em' }} />
              )}
              <p className='font-semibold mt-5'>No events at this time</p>
            </div>
          )}
        </div>
        <HeadCountsGraph
          isFullScreen={isFullScreen}
          devices={devices}
          onPressFullScreen={onPressFullScreen}
          showSeeAllButton={true}
        />
      </div>

      {/* Devices and Activity Feed */}

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 my-10'>
        <div
          className='md:bg-white md:p-10 rounded-[14px] w-full dark:bg-dark-card-bg'
          id='devicesDetails'
        >
          <div className='my-2 flex justify-between items-center'>
            <p className='font-extrabold text-[24px]'>Devices</p>
            <button
              className='text-accent cursor-pointer font-semibold text-end text-[15px] dark:text-dark-text'
              onClick={goToDevices}
            >
              View all
            </button>
          </div>

          {devices.length ? (
            <>
              <div className='my-3'>
                <Tabs
                  tabs={[{ text: 'All devices' }, { text: 'Online' }, { text: 'Offline' }]}
                  currentTab={capitalizeFirstLetter(deviceStatus)}
                  setCurrentTab={(v) => setDeviceStatus(v.toLowerCase())}
                />
              </div>
            </>
          ) : null}
          <div className='my-10'>
            <div>
              {displayDeviceType == 'list' && (
                <DevicesTable devices={filteredDevices?.slice(0, 4)} />
              )}
              {displayDeviceType == 'cards' && (
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-3 '>
                  <>
                    {filteredDevices?.length > 0 &&
                      filteredDevices
                        .slice(0, 3)
                        .map((device) => (
                          <DeviceCard
                            key={device.id}
                            device={device}
                            fromDashboard
                            onOpenDeviceModal={openDeviceModal}
                          />
                        ))}
                  </>
                </div>
              )}
              {displayDeviceType == 'cards' && !filteredDevices?.length && (
                <div className='flex flex-col h-full items-center justify-center'>
                  {darkMode ? (
                    <EmptyStateDevicesDark style={{ height: '12em', width: '17em' }} />
                  ) : (
                    <EmptyStateDevices style={{ height: '12em', width: '17em' }} />
                  )}
                  <p className='font-semibold pt-1'>Oops, No devices available to show here.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <ActivityFeed />
      </div>
      <DeviceControlModal
        isOpen={isDeviceModalOpen}
        onClose={closeDeviceModal}
        deviceId={deviceToShow?.deviceId}
        deviceName={deviceToShow?.deviceName}
        mediaUrl={deviceToShow?.mediaUrl}
        modalType={deviceToShow?.modalType}
      />
      <CustomModal isOpen={isFullScreen} onClose={onPressFullScreen} className='h-[90vh] '>
        <HeadCountsGraph
          isFullScreen={isFullScreen}
          devices={devices}
          onPressFullScreen={onPressFullScreen}
        />
      </CustomModal>
    </div>
  );
};

export default Dashboard;
