export const defaultSteps = [
  {
    target: '#switch-org',
    content: 'Do you have more than one organization? switch here!',
    disableBeacon: true,
  },
];
export const dashboardSteps = [
  {
    target: '#switch-org',
    content: 'Do you have more than one organization? switch here!',
    disableBeacon: true,
  },
  {
    target: '#weather',
    content: "Check your organization's weather",
    disableBeacon: true,
  },
  {
    target: '#overviewDetails',
    content: 'Quick overview of realtime count, devices and events',
    disableBeacon: true,
  },
  {
    target: '#eventsGraph',
    content: 'This graph shows the realtime count for all events',
    disableBeacon: true,
  },
  {
    target: '#eventsDetails',
    content: 'This section shows all records of ongoing, upcoming and past events',
    disableBeacon: true,
  },
  {
    target: '#analyticsDetails',
    content: 'This graph shows the realtime count for all events',
    disableBeacon: true,
  },
  {
    target: '#devicesDetails',
    content: 'This section captures devices linked to an event',
    disableBeacon: true,
  },
  {
    target: '#activityFeedDetails',
    content: 'This records activities within the organization',
    disableBeacon: true,
  },
  {
    target: '#settingsLink',
    content: 'Update your profile, notification, organization and billing details',
    disableBeacon: true,
  },
  {
    target: '#activityLink',
    content: 'Create posts, like and comment on posts within the organization',
    disableBeacon: true,
  },
  {
    target: '#teamLink',
    content: 'Create team and add team members within your organization',
    disableBeacon: true,
  },
  {
    target: '#devicesLink',
    content: 'This records devices within the organization',
    disableBeacon: true,
  },
  {
    target: '#eventLink',
    content: 'Create, update and attach devices to your event',
    disableBeacon: true,
  },
  {
    target: '#analyticsLink',
    content: 'View metrics for Realtime Counts, Seating Density and Headcounts',
    disableBeacon: true,
  },
];

export const analyticsTourSteps = [
  {
    target: '#countOverview',
    content: 'View current realtime count from all events',
    disableBeacon: true,
  },
  {
    target: '#devicesOverview',
    content: 'Quick overview of all devices',
    disableBeacon: true,
  },
  {
    target: '#realtimeCount',
    content: 'This graph shows the realtime count for all events',
    disableBeacon: true,
  },
  {
    target: '#seatingDensity',
    content: 'View Seating Density for all events',
    disableBeacon: true,
  },
  {
    target: '#headCount',
    content: 'This graph shows the previous counts for all devices',
    disableBeacon: true,
  },
  {
    target: '#deviceInHeadcount',
    content: 'Selected a device will display the head count for that device',
    disableBeacon: true,
  },
];

export const eventTourStep = [
  {
    target: '#addEvent',
    content: 'Click here to add your event(s)',
    disableBeacon: true,
  },
  {
    target: '#eventListSection',
    content: 'List of all your upcoming, ongoing and past events shows up here!',
    disableBeacon: true,
  },
];

export const deviceTourStep = [
  {
    target: '#addDevice',
    content: 'Click here to add a device',
    disableBeacon: true,
  },
  {
    target: '#devicesList',
    content: 'A snapshot of all your devices (both online and offline) shows up here',
    disableBeacon: true,
  },
];

export const activityFeedTourSteps = [
  {
    target: '#addActivityPost',
    content: 'Post about something',
    disableBeacon: true,
  },
  {
    target: '#imageUploadLink',
    content: 'Upload images',
    disableBeacon: true,
  },
  {
    target: '#askHawkeyeVisionTech',
    content: 'Want to create an event, device etc? Ask HawkeyeVision AI Agent',
    disableBeacon: true,
  },
];

export const teamsTourSteps = [
  {
    target: '#addTeam',
    content: 'Click here to add a team!',
    disableBeacon: true,
  },
  {
    target: '#teamMemberSection',
    content: 'A list of your team members shows here!',
    disableBeacon: true,
  },
];

export const settingsProfileSteps = [
  {
    target: '#profileArea',
    content: 'You can update your profile information such as address, logo, name etc.',
    disableBeacon: true,
  },
  {
    target: '#EditProfile',
    content: 'Click here to make changes',
    disableBeacon: true,
  },
  {
    target: '#editProfileImageUpload',
    content: 'Click here to update profile Image',
    disableBeacon: true,
  },
  {
    target: '#invites',
    content: 'Here you can invite team members to your organization',
    disableBeacon: true,
  },
  {
    target: '#notifications',
    content: 'Receive Notifications either via Push, Email or SMS',
    disableBeacon: true,
  },
  {
    target: '#organization',
    content: 'You can update your Organizations information such as address, logo, name etc.',
    disableBeacon: true,
  },
  {
    target: '#billing',
    content: 'View and/or change current plan, view Billing History and download Invoices',
    disableBeacon: true,
  },
  {
    target: '#api',
    content: 'Generate API key so you can plug into the APIs to run images and zip files',
    disableBeacon: true,
  },
];

export const inviteProfileSteps = [
  {
    target: '#inviteATeamMember',
    content: 'Invite a team member',
    disableBeacon: true,
  },
];

export const settingsOrgSteps = [
  {
    target: '#orgArea',
    content: 'You can update your organization information such as address, logo, name etc.',
    disableBeacon: true,
  },
  {
    target: '#editOrgSettings',
    content: 'Click here to make changes',
    disableBeacon: true,
  },
  {
    target: '#editOrgImageUpload',
    content: 'Click here to update profile Image',
    disableBeacon: true,
  },
];

export const billingsSettingsSteps = [
  {
    target: '#changePlanTour',
    content: 'You can Change your subscription plan here.',
    disableBeacon: true,
  },
  {
    target: '#billingsElipsesTour',
    content:
      'Click here to change default payment, edit billing address or delete billing information',
    disableBeacon: true,
  },
  {
    target: '#downloadReceiptBillingTour',
    content: 'Click here download your receipt at anytime!',
    disableBeacon: true,
  },
];

export const notificationTabSteps = [
  {
    target: '#notificationTabSteps',
    content:
      'Update notifications for comments on your posts and replies to our comments, tags, post or story, updates you might have missed and more.',
    disableBeacon: true,
  },
];
