export const inferenceTypeOptions = [
  { id: 1, label: 'Audience Metrics', value: 'crowd_counting' },
  { id: 2, label: 'Object Tracking', value: 'object_tracking' },
  { id: 3, label: 'Pose Detection', value: 'pose_detection' },
  { id: 4, label: 'Heat Map', value: 'heat_map' },
];

export const eventDurationOptions = [
  { id: 1, label: '30 minutes', value: 0.5 },
  { id: 2, label: '1 hour', value: 1 },
  { id: 3, label: '2 hours', value: 2 },
  { id: 4, label: '3 hours', value: 3 },
  { id: 5, label: '4 hours', value: 4 },
];

export const eventRepeatTypeOptions = [
  { id: 1, label: 'Does not repeat', value: 'None' },
  { id: 2, label: 'Daily', value: 'Daily' },
  { id: 3, label: 'Weekly', value: 'Weekly' },
  { id: 4, label: 'Monthly', value: 'Monthly' },
  { id: 5, label: 'Yearly', value: 'Yearly' },
  { id: 6, label: 'Every weekday (Monday to Friday)', value: 'Every weekday (Monday to Friday)' },
  { id: 7, label: 'Custom' },
];
