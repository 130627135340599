import { RootState } from 'types';
export const postsSelector = (state: RootState) => state.activities.posts;
export const updatingPostSelector = (state: RootState) => state.activities.isUpdatingPost;
export const deletingPostSelector = (state: RootState) => state.activities.isDeletingPost;

export const notificationsSelector = (state: RootState) => state.activities.notifications;

export const allPostComments = (state: RootState) => state.activities.postComments;
export const creatingPostsSelector = (state: RootState) => state.activities.creatingPost;
export const newsSelector = (state: RootState) => state.activities.news;
