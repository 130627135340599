import { FC, useEffect, useState ,useCallback} from 'react';

import { TDevice } from 'store/slices/devicesSlice/type';
import { devicesPlaceholderImg } from 'assets/images';
import { getSignedMediaUrl } from 'api/media';
import { useAppDispatch } from 'app/hooks';
import { getEventByID } from 'store/thunks';
import { eventByIDSelector } from 'store/slices/eventsSlice/selectors';
import CustomModal from 'components/Modal';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useAppSelector } from 'hooks';
export const DeviceHistoryCard: FC<{
  eventID?:number;
  device: TDevice;
  setShowEditDevice?: any;
  totalCount?: number;
}> = ({ device, totalCount }) => {
  const [mediaUrl, setMediaUrl] = useState<string>();
  const [mediaUrl2, setMediaUrl2] = useState<string>();
  const [highResMediaUrl, setHighResMediaUrl] = useState<string | undefined>();
  const [eventName, setEventName] = useState<string>();
  const [eventDate, setEventDate] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const event = useAppSelector(eventByIDSelector);
  const dispatch = useAppDispatch();
  

  useEffect(() => {
    if (device?.image_url_id) {
      getSignedMediaUrl({
        folder: 'analytics',
        media_url_id: device?.image_url_id,
        thumbnail: true,
      })
        .then((response) => {
          setMediaUrl(response.data.url);
          setMediaUrl2(response.data.url);
        })
        .catch((e) => {
          console.error('Error fetching media URL (thumbnail):', e);
        });

      getSignedMediaUrl({
        folder: 'analytics',
        media_url_id: device?.image_url_id,
        thumbnail: false,
      })
        .then((response) => {
          setHighResMediaUrl(response.data.url);
        })
        .catch((e) => {
          console.error('Error fetching media URL (high-res):', e);
        });
    }

    // Fetch event details
    if (device?.event_id) {
      const fetchEvent = async () => {
        const id: number = device.event_id;
        await dispatch(getEventByID(id));
      };

      fetchEvent();
    }
  }, [device, dispatch]);
  const handleImageLoad = () => {
    if (highResMediaUrl) {
      setMediaUrl(highResMediaUrl);

    }
  };
  const handleImageLoad2 = () => {
    if (highResMediaUrl) {
      setMediaUrl2(highResMediaUrl);
    }
  };
  const DateFormatting = (date: string): string => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1; 
    const day = newDate.getDate();
    const year = newDate.getFullYear().toString().slice(-2); 
    return `${month}/${day}/${year}`;
  };
  const handleImageClick = () => {
    setIsModalOpen(true); 
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  useEffect(() => {
    if (event?. event_title) {
      setEventName(event. event_title);
    }
    if (event) {
    setEventDate(DateFormatting(event?.created_at))
    }
  }, [event]);

  return (
    <div className='p-3 flex flex-col gap-3 shadow-md bg-white dark:bg-darkBg rounded-[5px] w-fill 
      md:max-w-[400px]'>
      {/* image section */}
      <div className='flex gap-2 flex-col items-center '>
        <img
          src={mediaUrl || devicesPlaceholderImg}
          style={{ objectFit: 'cover' }}
          className='w-full h-40 rounded-[5px]'
          onLoad={handleImageLoad} 
          onClick={handleImageClick}
        />
      </div>
      {/* image section */}

      {/* bottom section */}
      <div className='flex flex-col gap-1'>
  <div className="flex justify-between gap-1">
    <p className="capitalize font-semibold text-[15px]">{eventName}</p>
    <p className="capitalize font-semibold text-[15px]">{eventDate}</p>
  </div>


     
        <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
        <p className='capitalize font-semibold text-[12px] opacity-70'>Total Count:</p>
        <p className='capitalize font-semibold text-[12px]  opacity-70'>{device?.headcount}</p>
      </div>

{/*         {totalCount ? (
           <div className='flex items-center gap-2'>
            <p className='capitalize font-semibold text-[12px] md:text-[8px] opacity-70 '>Total Count:</p>
            <p className='capitalize font-semibold text-[12px] md:text-[8px] opacity-70'>{totalCount}</p>
            </div>
        ) : null} */}
         </div>
      </div>
      {/* bottom section */}
      <CustomModal isOpen={isModalOpen} 
      onClose={handleCloseModal}
                className='max-w-lg h-full 2xl:max-h-fit min-w-[400px] md:min-w-[700px] lg:min-w-[1000px] max-h-screen dark:bg-darkBg rounded-md shadow-lg pt-2
              bg-gray-100 -mt-2 overflow-x-scroll no-scrollbar'
      >
        <div id='clearScrollbar' className='space-y-2 -mx-3 flex justify-end'>
          <CancelPresentationIcon
                  className='  opacity-50 cursor-pointer hover:text-red-600 hover:opacity-85'
                  onClick={handleCloseModal}
                />
                </div>
          <img
            src={mediaUrl2 || devicesPlaceholderImg}
            alt="media"
            onLoad={handleImageLoad2} 
           className='object-cover w-full h-[520px] rounded-xl'
          />
      </CustomModal>
    </div>
  );
};
