import React, { ChangeEvent, useState } from 'react';

interface ImageUploadProps {
  onImageUpload: (image: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImageUpload }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const imageUrl = reader.result as string;
          setImageSrc(imageUrl);
          onImageUpload(imageUrl);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className='mb-8 -mt-4'>
      <label htmlFor='file-upload' className='block font-semibold mb-2 text-gray-700 text-sm'>
        Upload Venue Floor Plan
      </label>
      <input
        id='file-upload'
        type='file'
        accept='image/*'
        onChange={handleFileChange}
        className='hidden'
      />
      <label
        htmlFor='file-upload'
        className='block w-fit cursor-pointer text-sm text-white bg-accent hover:bg-blue-600 py-2 px-6 rounded-md text-center'
      >
        Choose File
      </label>

      {imageSrc && (
        <div className='mt-4'>
          <img
            src={imageSrc}
            alt='Uploaded'
            className='w-full h-[200px] object-contain border-2 border-gray-300 rounded-md'
          />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
