import {
  Home,
  EventsSection,
  Login,
  ContactUs,
  OrganizationsList,
  NotFound,
  Profile,
  IndustryLayout,
  DashboardActivities,
  DashboardTeam,
  DashboardEvent,
  DashboardIndex,
  DashboardDevices,
  DashboardSettings,
  NotificationSettings,
  OrganizationSettings,
  BillingSettings,
  InvitesSettings,
  ProfileSettings,
  Success,
  SignUp,
  ProcessForm,
  DashboardAnalytics,
  Help,
  OnPremSettings,
  DashboardSeatMapping,
  ObjectDetector,
  DashboardChats,
  APISettings,
} from '../containers';
import { RoutesProps, Routes } from '../types';

const routes: RoutesProps[] = [
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Landing,
    isProtected: false,
    component: <Home />,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Invitation,
    isProtected: false,
    component: <Home />,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Error,
    isProtected: false,
    component: <span className='grid place-content-center py-20'>Unknown error occured</span>,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.NotFound,
    isProtected: false,
    component: <NotFound />,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    path: Routes.ProcessForm,
    withNavbar: true,
    isProtected: true,
    component: <ProcessForm />,
  },
  {
    isLanding: true,
    path: Routes.SignUp,
    withNavbar: true,
    isProtected: false,
    component: <SignUp />,
  },
  {
    isLanding: true,
    path: Routes.Success,
    withNavbar: true,
    isProtected: true,
    component: <Success />,
  },

  {
    isLanding: true,
    path: Routes.ObjectDetector,
    withNavbar: true,
    isProtected: true,
    component: <ObjectDetector />,
  },

  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Error,
    isProtected: false,
    component: <Home />,
    withLogoHeader: true,
  },

  {
    isLanding: true,
    path: Routes.Login,
    component: <Login />,
    isProtected: false,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    path: Routes.OrganizationsList,
    isProtected: true,
    withLogoHeader: true,
    component: <OrganizationsList />,
  },
  {
    isLanding: true,
    path: Routes.Industriespage,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Transport,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Construction,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Hospitality,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Ministries,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Production,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Profile,
    withLogoHeader: true,
    isProtected: true,
    withNavbar: true,
    component: <Profile />,
  },
  {
    isLanding: true,
    path: Routes.ContactUs,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <ContactUs />,
  },
  {
    isLanding: true,
    path: Routes.Events,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: (
      <IndustryLayout>
        <EventsSection />
      </IndustryLayout>
    ),
  },
  {
    isLanding: false,
    path: Routes.Dashboard,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardIndex />,
  },
  {
    isLanding: false,
    path: Routes.DashboardAnalytics,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardAnalytics />,
  },
  {
    isLanding: false,
    path: Routes.SeatMapping,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardSeatMapping />,
  },
  {
    isLanding: false,
    path: Routes.DashboardEvents,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardEvent />,
  },
  {
    isLanding: false,
    path: Routes.DashboardDevices,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardDevices />,
  },
  {
    isLanding: false,
    path: Routes.DashboardDevice,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardDevices />,
  },
  {
    isLanding: false,
    path: Routes.DashboardSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <ProfileSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.Help,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <Help />,
  },
  {
    isLanding: false,
    path: Routes.DashboardTeam,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardTeam />,
  },
  {
    isLanding: false,
    path: Routes.ActivityFeed,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardActivities />,
  },
  {
    isLanding: false,
    path: Routes.ProfileSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <ProfileSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.NotificationSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <NotificationSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.OrganizationSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <OrganizationSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.InvitesSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <InvitesSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.BillingSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <BillingSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.OnPremSettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <OnPremSettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.APISettings,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: (
      <DashboardSettings>
        <APISettings />
      </DashboardSettings>
    ),
  },
  {
    isLanding: false,
    path: Routes.DashboardChats,
    withLogoHeader: true,
    isProtected: true,
    isProtectedWithSession: true,
    withNavbar: true,
    component: <DashboardChats />,
  },
];

export default routes;
