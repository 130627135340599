import React, { ReactNode, useState } from 'react';
import MessagingApp from 'components/chat-ui/Chat-ui';

interface ChatAgentProps {
  children?: ReactNode; // To allow additional content if needed
}

const ChatAgent: React.FC<ChatAgentProps> = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleOpenChat = () => setIsChatOpen(true);
  const handleCloseChat = () => setIsChatOpen(false);

  return (
    <div>
      {/* Floating Trigger Button */}
      {!isChatOpen && (
        <div className='fixed bottom-6 right-6 z-50 flex items-center gap-4'>
          {/* Chat Button with Image and Message */}
          <div
            onClick={handleOpenChat}
            className='cursor-pointer flex items-center bg-accent dark:bg-black text-white rounded-full shadow-lg p-2 hover:bg-blue-700 transition-all'
          >
            <svg
              className='w-12 h-12'
              version='1.0'
              xmlns='http://www.w3.org/2000/svg'
              width='256.000000pt'
              height='256.000000pt'
              viewBox='0 0 256.000000 256.000000'
              preserveAspectRatio='xMidYMid meet'
            >
              <g
                transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)'
                fill='#FFF'
                stroke='none'
              >
                <path
                  d='M1560 2124 c-31 -138 -45 -155 -156 -190 -47 -15 -82 -30 -77 -34 4
-4 35 -15 68 -25 123 -35 131 -45 171 -215 14 -61 28 -110 30 -108 2 2 14 55
28 116 34 159 45 172 179 207 37 10 67 22 67 26 0 5 -5 9 -11 9 -6 0 -43 10
-81 21 -107 33 -124 53 -156 187 -14 61 -29 112 -33 112 -3 0 -16 -48 -29
-106z'
                />
                <path
                  d='M2086 1880 c-6 -36 -11 -43 -49 -59 l-42 -18 32 -7 c39 -9 48 -19 63
-76 7 -25 14 -40 16 -35 2 6 8 29 14 52 9 36 16 43 48 54 l37 12 -26 11 c-42
17 -58 36 -65 72 -8 46 -20 43 -28 -6z'
                />
                <path
                  d='M1896 1601 c-10 -43 -23 -86 -29 -95 -7 -9 -41 -27 -76 -40 -70 -25
-70 -26 7 -51 64 -20 76 -37 101 -140 20 -87 23 -85 45 20 17 82 39 106 115
126 28 7 51 16 51 19 0 3 -23 12 -51 19 -76 20 -97 43 -120 136 -12 44 -22 81
-23 83 -2 1 -11 -33 -20 -77z'
                />
                <path
                  d='M746 1368 c-17 -16 -76 -145 -180 -396 -86 -206 -156 -382 -156 -392
0 -45 42 -80 92 -80 50 1 72 25 109 122 l37 93 144 3 c109 2 148 -1 155 -10 6
-7 22 -48 38 -90 33 -94 52 -113 112 -113 37 0 49 5 70 29 15 18 23 37 20 50
-2 12 -75 191 -162 399 -137 328 -162 380 -186 393 -39 20 -64 18 -93 -8z m92
-366 c51 -135 56 -122 -37 -122 -58 0 -81 4 -81 13 0 24 72 197 80 192 5 -3
22 -40 38 -83z'
                />
                <path
                  d='M1408 1379 c-56 -22 -59 -42 -56 -449 l3 -370 27 -27 c37 -37 96 -38
132 -4 l26 24 0 392 0 392 -23 21 c-28 26 -72 35 -109 21z'
                />
              </g>
            </svg>
          </div>
        </div>
      )}

      {/* Chat Window */}
      {isChatOpen && (
        <div className='fixed bottom-6 right-6 w-[400px] max-w-full bg-white dark:bg-gray-900 shadow-lg rounded-lg overflow-hidden z-50 animate-slide-up'>
          {/* Chat Content */}
          <div className='h-[500px] flex flex-col'>
            {/* Messaging UI */}
            <div className='flex-1 p-4 overflow-y-auto'>
              <MessagingApp
                selectedChat={{
                  id: 1,
                  name: 'Hawkeye AI Agent',
                  profilePicture: 'https://randomuser.me/api/portraits/men/1.jpg',
                }}
                onClose={handleCloseChat}
              />
            </div>
          </div>
        </div>
      )}

      {/* Render children */}
      {children}
    </div>
  );
};

export default ChatAgent;
