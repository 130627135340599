import React from 'react';
import ControlledInput from 'components/shared/ControlledInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

interface Polygon {
  name: string;
  description: string;
}

interface PolygonCardProps {
  polygons: Polygon[];
  deviceEditControl: any; // Replace with a specific type if available
  handleEditPolygon: (index: number) => void;
  handleDeletePolygon: (index: number) => void;
  loading?: boolean;
}

const PolygonCard: React.FC<PolygonCardProps> = ({
  polygons,
  deviceEditControl,
  handleEditPolygon,
  handleDeletePolygon,
  loading = false,
}) => {
  const [currentScrollPosition, setCurrentScrollPosition] = React.useState(0);
  const [atEndOfGallery, setAtEndOfGallery] = React.useState(false);

  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCurrentScrollPosition(scrollLeft);
      setAtEndOfGallery(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const handleScrollClick = (direction: 'backward' | 'forward') => {
    if (scrollContainerRef.current) {
      const cardWidth = scrollContainerRef.current.scrollWidth / polygons.length; // Get individual card width
      const scrollAmount = cardWidth * 3; // Scroll by three cards
      scrollContainerRef.current.scrollBy({
        left: direction === 'forward' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className='relative'>
      <div className='flex flex-row justify-between w-full'>
        {/* Backward Scroll Button */}
        {polygons.length > 3 && currentScrollPosition > 0 && (
          <button
            onClick={() => handleScrollClick('backward')}
            className='h-10 w-10 bg-black opacity-80 rounded-full text-white shadow-sm hover:bg-gray-700 flex items-center justify-center'
          >
            <ChevronLeftIcon className='w-5 h-5' />
          </button>
        )}

        {/* Polygon Gallery */}
        <div className='flex flex-row justify-between flex-1 overflow-hidden'>
          {loading ? (
            <div
              id='skeletonGallery'
              className='flex gap-2 overflow-x-scroll no-scrollbar px-2 max-h-36 relative'
            >
              {[...Array(8)].map((_, index) => (
                <div
                  key={index}
                  className='h-28 w-36 bg-gray-200 animate-pulse rounded-2xl'
                ></div>
              ))}
            </div>
          ) : polygons.length > 0 ? (
            <div
              id='polygonGallery'
              className='flex gap-4 overflow-x-scroll no-scrollbar px-1'
              ref={scrollContainerRef}
              onScroll={handleScroll}
              style={{ width: 'calc(100% - 80px)' }} // Adjust width to account for buttons
            >
              {polygons.map((polygon, index) => (
                <div
                  key={index}
                  className='flex flex-row w-1/6 p-4 border border-gray-200 rounded-lg shadow-sm bg-gray-50'
                  style={{ minWidth: '33.33%' }} // Restrict to 3 cards per view
                >
                  <div className='flex flex-col gap-4'>
                    <ControlledInput
                      control={deviceEditControl}
                      name={`polygonName-${index}`}
                      label='Polygon Name'
                      defaultValue={polygon.name}
                      placeholder='Polygon Name'
                    />
                    <ControlledInput
                      control={deviceEditControl}
                      name={`polygonDescription-${index}`}
                      label='Polygon Description'
                      defaultValue={polygon.description}
                      placeholder='Polygon Description'
                    />
                  </div>
                  <div className='flex gap-2 mt-2'>
                    <button
                      onClick={() => handleEditPolygon(index)}
                      className='h-8 w-8 bg-blue-500 hover:bg-blue-600 text-white rounded-full flex items-center justify-center'
                    >
                      <PencilIcon className='w-4 h-4' />
                    </button>
                    <button
                      onClick={() => handleDeletePolygon(index)}
                      className='h-8 w-8 bg-red-500 hover:bg-red-600 text-white rounded-full flex items-center justify-center'
                    >
                      <TrashIcon className='w-4 h-4' />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='flex items-center justify-center h-28 w-36 bg-gray-100 rounded-lg'>
              <span>No polygons available</span>
            </div>
          )}
        </div>

        {/* Forward Scroll Button */}
        {polygons.length > 3 && !atEndOfGallery && (
          <button
            onClick={() => handleScrollClick('forward')}
            className='h-10 w-10 bg-black opacity-80 rounded-full text-white shadow-sm hover:bg-gray-700 flex items-center justify-center'
          >
            <ChevronRightIcon className='w-5 h-5' />
          </button>
        )}
      </div>
    </section>
  );
};

export default PolygonCard;
