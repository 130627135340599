import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import Box from '@mui/material/Box';
import toast from 'react-hot-toast';
import Modal from '@mui/material/Modal';
import { sizeLimits } from 'constants/SizeLimits';
import { UploadMessages } from 'constants/ToastMessages';

interface Props {
  className?: string;
  onChange?: (value: string) => void;
  value?: string | null | undefined;
  label: string;
  required?: boolean;
  isLoading?: boolean;
  getFileImg?: any;
  getFile?: any;
}

const style: any = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '40em',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '1em',
  boxShadow: 24,
  display: 'flex',
  justifyContent: 'center',
  p: 4,
  objectFit: 'cover',
};

const UploadBannerPhoto = ({
  className,
  onChange,
  label,
  required,
  value,
  isLoading,
  getFile,
  getFileImg,
}: Props) => {
  const [src, setSrc] = useState<string>(value || '');
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (value) {
      setSrc(value);
    }
  }, [value]);

  const handlePhotoChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = e;
      if (target.files && target.files.length > 0) {
        setLoading(true);
        if (Number(target.files[0].size) > sizeLimits.image) {
          toast(UploadMessages.IMAGE_SIZE_EXCEEDED);
          setSrc('');
          return;
        } else {
          setSrc(URL.createObjectURL(target.files[0]));
          getFileImg(URL.createObjectURL(target.files[0]));
        }
      } else {
        setSrc('');
        setLoading(false);
      }

      const selectedFile = target.files?.[0];
      if (selectedFile) {
        getFile(selectedFile);
      }
    },
    [setSrc, onChange],
  );

  const uploadBannerPhotoRef = useRef<HTMLInputElement>(null);
  function handleOpenPhotoPicker() {
    if (uploadBannerPhotoRef.current) {
      uploadBannerPhotoRef.current.click();
    }
  }

  return (
    <>
      <div className={className}>
        <div>
          <input
            ref={uploadBannerPhotoRef}
            type='file'
            accept='image/*'
            alt='Upload photo'
            className='hidden'
            onChange={handlePhotoChange}
          />

          <div className='bg-card-bg dark:bg-darkBg overflow-hidden center-child w-full h-[300px]  cursor-pointer border-dashed border-[2px] border-grey-outline relative group'>
            {src ? (
              <img
                src={src}
                alt={label}
                className='w-full h-full object-contain'
                onClick={handleOpen}
              />
            ) : (
              <div
                role='button'
                tabIndex={0}
                onClick={() => handleOpenPhotoPicker()}
                onKeyDown={() => handleOpenPhotoPicker()}
                className='absolute w-full h-full items-center justify-center text-sub-text text-sm flex'
              >
                <button type='button' className='flex flex-col items-center gap-2'>
                  <Icon icon='solar:upload-linear' />
                  <span className='text-sm'>{label}</span>
                </button>
              </div>
            )}
          </div>

          {src && (
            <button
              onClick={() => handleOpenPhotoPicker()}
              type='button'
              className='w-max block mx-auto text-accent mt-1 text-sm'
            >
              <PublishedWithChangesIcon />
            </button>
          )}
        </div>
      </div>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <img
              src={src}
              className='cursor-pointer'
              onClick={() => {
                setOpen(false);
                handleOpenPhotoPicker();
              }}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

UploadBannerPhoto.defaultProps = {
  className: '',
  onChange: () => {},
  required: false,
  isLoading: false,
  value: '',
};

export default UploadBannerPhoto;
