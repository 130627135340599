import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { CustomTextField } from 'components';
import { Colors, FontNames } from 'types';

import { TControlledInputProps } from './types';

const ControlledInput: FC<TControlledInputProps> = ({
  name,
  rows,
  label,
  value,
  control,
  padding,
  error,
  disabled,
  readonly,
  variant,
  className,

  rightIcon,
  placeholder,
  borderColor = Colors.SOFT_SILVER,
  defaultValue,
  rulesMessage,
  fontFamily = FontNames.AVENIR_NEXT_PRO,
  labelColor = Colors.ENIGMATIC_MIDNIGHT,
  fromPayment,
  helperText,
  borderRadius,
  type = 'text',
  backGroundColor,
  backgroundColor,
  required = false,
  multiline = false,
  onEndAdornmentClick,
  autoComplete = 'off',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{ required: rulesMessage }}
      render={({ field, formState: { errors } }) => {
        const derivedValue = value !== undefined ? value : field.value;
        return (
          <CustomTextField
            {...rest}
            autoComplete={autoComplete}
            label={label}
            rows={rows}
            type={type}
            variant={variant}
            padding={padding}
            disabled={disabled}
            inputRef={field?.ref}
            readonly={readonly}
            required={required}
            rightIcon={rightIcon}
            className={className}
            multiline={multiline}
            onBlur={field?.onBlur}
            labelColor={labelColor}
            fontFamily={fontFamily}
            borderColor={borderColor}
            placeholder={placeholder}
            fromPayment={fromPayment}
            onChange={(e) => {
              field.onChange(e);
              if (rest?.onChange) rest.onChange(e);
            }}
            borderRadius={borderRadius}
            value={derivedValue}
            error={!!error || !!errors[name]}
            tabIndex={readonly ? -1 : undefined}
            onEndAdornmentClick={onEndAdornmentClick}
            backGroundColor={backgroundColor || backGroundColor}
            helperText={helperText || (errors[name]?.message as string)}
          />
        );
      }}
    />
  );
};

export default ControlledInput;
