import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { IconButton } from '@mui/material';
import { upcomingEvent } from 'assets/images';
import { useAppDispatch } from 'hooks';
import { getSignedMediaUrl } from 'api/media';
import { getDeviceByIdRequest } from 'api/devices';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDevicesHistoryByID } from 'store/thunks';

const limit = 1;
const offset = 0;
const HIDE = true;

type TUpcomingEventCardProps = {
  title: string;
  eventID: number;
  time: string;
  count?: number;
};
export const UpcomingEventCard = ({ title, eventID, time, count }: TUpcomingEventCardProps) => {
  const [eventImg, setEventImg] = useState('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (eventID) {
      getLatestEventImg(eventID);
    }
  }, []);
  const getLatestEventImg = async (Id: number) => {
    const options = {
      limit,
      offset,
      id: Id,
    };
    try {
      const response = await dispatch(getDevicesHistoryByID(options)).unwrap();
      if (Array.isArray(response.data) && response.data.length > 0) {
        const { image_url_id } = response.data[0];
        if (!image_url_id) {
          throw new Error('No image found in devices history.');
        }
        const mediaResponse = await getSignedMediaUrl({
          folder: 'analytics',
          media_url_id: image_url_id as string,
          thumbnail: true,
        });
        if (mediaResponse.data.url) {
          setEventImg(mediaResponse.data.url);
        }
      }
    } catch (err) {
      console.log('Failed to fetch media URL');
    }
  };
  return (
    <div className='flex  items-start justify-between bg-white md:bg-[#F7F7F7] p-3 rounded-[7px] lg:rounded-[14px] transition-all shadow-md md:shadow-sm dark:bg-dark-card-bg'>
      <div className='flex gap-2 items-center'>
        <img
          src={eventImg || upcomingEvent}
          alt='upcoming event'
          className='h-[60px] md:h-[90px] w-[60px] md:w-[90px] rounded-xl '
        />
        <div className='flex md:items-center justify-between flex-col md:flex-row gap-2'>
          <div className='flex flex-col md:gap-1'>
            <p className='max-w-[220px] md:max-w-sm text-black dark:text-white font-semibold text-[13px] md:text-[16px]'>
              {title}
            </p>
            <p className='text-[11px]'>{time}</p>
            {typeof count === 'number' ? (
              <p className='max-w-[220px] md:max-w-sm text-black dark:text-white font-semibold text-[11px]'>
                {count} {'people'}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {HIDE ? null : (
        <IconButton>
          <MoreHorizOutlinedIcon className='text-black' />
        </IconButton>
      )}
    </div>
  );
};
