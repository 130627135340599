import { VoidCallback } from 'types';
import CustomModal from '.';

export type TConfirmDeleteProps = {
  isOpen: boolean;
  onClose: VoidCallback;
  title: string;
  description: string;
  onDelete?: VoidCallback;
  deleteText?: string;
};

const ConfirmDeleteModal = ({
  isOpen = false,
  onClose,
  title,
  description,
  deleteText = 'Remove',
  onDelete,
}: TConfirmDeleteProps) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      className='max-w-max max-h-max flex items-center justify-center'
    >
      <div className='w-[90%] max-w-[500px] bg-white dark:bg-darkBg p-6 rounded-lg'>
        <div className='text-center mb-6'>
          <p className='text-lg md:text-xl font-semibold mb-3'>{title}</p>
          <p className='text-sm md:text-base text-gray-600 dark:text-gray-300'>{description}</p>
        </div>
        <div className='flex justify-center gap-4'>
          <button
            className='py-2 px-6 bg-gray-200 dark:bg-btn-dark dark:text-white rounded-md text-sm md:text-base cursor-pointer hover:bg-gray-300'
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='py-2 px-6 bg-red-600 text-white rounded-md text-sm md:text-base cursor-pointer hover:bg-red-700'
            onClick={onDelete}
          >
            {deleteText}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmDeleteModal;
