import { FC, useState } from 'react';
import { FormControl, RadioGroup } from '@mui/material';

import { useWindowSize } from 'hooks';
import { TPlanDurationPrices, TSubscriptionFilter } from 'types/global/durationTypes';
import { API_FILTER, subscriptionPlansNew } from 'constants/SubscriptionPlans';
import SubscriptionPlanCard from 'components/views/SubscriptionPlanCard/SubscriptionPlanCard';
import ProcessFormTitle from 'components/shared/ProcessFormTitle';
import DurationTabs from 'components/shared/DurationTabs';
import SlidePagination from 'components/views/SlidePagination/SlidePagination';
import { ProcessSubType } from 'containers/ProcessForm/types';

import breakPoints from '../../constants/BreakPoints';

import styles from './SubscriptionPlanBoxes.module.scss';

import type { TSubscriptionPlanBoxesProps } from './types';

const SubscriptionPlanBoxes: FC<TSubscriptionPlanBoxesProps> = ({
  cardClasses,
  className,
  activePlanTitle,
  defaultDuration,
  activeCardForSend,
  withTitle = false,
  activePlanInterval,
  setActiveCardForSend,
}) => {
  const { width } = useWindowSize();

  const [activeDuration, setActiveDuration] = useState<TPlanDurationPrices>({
    id: 1,
    duration: 'Monthly',
  });

  const [subscriptionType, setSubscriptionType] = useState<TSubscriptionFilter>('Device');

  const filteredPlans = subscriptionPlansNew.filter((plan) => plan.type === subscriptionType);

  const plans = filteredPlans.map((plan) => (
    <SubscriptionPlanCard
      plan={plan}
      key={plan.id}
      className={cardClasses}
      duration={activeDuration}
      defaultDuration={defaultDuration}
      subscriptionType={subscriptionType.toLowerCase() as ProcessSubType | undefined}
      getCardOptions={setActiveCardForSend}
      setActiveDuration={setActiveDuration}
      activePlanInterval={activePlanInterval}
      activePlanTitle={activePlanTitle}
      isActive={String(activeCardForSend.title) === String(plan.title)}
    />
  ));

  const getActiveDuration = (duration: TPlanDurationPrices) => setActiveDuration(duration);

  const selectedPlan =
    subscriptionPlansNew.find(
      (el) => el.title === activeCardForSend.title && el.type === subscriptionType,
    ) || subscriptionPlansNew.find((el) => el.id === 1 && el.type === subscriptionType);

  const handleSubscriptionTypeChange = (newType: TSubscriptionFilter) => {
    setSubscriptionType(newType);
  };

  return (
    <div className='items-start'>
      {Number(width) > breakPoints.DESKTOP ? (
        <div>
          <div style={{ maxWidth: '100%' }}>
            <div className='flex flex-col justify-between items-center mb-9'>
              {withTitle && (
                <ProcessFormTitle
                  title={`Subscription plan (${
                    subscriptionType === API_FILTER ? 'API Integrations' : 'Device Plan'
                  })`}
                />
              )}
              <div className='bg-gray-200 rounded-md shadow-xl -mt-1'>
                <button
                  type='button'
                  className={`py-1 transition-colors duration-300 rounded-l-md w-20 text-center ${
                    subscriptionType === 'Device'
                      ? 'bg-accent text-white'
                      : 'hover:bg-gray-600 hover:text-white'
                  }`}
                  onClick={() => handleSubscriptionTypeChange('Device')}
                >
                  Device
                </button>
                <button
                  type='button'
                  className={`py-1 transition-colors duration-300 rounded-r-md w-20 text-center ${
                    subscriptionType === 'API'
                      ? 'bg-accent text-white'
                      : 'hover:bg-gray-600 hover:text-white'
                  }`}
                  onClick={() => handleSubscriptionTypeChange('API')}
                >
                  API
                </button>
              </div>
            </div>

            <FormControl>
              {subscriptionType === 'Device' && (
                <div className='flex items-start mb-1'>
                  <DurationTabs
                    durations={selectedPlan?.durations ?? []}
                    selectedPlan={selectedPlan}
                    subscriptionType={subscriptionType}
                    activeDuration={activeDuration}
                    activeCardForSend={activeCardForSend}
                    changeActiveDuration={getActiveDuration}
                    setActiveCardForSend={setActiveCardForSend}
                  />
                </div>
              )}
              <RadioGroup
                className={className}
                name='radio-buttons-group'
                defaultValue={subscriptionPlansNew[0].title}
                aria-labelledby='demo-radio-buttons-group-label'
                style={{ display: 'flex', gap: 32, flexDirection: 'row', flexWrap: 'nowrap' }}
              >
                {plans}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      ) : (
        <div>
          <div className='flex items-center gap-3 w-fit mb-2 -mt-12 mx-auto'>
            {withTitle && <ProcessFormTitle title={`Subscription plan (${subscriptionType})`} />}
            <div className='bg-gray-200 rounded-md shadow-xl w-fit flex h-fit mt-10 mb-4'>
              <button
                type='button'
                className={`py-1 transition-colors duration-300 rounded-l-md w-14 text-center text-xs ${
                  subscriptionType === 'Device'
                    ? 'bg-accent text-white'
                    : 'hover:bg-gray-600 hover:text-white'
                }`}
                onClick={() => handleSubscriptionTypeChange('Device')}
              >
                Device
              </button>
              <button
                type='button'
                className={`py-1 transition-colors duration-300 rounded-r-md w-14 text-center text-xs ${
                  subscriptionType === 'API'
                    ? 'bg-accent text-white'
                    : 'hover:bg-gray-600 hover:text-white'
                }`}
                onClick={() => handleSubscriptionTypeChange('API')}
              >
                API
              </button>
            </div>
          </div>

          {subscriptionType === 'Device' && (
            <div style={{ marginBottom: '20px' }}>
              <DurationTabs
                durations={selectedPlan?.durations ?? []}
                subscriptionType={subscriptionType}
                selectedPlan={selectedPlan}
                activeDuration={activeDuration}
                activeCardForSend={activeCardForSend}
                changeActiveDuration={getActiveDuration}
                setActiveCardForSend={setActiveCardForSend}
              />
            </div>
          )}
          <SlidePagination elements={plans} className={styles.container} />
        </div>
      )}
    </div>
  );
};

export default SubscriptionPlanBoxes;
