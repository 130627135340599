import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TrendingDownOutlined from '@mui/icons-material/TrendingDownOutlined';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import cn from 'classnames';

import { VoidCallback } from 'types';

export type OverViewCardProps = {
  title: string;
  value: number;
  icon: React.FC<SvgIconProps>;
  isFirst?: boolean;
  growth?: {
    value: number;
    time: string;
  };
  detail?: {
    positive?: string;
    negative?: string;
  };
  onClick?: VoidCallback;
  className?: string;
};

export const OverViewCard: FC<OverViewCardProps> = ({
  title,
  value,
  growth,
  detail,
  icon: Icon,
  isFirst,
  onClick,
  className,
}) => {
  return (
    <div
      className={cn(
        'bg-white dark:bg-dark-card-bg rounded-[14px] p-2 md:p-4 flex flex-col gap-3 md:gap-5 transition-all shadow-md md:shadow-none min-w-40',
        {
          'border-l-accent dark:border-l-dark-text border-l-2': isFirst,
          'cursor-pointer': !!onClick,
          className: !!className,
          'h-full md:w-full max-w-[300px]': !className,
        },
      )}
      onClick={onClick}
    >
      <div className='flex justify-between items-center'>
        <p className='font-semibold'>{title}</p>
        <div className='rounded-full bg-accent dark:bg-dark-text p-2 flex items-center scale-75 md:scale-100'>
          <Icon className='text-white' />
        </div>
      </div>
      <p className='font-bold text-[25px] md:text-[32px]'>{value}</p>
      {detail && (
        <div className='flex items-center gap-1'>
          {detail.positive && (
            <div className='flex items-center gap-1'>
              <div className='bg-green-600 h-2 w-2 rounded-full p-1' />
              <p>{detail.positive}</p>
            </div>
          )}
          {detail.negative && (
            <div className='flex items-center gap-1'>
              <div className='bg-red-600 h-2 w-2 rounded-full p-1' />
              <p>{detail.negative}</p>
            </div>
          )}
        </div>
      )}
      {growth && (
        <div className='flex items-center gap-2'>
          {typeof growth?.value === 'number' ? (
            <div
              className={cn('flex gap-1 items-center', {
                'text-green-500': growth?.value > 0,
                'text-red-500': growth?.value < 0,
              })}
            >
              {growth?.value < 0 ? <TrendingDownOutlined /> : <TrendingUpOutlinedIcon />}
              <p className='text-sm'>{growth.value}%</p>
            </div>
          ) : null}

          <p className='font-light text-[#5B5B5B] text-sm'>{growth.time}</p>
        </div>
      )}
    </div>
  );
};
