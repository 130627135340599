import { useState } from 'react';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SidebarModal from 'components/shared/SideBarModal';
import AddVenuesPage from './AddVenues';
import SectionsPage from './VenueSections';
import SeatMap from 'containers/seatingMap/SeatMap';
import Viewer3D from 'containers/seatingMap/Viewer3D';
import Tesseract from 'tesseract.js';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Seat } from './SeatMap';

const SeatMapping = () => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [seatPositions, setSeatPositions] = useState<Seat[]>([]);
  const [venues, setVenues] = useState<{ name: string; capacity: number; floorPlan?: string }[]>(
    [],
  );
  const [sections, setSections] = useState(['VIP', 'Regular', 'Balcony']);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [selectedSeat, setSelectedSeat] = useState<Seat | null>(null);

  const handleApplyAction = (selectedSeats: string[], action: string) => {
    console.log(`Action "${action}" applied to seats: ${selectedSeats}`);
  };

  const handleSectionsUpdate = (
    updatedSections: { id: string; name: string; capacity: number }[],
  ) => {
    setSections(updatedSections.map((section) => section.name));
    console.log('Updated Sections:', updatedSections);
  };

  const handleVenueSave = (venue: { name: string; capacity: number; floorPlan?: string }) => {
    setVenues((prevVenues) => [...prevVenues, venue]);
    console.log('Venue Saved:', venue);
  };

  const handleImageUpload = (image: string) => {
    setUploadedImage(image);
    detectSeats(image);
  };

  const detectSeats = async (imageSrc: string) => {
    try {
      const result = await Tesseract.recognize(imageSrc, 'eng', {
        logger: (m) => console.log(m),
      });

      const detectedSeats: Seat[] = result.data.words.map((word, index) => {
        const row = Math.floor(word.bbox.y0 / 100) + 1;
        const column = String.fromCharCode(65 + Math.floor(word.bbox.x0 / 100));

        return {
          x: column,
          y: row,
          occupied: false,
          section: 'VIP',
        };
      });

      setSeatPositions(detectedSeats);
    } catch (error) {
      console.error('Error detecting seats:', error);
    }
  };

  const toggleSeatStatus = (index: number) => {
    setSeatPositions((prevSeats) =>
      prevSeats.map((seat, i) => (i === index ? { ...seat, occupied: !seat.occupied } : seat)),
    );
  };

  const openSidebar = (content: JSX.Element) => {
    setModalContent(content);
    setShowModal(true);
  };

  const closeSidebar = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const handleSeatClick = (seat: Seat) => {
    setSelectedSeat(seat);
  };

  return (
    <div>
      <div className='mb-10 flex items-center justify-between'>
        <p className='text-[24px] font-semibold'>Seat Mapping</p>
        <PrimaryButton
          onClick={() =>
            openSidebar(
              <AddVenuesPage onVenueSave={handleVenueSave} handleImageUpload={handleImageUpload} />,
            )
          }
        >
          Add Venue
        </PrimaryButton>
      </div>

      <div className='flex'>
        <div className='w-full flex flex-col p-4'>
          <div>
            <p className='text-[18px] font-semibold mb-4'>Venues</p>
            <div className='flex flex-wrap gap-6'>
              {venues.map((venue, index) => (
                <div
                  key={index}
                  className='w-[350px] p-5 gap-y-6 rounded-[12px] bg-white shadow-lg'
                >
                  <p className='text-[18px] mb-3 min-w-fit font-semibold text-gray-700'>
                    {venue.name}
                  </p>
                  <p className='text-[15px] font-light mb-2'>Capacity: {venue.capacity}</p>
                  <button
                    onClick={() =>
                      openSidebar(<SectionsPage onSectionsUpdate={handleSectionsUpdate} />)
                    }
                    type='button'
                    className='flex items-center py-3 gap-4 px-6 rounded-md bg-light-blue-bg dark:border-white text-accent dark:bg-btn-dark dark:text-white group ml-auto'
                  >
                    <PencilSquareIcon className='w-[16px]' />
                    Manage Sections
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className='w-full mt-4'>
            {uploadedImage && (
              <div>
                <Viewer3D
                  imageSrc={uploadedImage}
                  seatPositions={seatPositions}
                  toggleSeatStatus={toggleSeatStatus}
                />
                <SeatMap
                  seatPositions={seatPositions}
                  toggleSeatStatus={toggleSeatStatus}
                  onSeatClick={handleSeatClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <SidebarModal show={showModal} setShow={closeSidebar} component={modalContent} />
      )}
    </div>
  );
};

export default SeatMapping;
