import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { dashboardApi } from 'api';

import {
  TAnalyticsDateParams,
  THeadCountIntervalParams,
  THeadCountIntervalResponse,
  TSumHeadCountParams,
} from './type';

export const getCurrentHeadCount = createAsyncThunk(
  'dashboardSlice/getCurrentHeadCount',
  async () => {
    try {
      const response = await dashboardApi.getCurrentHeadCountRequest();
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
    }
  },
);

export const getSumHeadCount = createAsyncThunk(
  'dashboard/getSumHeadCount',
  async (params: TSumHeadCountParams) => {
    try {
      const response = await dashboardApi.getSumHeadCountRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const getHeadCountAtIntervals = createAsyncThunk(
  'dashboard/getHeadCountAtIntervals',
  async (params: THeadCountIntervalParams) => {
    try {
      const response = await dashboardApi.getHeadCountAtIntervalsRequest(params);
      return response.data as THeadCountIntervalResponse;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const getSeatingDensity = createAsyncThunk(
  'dashboard/getSeatingDensity',
  async (event_id: number) => {
    try {
      const response = await dashboardApi.getSeatingDensityRequest(event_id);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);

export const getAnalyticsByDateTime = createAsyncThunk(
  'dashboard/getAnalyticsByDateTime',
  async (params: TAnalyticsDateParams) => {
    try {
      const response = await dashboardApi.getAnalyticsByDateTimeRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
    }
  },
);
