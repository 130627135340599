// Import necessary types and dependencies
import { AxiosResponse } from 'axios';
import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import { TChatbotCreateParams, TChatbotCreateResponse, TChatbotQueryParams, TChatbotResponse } from './types';


// Define the endpoints for the chatbot feature
const { getChatbotMessages, createChatbotMessage } = endpoints.ChatbotService;

// GET /activities-api/he/chatbot/
// Fetch chatbot messages with optional limit and offset
export const getChatbotMessagesRequest = (
  params: TChatbotQueryParams = { limit: 10, offset: 0 },
): Promise<AxiosResponse<TChatbotResponse>> =>
  client.get(getChatbotMessages(), {
    params,
  });

// POST /activities-api/he/chatbot/
// Send a query to the chatbot and receive a response
export const createChatbotMessageRequest = (
  data: TChatbotCreateParams,
): Promise<AxiosResponse<TChatbotCreateResponse>> =>
  client.post(createChatbotMessage(), null, {
    params: data,
  });
