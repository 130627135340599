import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { FormControl, InputAdornment, OutlinedInput, Radio } from '@mui/material';
import Dropdown from 'components/shared/Dropdown';
import dayjs from 'dayjs';
import React, { useState } from 'react';

export interface ICustomCalendarData {
  num?: number;
  interval?: string;
  repeatDays: { name: string; value: string }[];
  repeatEnd?: string;
  date: string;
  occurences?: number;
}

interface IEventCustomCalendarProps {
  close: () => void;
  startDate: string | Date | undefined;
  applyCustomRepeat: (data: any) => void;
  customCalendarData: ICustomCalendarData;
  setNum: (num: number) => void;
}

export const EventCustomCalender: React.FC<IEventCustomCalendarProps> = ({
  close,
  startDate,
  applyCustomRepeat,
  customCalendarData,
  setNum,
}) => {
  const {
    num: _num,
    interval: _interval,
    repeatDays: _repeatDays,
    repeatEnd: _repeatEnd,
    date: _date,
    occurences: _occurences,
  } = customCalendarData;

  const [num, setLocalNum] = useState(customCalendarData.num || 1);

  const [interval, setInterval] = useState(_interval || 'Weekly');

  const [repeatDays, setRepeatDays] = useState<{ name: string; value: string }[]>(
    _repeatDays || [],
  );

  const [repeatEnd, setRepeatEnd] = useState(_repeatEnd || '');

  const [date, setDate] = useState(_date || '');

  const [occurences, setOccurrences] = useState(_occurences || 2);

  const daysOfWeek = [
    { name: 'S', value: 'sun' },
    { name: 'M', value: 'mon' },
    { name: 'T', value: 'tue' },
    { name: 'W', value: 'wed' },
    { name: 'T', value: 'thu' },
    { name: 'F', value: 'fri' },
    { name: 'S', value: 'sat' },
  ];

  const repeatDropdownOptions = ['Day', 'Week', 'Month', 'Year'].map((label) => ({
    label,
    value: {
      Day: 'Daily',
      Week: 'Weekly',
      Month: 'Monthly',
      Year: 'Yearly',
    }[label] as string,
  }));

  const handleNumChange = (newNum: number) => {
    setLocalNum(newNum);
    setNum(newNum);
  };

  const handleNumIncrement = () =>
    setLocalNum((prevState) => (prevState !== 1000 ? prevState + 1 : prevState));

  const handleNumDecrement = () =>
    setLocalNum((prevState) => (prevState !== 1 ? prevState - 1 : prevState));

  const intervalMap: { [key: string]: string } = {
    Day: 'Daily',
    Week: 'Weekly',
    Month: 'Monthly',
    Year: 'Yearly',
  };

  const handleDropdownChange = (e: any) => {
    const value = e.target.value;

    const mappedValue = intervalMap[value];

    if (mappedValue) {
      setInterval(mappedValue);
    }
  };

  const handleRepeatDaysClick = (data: { name: string; value: string }) => {
    const res = repeatDays.find((val) => val.value === data.value);

    if (res) {
      setRepeatDays((prevState) => prevState.filter((val) => val.value !== res.value));
      return;
    }

    setRepeatDays((prevState) => prevState.concat(data));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => setRepeatEnd(e.target.name);

  const handleOccurrencesIncrement = () =>
    setOccurrences((prevState) => (prevState !== 1000 ? prevState + 1 : prevState));

  const handleOccurrencesDecrement = () =>
    setOccurrences((prevState) => (prevState !== 1 ? prevState - 1 : prevState));

  const repeatedDaysSelected = (val: string) => {
    const res = repeatDays.find((repeatDay) => repeatDay.value === val);

    if (res) return 'bg-accent';

    return 'bg-gray-200';
  };

  const handleDone = () => {
    const customCalenderData = {
      num,
      interval,
      repeatDays,
      repeatEnd,
      date,
      occurences,
    };

    let endDate;
    const dateInterval = interval.toLowerCase();

    if (repeatEnd === 'on' && date) {
      endDate = date;
    } else if (repeatEnd === 'after' && startDate) {
      const dateNum = num * occurences;
      endDate = dayjs(startDate)
        .add(dateNum, dateInterval as any)
        .format('YYYY-MM-DD');
    } else if (startDate && !repeatEnd) {
      endDate = dayjs(startDate)
        .add(num, dateInterval as any)
        .format('YYYY-MM-DD');
    }

    const eventDays = repeatDays.map((val) => val.value);
    applyCustomRepeat({ endDate, eventDays, customCalenderData, repeat_every: num, interval });
  };

  return (
    <div>
      <h1 className='text-xl text-gray-500'>Custom Repeat</h1>

      <div className='mt-5'>
        <div>
          <h3 className='text-gray-600'>Repeat Every</h3>

          <div className='mt-2 flex gap-4'>
            <div className='flex gap-2'>
              <input
                type='number'
                min='1'
                max='1000'
                className='mt-2 w-16 h-14 py-1 text-center border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-gray-800'
                value={num}
              />

              <div className='flex flex-col gap-4 self-center  text-gray-600'>
                <ChevronUpIcon
                  className='h-5 cursor-pointer '
                  onClick={handleNumIncrement}
                  aria-label='Increase repeat interval'
                />

                <ChevronDownIcon
                  className='h-5 cursor-pointer text-gray-600'
                  onClick={handleNumDecrement}
                  aria-label='Decrease repeat interval'
                />
              </div>
            </div>

            <Dropdown
              value={interval}
              onChange={handleDropdownChange}
              items={repeatDropdownOptions}
              width={200}
            />
          </div>
        </div>

        <div className='mt-2'>
          <h3 className='text-gray-600'>Repeat on</h3>

          <div className='mt-2 flex gap-2'>
            {daysOfWeek.map((val, ind) => (
              <p
                key={ind}
                onClick={() => handleRepeatDaysClick(val)}
                className={`w-10 h-10 flex items-center justify-center ${repeatedDaysSelected(
                  val.value,
                )} font-bold rounded-full cursor-pointer`}
              >
                {val.name}
              </p>
            ))}
          </div>
        </div>

        <div className='mt-4'>
          <h3 className='text-gray-600'>Ends</h3>

          <div className='flex flex-col'>
            <div className='flex gap-14'>
              <div className='flex items-center'>
                <Radio name='on' checked={repeatEnd === 'on'} onChange={handleRadioChange} />
                <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                  On
                </label>
              </div>

              <div className=''>
                <input
                  type='date'
                  value={date}
                  onChange={(e) => handleNumChange(Number(e.target.value))}
                  required
                  disabled={repeatEnd !== 'on'}
                  className='mt-1 px-9 py-4 block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300'
                />
              </div>
            </div>

            <div className='flex gap-10'>
              <div className='flex items-center'>
                <Radio name='after' checked={repeatEnd === 'after'} onChange={handleRadioChange} />
                <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                  After
                </label>
              </div>

              <div className='flex'>
                <FormControl sx={{ m: 1, width: '18ch' }} variant='outlined'>
                  <OutlinedInput
                    id='outlined-adornment-weight'
                    endAdornment={<InputAdornment position='end'>Occurrences</InputAdornment>}
                    aria-describedby='outlined-weight-helper-text'
                    value={occurences}
                    disabled={repeatEnd !== 'after'}
                  />
                </FormControl>

                <div className='flex flex-col gap-4 self-center'>
                  <ChevronUpIcon
                    className={`h-5 cursor-pointer ${
                      repeatEnd !== 'after' ? 'pointer-events-none' : ''
                    }`}
                    onClick={handleOccurrencesIncrement}
                  />

                  <ChevronDownIcon
                    className={`h-5 cursor-pointer ${
                      repeatEnd !== 'after' ? 'pointer-events-none' : ''
                    }`}
                    onClick={handleOccurrencesDecrement}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 flex gap-8 float-end'>
        <p className='cursor-pointer' onClick={close}>
          Cancel
        </p>

        <p className='text-accent cursor-pointer' onClick={handleDone}>
          Done
        </p>
      </div>
    </div>
  );
};
