import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { getChatbotMessagesRequest, createChatbotMessageRequest } from '../../api/chatbot/';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import ModalImage from 'react-modal-image';
import EmojiPicker from 'emoji-picker-react';

interface MessagingAppProps {
  selectedChat: {
    id: number;
    name: string;
    profilePicture: string;
  } | null;
  onClose: () => void;
}

export default function MessagingApp({ selectedChat, onClose }: MessagingAppProps) {
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  // Fetch initial messages
  useEffect(() => {
    if (!selectedChat) return;
    const fetchMessages = async () => {
      try {
        const response = await getChatbotMessagesRequest();
        const sortedMessages = response?.data?.data?.sort(
          (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        );
        setMessages(sortedMessages || []);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();
  }, [selectedChat]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Ensure query-response pairs are always grouped
  const organizedMessages = messages.reduce((acc: any[], message: any, index: number) => {
    if (message.type === 'query') {
      acc.push(message); // Add the query first
      const correspondingResponse = messages[index + 1];
      if (correspondingResponse?.type === 'response') {
        acc.push(correspondingResponse); // Add its response immediately after
      }
    } else if (
      message.type === 'response' &&
      acc.findIndex((msg) => msg.id === message.id) === -1
    ) {
      acc.push(message); // Add standalone response if not yet included
    }
    return acc;
  }, []);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setLoading(true);
    try {
      // Send message via API
      const response = await createChatbotMessageRequest({
        query: newMessage,
      });

      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      // Update messages state
      setMessages((prev) => [
        ...prev,
        { content: newMessage, type: 'query', timestamp },
        { content: response.data.content, type: 'response', timestamp }, // Assuming API returns a reply
      ]);
      setNewMessage('');
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    if (emoji) {
      setNewMessage((prev) => prev + emoji.emoji);
    }
    setShowEmojiPicker(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleImageClick = (imageUrl: string) => {
    setLightboxImage(imageUrl);
    setLightboxOpen(true);
  };

  if (!selectedChat) return null;

  return (
    <div
      className={`fixed bottom-0 right-4 w-[450px] ${
        isMaximized ? 'h-screen' : 'h-[75vh]'
      } bg-accent text-white dark:bg-black shadow-lg flex flex-col`}
      style={{
        borderRadius: '12px',
        overflow: 'hidden',
        zIndex: 1000,
      }}
    >
      {/* Chat Header */}
      <div className='flex items-center justify-between p-4 border-b gap-4'>
        <div className='flex items-center gap-4'>
          {/* Placeholder for Profile Picture */}
          <svg
            className='w-12 h-12'
            version='1.0'
            xmlns='http://www.w3.org/2000/svg'
            width='256.000000pt'
            height='256.000000pt'
            viewBox='0 0 256.000000 256.000000'
            preserveAspectRatio='xMidYMid meet'
          >
            <g
              transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)'
              fill='#FFF'
              stroke='none'
            >
              <path
                d='M1560 2124 c-31 -138 -45 -155 -156 -190 -47 -15 -82 -30 -77 -34 4
-4 35 -15 68 -25 123 -35 131 -45 171 -215 14 -61 28 -110 30 -108 2 2 14 55
28 116 34 159 45 172 179 207 37 10 67 22 67 26 0 5 -5 9 -11 9 -6 0 -43 10
-81 21 -107 33 -124 53 -156 187 -14 61 -29 112 -33 112 -3 0 -16 -48 -29
-106z'
              />
              <path
                d='M2086 1880 c-6 -36 -11 -43 -49 -59 l-42 -18 32 -7 c39 -9 48 -19 63
-76 7 -25 14 -40 16 -35 2 6 8 29 14 52 9 36 16 43 48 54 l37 12 -26 11 c-42
17 -58 36 -65 72 -8 46 -20 43 -28 -6z'
              />
              <path
                d='M1896 1601 c-10 -43 -23 -86 -29 -95 -7 -9 -41 -27 -76 -40 -70 -25
-70 -26 7 -51 64 -20 76 -37 101 -140 20 -87 23 -85 45 20 17 82 39 106 115
126 28 7 51 16 51 19 0 3 -23 12 -51 19 -76 20 -97 43 -120 136 -12 44 -22 81
-23 83 -2 1 -11 -33 -20 -77z'
              />
              <path
                d='M746 1368 c-17 -16 -76 -145 -180 -396 -86 -206 -156 -382 -156 -392
0 -45 42 -80 92 -80 50 1 72 25 109 122 l37 93 144 3 c109 2 148 -1 155 -10 6
-7 22 -48 38 -90 33 -94 52 -113 112 -113 37 0 49 5 70 29 15 18 23 37 20 50
-2 12 -75 191 -162 399 -137 328 -162 380 -186 393 -39 20 -64 18 -93 -8z m92
-366 c51 -135 56 -122 -37 -122 -58 0 -81 4 -81 13 0 24 72 197 80 192 5 -3
22 -40 38 -83z'
              />
              <path
                d='M1408 1379 c-56 -22 -59 -42 -56 -449 l3 -370 27 -27 c37 -37 96 -38
132 -4 l26 24 0 392 0 392 -23 21 c-28 26 -72 35 -109 21z'
              />
            </g>
          </svg>
          <div>
            <p className='font-bold'>{selectedChat.name}</p>
            <p className='flex items-center space-x-2 text-gray-900'>
              <span className='h-3 w-3 rounded-full bg-green-500 animate-pulse'></span>
              <span className='text-gray-50'>Active now</span>
            </p>
          </div>
        </div>
        <div className='flex items-center text-white gap-4'>
          <CloseIcon onClick={onClose} className='cursor-pointer text-white' />
        </div>
      </div>

      {/* Messages */}
      <div className='flex-1 overflow-y-auto bg-gray-50 p-4'>
        {messages?.length ? (
          messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.type === 'query' ? 'justify-end' : 'justify-start'
              } items-center mb-4`}
              ref={index === messages.length - 1 ? lastMessageRef : null} // Assign ref to the last message
            >
              <div
                className={`flex flex-row gap-2 items-start mb-4 ${
                  message.type === 'response' ? 'justify-start' : 'justify-end'
                }`}
              >
                {/* Display image for each message */}
                {message.type === 'response' && (
                  <div className={`bg-accent rounded-full text-white dark:bg-black `}>
                    <svg
                      className='w-8 h-8'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 256.000000 256.000000'
                      preserveAspectRatio='xMidYMid meet'
                    >
                      <g
                        transform='translate(0.000000,256.000000) scale(0.100000,-0.100000)'
                        fill='#FFF'
                        stroke='none'
                      >
                        <path d='M1560 2124 c-31 -138 -45 -155 -156 -190 -47 -15 -82 -30 -77 -34 4 -4 35 -15 68 -25 123 -35 131 -45 171 -215 14 -61 28 -110 30 -108 2 2 14 55 28 116 34 159 45 172 179 207 37 10 67 22 67 26 0 5 -5 9 -11 9 -6 0 -43 10 -81 21 -107 33 -124 53 -156 187 -14 61 -29 112 -33 112 -3 0 -16 -48 -29 -106z' />
                        <path d='M2086 1880 c-6 -36 -11 -43 -49 -59 l-42 -18 32 -7 c39 -9 48 -19 63 -76 7 -25 14 -40 16 -35 2 6 8 29 14 52 9 36 16 43 48 54 l37 12 -26 11 c-42 17 -58 36 -65 72 -8 46 -20 43 -28 -6z' />
                        <path d='M1896 1601 c-10 -43 -23 -86 -29 -95 -7 -9 -41 -27 -76 -40 -70 -25 -70 -26 7 -51 64 -20 76 -37 101 -140 20 -87 23 -85 45 20 17 82 39 106 115 126 28 7 51 16 51 19 0 3 -23 12 -51 19 -76 20 -97 43 -120 136 -12 44 -22 81 -23 83 -2 1 -11 -33 -20 -77z' />
                        <path d='M746 1368 c-17 -16 -76 -145 -180 -396 -86 -206 -156 -382 -156 -392 0 -45 42 -80 92 -80 50 1 72 25 109 122 l37 93 144 3 c109 2 148 -1 155 -10 6 -7 22 -48 38 -90 33 -94 52 -113 112 -113 37 0 49 5 70 29 15 18 23 37 20 50 -2 12 -75 191 -162 399 -137 328 -162 380 -186 393 -39 20 -64 18 -93 -8z m92 -366 c51 -135 56 -122 -37 -122 -58 0 -81 4 -81 13 0 24 72 197 80 192 5 -3 22 -40 38 -83z' />
                        <path d='M1408 1379 c-56 -22 -59 -42 -56 -449 l3 -370 27 -27 c37 -37 96 -38 132 -4 l26 24 0 392 0 392 -23 21 c-28 26 -72 35 -109 21z' />
                      </g>
                    </svg>
                  </div>
                )}

                {/* Message Bubble */}
                <div
                  className={`px-4 py-2 rounded-lg max-w-xs ${
                    message.type === 'response'
                      ? 'bg-accent text-white dark:bg-black'
                      : 'bg-gray-200 text-gray-900'
                  }`}
                >
                  {message.content}

                  {message.type === 'response' && (
                    <div className='flex flex-col'>
                      <div>
                        {message.content.includes('http') &&
                        (message.content.includes('.jpg') || message.content.includes('.png')) ? (
                          <img
                            src={message.content}
                            alt='image'
                            className='max-w-[200px] rounded-md cursor-pointer'
                            onClick={() => handleImageClick(message.content)}
                          />
                        ) : message.content.includes('http') &&
                          (message.content.includes('.mp4') || message.content.includes('.mov')) ? (
                          <video
                            src={message.content}
                            controls
                            className='max-w-[200px] rounded-md cursor-pointer'
                          />
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>
                  )}
                  <p className='text-xs text-gray-400 mt-1'>{message.timestamp}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* Input */}

      <div className='flex flex-col gap-3 p-4 bg-white'>
        <TextField
          rows={3}
          variant='outlined'
          onChange={(e) => setNewMessage(e.target.value)}
          value={newMessage}
          placeholder='Write a message...'
          fullWidth
          onKeyDown={handleKeyDown}
        />

        <div className='flex flex-row justify-between gap-3'>
          <button
            className={`px-3 py-0.5 text-white text-sm rounded-[15px] ${
              newMessage.trim() ? 'bg-accent dark:bg-black' : 'bg-gray-300'
            }`}
            disabled={!newMessage.trim() || loading}
            onClick={handleSendMessage}
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
          <div className='flex items-center'>
            <div>
              <button onClick={() => setShowEmojiPicker(!showEmojiPicker)} className='ml-2'>
                😊
              </button>
            </div>
          </div>
        </div>
        {showEmojiPicker && (
          <EmojiPicker reactionsDefaultOpen={true} onEmojiClick={handleEmojiSelect} />
        )}
      </div>

      {/* Lightbox for image/video */}
      {lightboxOpen && (
        <ModalImage
          large={lightboxImage}
          small={lightboxImage}
          //onClose={() => setLightboxOpen(false)}
        />
      )}
    </div>
  );
}
