import { ongoingEvent } from 'assets/images';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { IconButton } from '@mui/material'; 
import { upcomingEvent } from 'assets/images';
import { useAppDispatch } from 'hooks';
import { getSignedMediaUrl } from 'api/media';
import { getDeviceByIdRequest } from 'api/devices';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDevicesHistoryByID } from 'store/thunks';

type OngoingEventProps = {
  title: string;
  eventID:number;
  relativeTime: string;
};

const limit = 1;
const offset = 0;
const HIDE = true;
export const OngoingEventCard = ({ title, eventID, relativeTime }: OngoingEventProps) => {
  const[ eventImg, setEventImg] = useState('')
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (eventID) {
      getLatestEventImg(eventID);
    }
  }, []);
  const getLatestEventImg = 
    async (Id: number )=> {
      
      const options = {
      limit,
      offset,
      id: Id,
    };
    try {
      const response = await dispatch(getDevicesHistoryByID(options)).unwrap();
      if (Array.isArray(response.data) && response.data.length > 0) {
        const { image_url_id } = response.data[0];

        if (!image_url_id) {
          throw new Error('No image found in devices history.');
        }
      
        const mediaResponse = await getSignedMediaUrl({
          folder: 'analytics',
          media_url_id: image_url_id as string,
          thumbnail:true
        });
        if(mediaResponse.data.url){

        setEventImg(mediaResponse.data.url)
        }
      }}
      catch(err){console.log('Failed to fetch media URL')}
    }
  return (
    <div className='bg-white md:bg-[#F0F7FF] dark:bg-dark-card-bg-old  max-w-sm w-[600px] min-w-[400px] p-4 rounded-[14px] flex items-center gap-12 transition-all shadow-md md:shadow-none'>
      <div className='flex items-center gap-3'>
        <img
          src={eventImg || ongoingEvent}
          alt='upcoming event'
          className='h-[50px] md:h-[60px] rounded-full'
        />
        <div className='flex flex-col gap-2'>
          <p className='font-semibold text-[14px] dark:text-white'>{title}</p>
          <p className='text-[13px] text-[#6B6B6B] dark:text-white'>Started {relativeTime}</p>
        </div>
      </div>
      {HIDE ? null : <p className='text-accent font-semibold cursor-pointer'>View</p>}
    </div>
  );
};
