import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Circle, Group } from 'react-konva';
import { ClipLoader } from 'react-spinners';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs';

export type Point = { x: number; y: number };

export type PolygonData = {
  points: Point[];
  peopleCount: number;
};

type PolygonDrawerProps = {
  src: string;
  type: 'image' | 'video';
  crossOrigin?: string;
  currentPoints: Point[];
  setCurrentPoints: React.Dispatch<React.SetStateAction<Point[]>>;
  polygons: PolygonData[];
  setPolygons: React.Dispatch<React.SetStateAction<PolygonData[]>>;
  onPolygonComplete?: (polygons: PolygonData[]) => void;
};

export type PolygonDrawerRef = {
  completePolygon: () => void;
};


const PolygonDrawer = forwardRef<PolygonDrawerRef, PolygonDrawerProps>(
  (
    {
      src,
      type,
      currentPoints,
      setCurrentPoints,
      polygons,
      setPolygons,
      onPolygonComplete,
    },
    ref
  ) => {
    const [media, setMedia] = useState<HTMLImageElement | HTMLVideoElement | null>(null);
    const [loading, setLoading] = useState(false);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);
        const [selectedPolygonIdx, setSelectedPolygonIdx] = useState<number | null>(null); // Index du polygone sélectionné

    useImperativeHandle(ref, () => ({
      completePolygon,
    }));

    useEffect(() => {
      const loadMedia = async () => {
        if (type === 'image') {
          const img = new Image();
          img.crossOrigin = 'anonymous';
          img.src = src;
          img.onload = () => {
            setMedia(img);
            updateStageSize();
          };
        } else if (type === 'video') {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous';
          video.src = src;
          video.loop = true;
          video.muted = true;
          video.play();
          video.onloadedmetadata = () => {
            setMedia(video);
            updateStageSize();
          };
        }
      };

      loadMedia();
    }, [src, type]);

    const updateStageSize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setStageSize({ width, height });
      }
    };

    const addPoint = (e: any) => {
      if (loading) return;
      const stage = e.target.getStage();
      const pointer = stage.getPointerPosition();
      if (pointer) {
        setCurrentPoints((prev) => [...prev, pointer]);
      }
    };

    const completePolygon = async () => {
      if (currentPoints.length < 3) return;

      const newPolygon: PolygonData = {
        points: currentPoints.map(({ x, y }) => ({ x: Math.round(x), y: Math.round(y) })),
        peopleCount: 0,
      };

      setLoading(true);
      await analyzePolygon(newPolygon);
      setLoading(false);

      setPolygons((prev) => {
        const updated = [...prev, newPolygon];
        onPolygonComplete?.(updated);
        return updated;
      });
      setCurrentPoints([]);
    };

    const analyzePolygon = async (polygon: PolygonData) => {
      if (!media) return;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx || !containerRef.current) return;

      const { width, height } = containerRef.current.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;

      if (type === 'image') {
        ctx.drawImage(media as HTMLImageElement, 0, 0, width, height);
      } else {
        ctx.drawImage(media as HTMLVideoElement, 0, 0, width, height);
      }

      const model = await cocoSsd.load();
      const predictions = await model.detect(canvas);

      const isInsidePolygon = (x: number, y: number) => {
        const { points } = polygon;
        let inside = false;
        for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
          const xi = points[i].x, yi = points[i].y;
          const xj = points[j].x, yj = points[j].y;

          const intersect =
            yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
          if (intersect) inside = !inside;
        }
        return inside;
      };

      polygon.peopleCount = predictions.filter((p) => {
        if (p.class !== 'person') return false;
        const [x, y, w, h] = p.bbox;
        const centerX = x + w / 2;
        const centerY = y + h / 2;
        return isInsidePolygon(centerX, centerY);
      }).length;
    };


    const handlePointDrag = (e: any, polygonIdx: number, pointIdx: number) => {
      const pos = e.target.position();
      setPolygons((prevPolygons) => {
        const updated = [...prevPolygons];
        updated[polygonIdx].points[pointIdx] = pos;
        return updated;
      });
    };

    const handlePolygonDrag = (e: any, idx: number) => {
      const { x: dx, y: dy } = e.target.position(); // Get drag offset
    
      // Update polygon points with drag offset
      setPolygons((prevPolygons) => {
        const updated = [...prevPolygons];
        updated[idx].points = updated[idx].points.map((point) => ({
          x: point.x + dx,
          y: point.y + dy,
        }));
        return updated;
      });
    
      e.target.position({ x: 0, y: 0 }); // Reset the drag position
    };

    const selectPolygon = (idx: number) => {
      setSelectedPolygonIdx(idx);
    };

    
    return (
      <div ref={containerRef} className="relative w-full h-[550px]">
        <Stage
          width={stageSize.width}
          height={stageSize.height}
          onClick={addPoint}
        >
          <Layer>
            {media && (
              <KonvaImage
                image={media as any}
                width={stageSize.width}
                height={stageSize.height}
              />
            )}
            {polygons.map((polygon, polygonIdx) => (
              <Group
                key={`polygon-${polygonIdx}`}
                draggable
                onDragMove={(e) => handlePolygonDrag(e, polygonIdx)} // Draggable group
                onClick={() => selectPolygon(polygonIdx)}
              >
                <Line
                  points={polygon.points.flatMap((p) => [p.x, p.y])}
                  closed
                  fill="rgba(0, 255, 0, 0.3)"
                  stroke="green"
                  strokeWidth={2}
                />
                {polygon.points.map((point, pointIdx) => (
                  <Circle
                    key={`point-${polygonIdx}-${pointIdx}`}
                    x={point.x}
                    y={point.y}
                    radius={6}
                    fill="red"
                    draggable
                    onDragMove={(e) => handlePointDrag(e, polygonIdx, pointIdx)} // Points remain draggable
                  />
                ))}
              </Group>
            ))}
            <Line
              points={currentPoints.flatMap((p) => [p.x, p.y])}
              closed
              fill="rgba(0, 0, 255, 0.3)"
              stroke="blue"
              strokeWidth={2}
            />
            {currentPoints.map((point, idx) => (
              <Circle
                key={`current-point-${idx}`}
                x={point.x}
                y={point.y}
                radius={6}
                fill="red"
                draggable
                onDragMove={(e) => {
                  const pos = e.target.position();
                  setCurrentPoints((prev) =>
                    prev.map((p, i) => (i === idx ? pos : p))
                  );
                }}
              />
            ))}
          </Layer>
        </Stage>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <ClipLoader color="#36d7b7" size={50} />
          </div>
        )}
        <div>
          {polygons.map((polygon, idx) => (
            <div key={idx} className="my-2">
              Polygon {idx + 1}: {polygon.peopleCount} people
            </div>
          ))}
        </div>
      </div>
    );
  }
);


export default PolygonDrawer;
