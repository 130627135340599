import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { BarGraph } from 'components/analytics/BarGraph';
import { TBarGraphData } from 'components/analytics/types';
import { getDateInTimeZone } from 'helpers/utils';
import { getHeadCountAtIntervals } from 'store/thunks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types';
import { useDarkMode } from 'hooks';
import { eventsSelector } from 'store/slices/eventsSlice/selectors';

const emptyStateIntervalData = {
  labels: [
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
    '9:00',
    '9:05',
    '10:15',
    '9:20',
    '9:25',
  ],
  datasets: [
    {
      label: '',
      data: [1, 2, 3, 4, 5, 6, 7, 7, 7, 8, 5, 4, 3, 2, 4],
      borderColor: '#0082dc',
      backgroundColor: Colors.LIGHT_GRAYISH_SILVER,
      fill: false,
      tension: 0.3,
      borderDash: [1],
      showLine: true,
      dash: 3,
    },
  ],
};

export default function PercentageOccupancyGraph() {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);
  const { pastEvents, currentEvents } = useAppSelector(eventsSelector);
  const attendanceIntervalRef = useRef<NodeJS.Timeout>();
  const [headCountIntervalData, setHeadCountIntervalData] = useState<TBarGraphData>();
  const { darkMode } = useDarkMode();

  const sittingOptions = useMemo(
    () => ({
      responsive: true,
      lineWidth: 0,
      maintainAspectRatio: false,
      layout: {
        padding: { top: 20 },
      },
      plugins: {
        legend: { display: false },
        borderJoinStyle: 'round',

        title: {
          display: false,
          text: 'Sitting Occupancy',
        },
        datalabels: {
          display: true,
          color: 'gray',
          anchor: 'end',
          align: 'end',
          font: {
            size: 10,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 10,
              color: darkMode ? '#ffffff' : '#000000',
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            color: darkMode ? '#ffffff' : '#000000',
          },
          title: {
            display: true,
            align: 'center',
            text: 'Occupancy %',
            color: darkMode ? '#ffffff' : '#000000',
          },
        },
      },
    }),
    [darkMode],
  );

  const getHeadCounts = useCallback(async () => {
    const selectedEvent =
      currentEvents?.data?.[0] || pastEvents?.data?.[pastEvents?.data.length - 1];

    const interval = selectedEvent?.trigger_interval;
    dispatch(getHeadCountAtIntervals({ interval }))
      .unwrap()
      .then((response) => {
        const labels = response?.data.map((intervalData) => {
          const now = new Date();
          const times = intervalData?.time?.split?.(':') ?? [];
          const hours = Number(times[0] ?? '0');
          const mins = Number(times[1] ?? '0');
          now.setHours(hours, mins, 0);

          return dayjs(getDateInTimeZone(now)).format('h:mma');
        }); // x-axis
        const data = response?.data.map((intervalData) => intervalData.count); // y-axis
        const graphData = {
          labels,
          datasets: [
            {
              label: '',
              data,
              borderColor: '#0082dc',
              backgroundColor: '#4472c4',
              fill: true,
              tension: 0.3,
            },
          ],
        };
        setHeadCountIntervalData(graphData);
      })
      .catch((e) => {});
  }, [dispatch, currentEvents, pastEvents]);

  useEffect(() => {
    getHeadCounts();
  }, []);

  useEffect(() => {
    // Get headcounts every 5 mins
    attendanceIntervalRef.current = setInterval(() => {
      getHeadCounts();
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearInterval(attendanceIntervalRef.current);
    };
  }, [getHeadCounts, userData?.member_info]);

  return (
    <div>
      <h4 className='mb-0 md:mb-3 text-right text-sm text-gray-600 dark:text-gray-400 pr-2'>
        {currentEvents?.data?.length > 0
          ? currentEvents.data[0]?.event_title
          : pastEvents?.data?.length > 0
          ? pastEvents.data[pastEvents.data.length - 1]?.event_title
          : ''}
      </h4>

      <BarGraph
        data={headCountIntervalData}
        emptyStateData={emptyStateIntervalData}
        options={sittingOptions}
      />
    </div>
  );
}
