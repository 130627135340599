import {
  PhotoIcon,
  CalendarIcon,
  PlayCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { CircularProgress, IconButton, Popover } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import FormInput from 'components/inputs/FormInput';
import FeedCard from 'components/Cards/FeedCard';
import { useAppDispatch, useAppSelector } from 'hooks';
import { askAIAgent, createPost, getActivePosts, getCurrentUser, getNews } from 'store/thunks';
import {
  creatingPostsSelector,
  newsSelector,
  postsSelector,
} from 'store/slices/activitiesSlices/selector';
import { getRelativeTime, handleImageUpload } from 'helpers/utils';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { TPostMetadata } from 'store/slices/activitiesSlices/types';
import { TChangeInputEvent, VoidCallback } from 'types';
import UserAvatar from 'components/shared/UserAvatar';
import { LoadingDots } from 'components/shared/LoadingDots';

const HIDDEN = true;

type TPostSubmitOption = {
  onClick: () => void;
  text: string;
  disabled?: boolean;
};

type TNewsCardProps = {
  title: string;
  description: string;
  time: string;
};

function NewsCard({ title, description, time }: TNewsCardProps) {
  return (
    <article className='flex flex-col gap-6 shadow-sm bg-white dark:bg-dark-card-bg p-2 md:p-4'>
      <div className='flex flex-col gap-2'>
        <h2 className='dark:text-white line-clamp-2'>{title}</h2>
        <p className='dark:text-white line-clamp-5'>{description}</p>
      </div>
      <p className='text-sm opacity-30 dark:opacity-70 dark:text-white'>{time}</p>
    </article>
  );
}

type TImageToUploadProps = {
  src: string;
  alt: string;
  onRemoveImage?: VoidCallback;
};

function ImageToUpload({ src, alt, onRemoveImage }: TImageToUploadProps) {
  return (
    <div className='relative p-4'>
      <button className='absolute top-0 right-0' onClick={onRemoveImage}>
        <CancelIcon color='disabled' />
      </button>
      <img alt={alt} src={src} className='max-h-14' />
    </div>
  );
}

const SubmitOption = ({ text, onClick, disabled }: TPostSubmitOption) => {
  return (
    <button
      className='flex flex-row gap-4 p-1.5 md:p-2 w-[224px] md:w-[226px] bg-accent dark:bg-white text-white dark:text-accent'
      onClick={onClick}
      disabled={disabled}
    >
      <p className='dark:text-accent'>{text}</p>
    </button>
  );
};

const Activities = () => {
  const [postText, setPostText] = useState<string>('');
  const userData = useAppSelector(userDataSelector);
  const creatingPost = useAppSelector(creatingPostsSelector);
  const news = useAppSelector(newsSelector);
  const allPosts = useAppSelector(postsSelector);
  const dispatch = useAppDispatch();
  const imageUploadInput = useRef<HTMLInputElement>(null);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>([]);
  const [imagesToPreview, setImagesToPreview] = useState<string[]>([]);
  const animationTimeoutRef = useRef<NodeJS.Timeout>();
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>();
  const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const name = useMemo(() => userData?.member_info?.name, [userData?.member_info?.name]);

  const posts = useMemo(
    () =>
      allPosts
        .filter((post) => !post.is_hidden)
        .map(
          ({
            id,
            name,
            medias,
            content,
            is_liked,
            metadata,
            created_at,
            no_of_likes,
            no_of_comments,
            profile_image_url_id,
          }) => ({
            id,
            imgUrl: '',
            text: content,
            name,
            time: getRelativeTime(created_at),
            likes: no_of_likes,
            comments: no_of_comments,
            profileMediaId: profile_image_url_id,
            isLiked: is_liked,
            metadata,
            mediaIds: medias
              .filter((media) => media.media_type === 'image')
              .map((media) => media.media_url_id),
          }),
        ),
    [allPosts],
  );

  const onClickSubmitOptions = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElement(event.currentTarget);
    setIsShowingOptions(true);
  }, []);

  const onCloseSubmitOptions = useCallback(() => {
    setPopoverAnchorElement(null);
    setIsShowingOptions(false);
  }, []);

  function onChangePostText(e: ChangeEvent<HTMLInputElement>) {
    setPostText(e.target.value);
  }

  function onPostKeyDown(e: React.KeyboardEvent) {
    // If enter key is pressed and there's something to post
    if (e.key === 'Enter' && (postText.length || imagesToUpload.length) && !creatingPost) {
      onClickCreatePost();
    }
  }

  const updateListOfImages = useCallback((newImage: File, newSrc: string) => {
    setImagesToUpload((prevImages) => [...prevImages, newImage]);
    setImagesToPreview((prevImages) => [...prevImages, newSrc]);
  }, []);

  async function onClickCreatePost(isAskingAIAgent = false) {
    const body = new FormData();
    const metadata = {} as TPostMetadata;
    const userRegex = /@([a-zA-Z0-9]*)/g;

    // Get metadata for user names of the form "@username"
    const userMatches = postText.match(userRegex);
    let updatedPostText = postText;

    userMatches?.forEach((userMatch) => {
      metadata.user = {
        name: userMatch.slice(1),
        type: 'user',
        id: 0,
      };

      updatedPostText = postText.replace(userMatch, '{{ user }}');
    });

    body.append(
      'post_data',
      JSON.stringify({
        content: updatedPostText,
        metadata: Object.keys(metadata).length ? metadata : null,
      }),
    );

    if (imagesToUpload.length) {
      imagesToUpload.forEach((imageToUpload) => {
        body.append('medias', imageToUpload);
      });
    }

    try {
      const response = isAskingAIAgent
        ? await dispatch(askAIAgent(updatedPostText)).unwrap()
        : await dispatch(createPost(body)).unwrap();
      if (response) {
        setPostText(''); // Clear the post text input
        setImagesToUpload([]); // Clear image files
        setImagesToPreview([]); // Clear image previews
      }
    } catch (error) {
      // console.error(error as Error);
    }
  }

  const submitOptions: TPostSubmitOption[] = [
    { onClick: () => onClickCreatePost(false), text: 'Create a Post' },
  ];

  function onClickUploadImage() {
    imageUploadInput?.current?.click();
  }

  const onRemoveImageToUpload = useCallback(
    (index: number) => {
      if (!creatingPost) {
        setImagesToUpload((prevImages) => [
          ...prevImages.slice(0, index),
          ...prevImages.slice(index + 1, prevImages.length),
        ]);
        setImagesToPreview((prevImages) => [
          ...prevImages.slice(0, index),
          ...prevImages.slice(index + 1, prevImages.length),
        ]);
      }
    },
    [creatingPost],
  );

  const onImageUpload = (event: TChangeInputEvent) => {
    handleImageUpload(event, (file) => {
      const reader = new FileReader();

      reader.onload = () => {
        updateListOfImages(file, reader.result as string);
      };

      reader.readAsDataURL(file);

      if (reader.readyState == FileReader.DONE) {
        reader.abort();
      }
    });
  };

  function fetchNews() {
    dispatch(getNews({ limit: 10, offset: 0 }));
    const newsIcon = document.getElementById('news-icon');
    newsIcon?.classList.add('animate-spin'); // Make the news icon spin

    animationTimeoutRef.current = setTimeout(() => {
      newsIcon?.classList.remove('animate-spin'); // Stop the spinning news icon
      clearTimeout(animationTimeoutRef.current);
    }, 1000);
  }

  useLayoutEffect(() => {
    dispatch(getCurrentUser());

    return () => clearTimeout(animationTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (userData?.member_info) {
      batch(() => {
        dispatch(getActivePosts({ limit: 10, offset }));
        fetchNews();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userData?.member_info, offset]);

  const loadMorePosts = async () => {
    setLoading(true);  
    try {
      const response = await dispatch(getActivePosts({ limit: 10, offset }));
      if (response) {
        setOffset((prevOffset) => prevOffset + 10); // Increase offset after loading more posts
      }
    } catch (error) {
      console.error('Error fetching more posts:', error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    if (userData?.member_info) {
      loadMorePosts();
    }
  }, [userData?.member_info]);

  return (
    <section className='grid md:grid-cols-[1.4fr,0.6fr] gap-1'>
      {/** Posts */}
      <div>
        <p className='text-[18px] font-semibold pb-10'>Activity Feed</p>
        <div className='commentForm bg-white rounded-[12px] p-5 dark:bg-dark-card-bg'>
          <div className='grid grid-cols-[0.08fr,1.9fr] gap-1 '>
            <UserAvatar fullName={name} mediaId={userData?.member_info?.profile_image_url_id} />
            <span id='addActivityPost'>
              <FormInput
                name='chate'
                placeholder="What's on your mind?"
                className='!w-full !h-[40px]'
                onChange={onChangePostText}
                // onKeyDown={onPostKeyDown}
                value={postText}
                disabled={creatingPost}
              />
            </span>
          </div>
          <div className='flex items-center justify-between '>
            <div className='text-[#C3C5CD] flex flex-row gap-3'>
              <IconButton disableRipple disabled={creatingPost} onClick={onClickUploadImage}>
                <PhotoIcon className='w-[24px] dark:text-white' id='imageUploadLink' />
                <input
                  ref={imageUploadInput}
                  hidden
                  accept='image/*'
                  type='file'
                  onChange={onImageUpload}
                />
              </IconButton>
              {!HIDDEN && (
                <>
                  <IconButton>
                    <PlayCircleIcon className='w-[24px]' />
                  </IconButton>
                  <IconButton>
                    <CalendarIcon className='w-[24px]' />
                  </IconButton>
                </>
              )}
              <div className='flex flex-row gap-2 max-w-7xl overflow-x-scroll'>
                {imagesToPreview.map((imageToPreview, index) => (
                  <ImageToUpload
                    key={imageToPreview + index}
                    src={imageToPreview}
                    alt={'create-post-' + imagesToUpload[index].name}
                    onRemoveImage={() => onRemoveImageToUpload(index)}
                  />
                ))}
              </div>
            </div>
            <>
              <div className='flex flex-row items-center' id='askHawkeyeVisionTech'>
                <button
                  className='rounded p-1.5 md:p-2 bg-accent dark:bg-white text-white dark:text-accent'
                  onClick={() => onClickCreatePost(false)}
                  disabled={creatingPost || !postText}
                >
                  Create a Post
                </button>
              </div>

              <Popover
                open={isShowingOptions}
                onClose={onCloseSubmitOptions}
                anchorEl={popoverAnchorElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 29,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className='flex flex-col'>
                  {submitOptions.map((option) => (
                    <SubmitOption
                      key={option.text}
                      disabled={creatingPost || !postText}
                      {...option}
                    />
                  ))}
                </div>
              </Popover>
            </>
          </div>
        </div>
        {creatingPost ? <LoadingDots className='mt-4' /> : null}

        {/* posts */}
        {posts?.map(({ id, text, imgUrl, ...otherProps }) => (
          <FeedCard
            key={id}
            id={id}
            feedText={text}
            feedImg={undefined}
            img={imgUrl}
            {...otherProps}
          />
        ))}
        {posts.length > 0 && (
          <button
            className={` p-1 px-3 text-sm bg-accent text-white rounded-sm shadow-lg my-4 transition-all duration-300 ease-in-out hover:scale-105 disabled:bg-transparent disabled:shadow-none disabled:cursor-not-allowed`}
            onClick={loadMorePosts}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={18} color='primary' className='animate-spin' />
            ) : (
              'Load more'
            )}
          </button>
        )}
      </div>

      {/* News */}
      <section className='px-5 hidden md:block'>
        <div className='flex items-center justify-between'>
          <p className='text-[18px] font-semibold'>News</p>
          <div className='flex items-center justify-between'>
            <IconButton onClick={fetchNews}>
              <ArrowPathIcon id='news-icon' className='w-[24px] text-accent dark:text-dark-text' />
            </IconButton>
          </div>
        </div>

        <div className='mt-7 flex flex-col gap-4 max-h-[80vh] overflow-y-scroll'>
          {news.map(({ title, content, created_at }, index) => (
            <NewsCard
              key={content + index}
              title={title}
              description={content}
              time={dayjs(created_at).format('MMM D[,] YYYY')}
            />
          ))}
        </div>
      </section>
    </section>
  );
};

export default Activities;
