import { HeartIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { IconButton, Popover } from '@mui/material';
import DOMPurify from 'dompurify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import classNames from 'classnames';

import { useAppDispatch } from 'app/hooks';
import FormInput from 'components/inputs/FormInput';
import { TPostComment, TPostMetadata } from 'store/slices/activitiesSlices/types';
import {
  deletePost,
  getAllActivityComments,
  likeActivityPost,
  postCommentCreate,
  removeActivityPostLike,
  updatePost,
} from 'store/thunks';
import { replaceActivityEntitiesWithLinks, replaceActivityEntitiesWithNames } from 'helpers/utils';
import { getSignedMediaUrl } from 'api/media';
import '../../pages/dashboard/styles/dashboard.scss';
import UserAvatar from 'components/shared/UserAvatar';
import { useAppSelector, useDarkMode } from 'hooks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { TPopOverOptionProps } from 'components/shared/PopOverOption/types';
import { PopOverOption } from 'components/shared/PopOverOption';
import { deletingPostSelector, updatingPostSelector } from 'store/slices/activitiesSlices/selector';
import ConfirmDeleteModal from 'components/Modal/ConfirmDeleteModal';
import { Comment } from 'containers/activities/Comment';

import { BOT_NAME } from '../../constants';
interface FeedCardProps {
  id: number;
  img: string;
  feedText: string;
  feedImg?: string;
  likes: number;
  name: string;
  time: string;
  comments: number;
  profileMediaId?: string;
  isLiked: boolean;
  metadata: TPostMetadata;
  mediaIds: string[];
}

const FeedCard: React.FC<FeedCardProps> = ({
  id,
  feedText,
  likes,
  name,
  time,
  comments,
  profileMediaId,
  feedImg,
  isLiked,
  metadata,
  mediaIds,
}) => {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<string[]>([]);
  const [commentText, setComentText] = useState<string>('');
  const [viewComment, setViewComment] = useState<boolean>(
    !!feedText?.toLowerCase().includes?.(BOT_NAME),
  );
  const [postComments, setPostComments] = useState<TPostComment[]>([]);
  const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false);
  const [isEditingPost, setIsEditingPost] = useState<boolean>(false);
  const [isDeletePostConfirmationOpen, setIsDeletePostConfirmationOpen] = useState<boolean>(false);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>();
  const { darkMode } = useDarkMode();

  const userData = useAppSelector(userDataSelector);
  const isUpdatingPost = useAppSelector(updatingPostSelector);
  const isDeletingPost = useAppSelector(deletingPostSelector);
  const cleanHTML = DOMPurify.sanitize(replaceActivityEntitiesWithLinks(feedText, metadata));

  function onClickEditPost() {
    if (userData?.member_info.name !== name) return;
    setIsEditingPost(true);
    setIsShowingOptions(false);
  }

  function onClickDeletePost() {
    setIsDeletePostConfirmationOpen(true);
    setIsShowingOptions(false);
  }

  const options: TPopOverOptionProps[] = useMemo(
    () => [
      ...(name.toLowerCase().startsWith(BOT_NAME)
        ? []
        : ([
            { onClick: onClickEditPost, type: 'edit', text: 'Edit', color: 'primary' },
          ] as TPopOverOptionProps[])),
      { onClick: onClickDeletePost, type: 'delete', text: 'Delete', color: 'error' },
    ],
    [name],
  );

  const onClickOptions = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElement(event.currentTarget);
    setIsShowingOptions(true);
  }, []);

  const onCloseOptions = useCallback(() => {
    setPopoverAnchorElement(null);
    setIsShowingOptions(false);
  }, []);

  function handleDeletingPost() {
    if (!isDeletingPost)
      dispatch(
        deletePost({
          activity_feed_id: id,
          onSuccess() {
            setIsDeletePostConfirmationOpen(false);
          },
        }),
      );
  }

  async function onLikePost() {
    if (isLiked) {
      await dispatch(removeActivityPostLike({ activity_type: 'post', activity_id: id }));
      return;
    }
    await dispatch(likeActivityPost({ activity_type: 'post', activity_id: id }));
  }

  const getImageURLs = useCallback(async () => {
    const imageURLs = [];

    for (const mediaId of mediaIds) {
      const response = await getSignedMediaUrl({ folder: 'activity-info', media_url_id: mediaId });
      imageURLs.push(response.data.url);
    }

    setImages(imageURLs);
  }, [mediaIds]);

  useEffect(() => {
    if (mediaIds.length) {
      getImageURLs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaIds.length]);

  const getAllComments = () => {
    const params = {
      offset: 0,
      limit: 10,
      id: id,
    };
    dispatch(getAllActivityComments(params))
      .unwrap()
      .then((response) => {
        setPostComments(response.data.slice(0).reverse());
      })
      .catch((e) => {});
  };

  const onCommentSubmit = async (e: any) => {
    e?.preventDefault();
    const body = {
      post_id: id,
      content: commentText,
    };

    try {
      await dispatch(postCommentCreate(body));
      setComentText(''); // Clear the comment text input

      if (viewComment) getAllComments(); // if comments are already open, load all comments
      else setViewComment(true); // Show comment
    } catch (error) {
      // console.error(error as Error);
    }
  };

  const onUpdatePost = () => {
    const postUpdate = document.getElementById('updateCommentInput')?.textContent as string;

    dispatch(
      updatePost({
        id,
        content: postUpdate,
        onSuccess() {
          setIsEditingPost(false);
        },
      }),
    );
  };

  function onPressShowComments() {
    setViewComment((prevState) => !prevState);
  }

  useEffect(() => {
    if (viewComment && !postComments?.length) {
      getAllComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postComments?.length, viewComment]);

  return (
    <div className='bg-white rounded-[12px] p-3 md:p-5 mt-10 dark:bg-dark-card-bg'>
      <div className='flex gap-1 mb-3 items-center justify-between '>
        <div className='flex flex-row justify-between w-full'>
          <div className='flex gap-3'>
            <UserAvatar fullName={name} mediaId={profileMediaId} />
            <div>
              <p className='text-main-text font-semibold dark:text-white'>
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </p>
              <p className='text-[#C3C5CD] text-sm'>{time}</p>
            </div>
          </div>
          <>
            <IconButton onClick={onClickOptions}>
              <MoreVertOutlined sx={{ ...(darkMode ? { color: 'white' } : {}) }} />
            </IconButton>
            <Popover
              open={isShowingOptions}
              onClose={onCloseOptions}
              anchorEl={popoverAnchorElement}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className='flex flex-col'>
                {options.map((option) => (
                  <PopOverOption key={option.text} {...option} />
                ))}
              </div>
            </Popover>
          </>
        </div>
      </div>
      {isEditingPost ? (
        <div className='relative'>
          <div
            id='updateCommentInput'
            className='commentForm w-full min-h-20 max-h-40 overflow-x-hidden overflow-y-auto h-8 p-2 bg-[#F8F8F8] dark:bg-[#404040] text-[#667085] dark:text-[#ffffff]'
            contentEditable={!isUpdatingPost}
          >
            {replaceActivityEntitiesWithNames(feedText, metadata)}
          </div>
          <div className='flex flex-row w-full justify-end bg-[#F8F8F8] dark:bg-[#404040]'>
            <div className='flex flex-row gap-2 px-2 pb-2'>
              <button
                className='text-sm p-1 px-2 bg-dark-sub-title dark:bg-black rounded'
                disabled={isUpdatingPost}
                onClick={() => setIsEditingPost(false)}
              >
                Cancel
              </button>
              <button
                className='text-sm p-1 px-2 text-white dark:text-accent rounded bg-accent dark:bg-[#F1F7FF]'
                disabled={isUpdatingPost}
                onClick={onUpdatePost}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <span className='text-sm' dangerouslySetInnerHTML={{ __html: cleanHTML }}></span>
      )}
      <div className='flex flex-row gap-2 py-2 max-w-7xl overflow-x-scroll' id='clearScrollbar'>
        {images.map((img) => (
          <img key={img} src={img} className='max-h-52' />
        ))}
      </div>
      {feedImg && (
        <div className='w-full m-h-[276px] relative my-4 rounded-[10px]'>
          <img src={feedImg} className='w-full h-full object-cover rounded-[10px]' />
        </div>
      )}

      <div className='flex items-center justify-between mb-3'>
        <div className='text-[#C3C5CD] flex gap-3'>
          <div className='flex items-center gap-2'>
            <button type='button' onClick={onLikePost}>
              <IconButton>
                <HeartIcon
                  className='w-[24px]'
                  color={isLiked ? 'red' : ''}
                  fill={isLiked ? 'red' : 'white'}
                />
              </IconButton>
            </button>

            <p className='text-sm text-main-text dark:text-white'>
              {likes} {`like${likes === 1 ? '' : 's'}`}
            </p>
          </div>
          <button
            className='flex items-center gap-2 cursor-pointer'
            disabled={!comments}
            onClick={onPressShowComments}
          >
            <IconButton>
              <ChatBubbleLeftIcon className='w-[24px] dark:text-white' />
            </IconButton>
            <p className='text-sm text-main-text dark:text-white'>
              {comments} {`comment${comments === 1 ? '' : 's'}`}
            </p>
          </button>
        </div>
      </div>
      {postComments?.map((comment, index) => (
        <Comment
          key={comment.created_at as string}
          index={index}
          comment={comment}
          className={classNames('', {
            hidden: !viewComment,
          })}
        />
      ))}
      <div className='grid grid-cols-[0.08fr,1.9fr] gap-3 mt-4'>
        <UserAvatar
          fullName={userData?.member_info?.name}
          mediaId={userData?.member_info?.profile_image_url_id}
        />
        <form className='commentForm' autoComplete='off' onSubmit={onCommentSubmit}>
          <div className='relative'>
            <FormInput
              name='chat'
              placeholder='Write your comment'
              value={commentText}
              onChange={(e) => setComentText(e.target.value)}
              className='!w-full !h-[40px]'
              containerClassName='flex flex-col gap-1'
            />
          </div>
        </form>
      </div>
      <ConfirmDeleteModal
        isOpen={isDeletePostConfirmationOpen}
        onClose={() => setIsDeletePostConfirmationOpen(false)}
        title='Delete Post'
        description='Are you sure you want to delete this post?'
        deleteText='Delete'
        onDelete={handleDeletingPost}
      />
    </div>
  );
};

FeedCard.defaultProps = {
  feedImg: '',
};

export default FeedCard;
