import AddIcon from '@mui/icons-material/Add';
import { FC } from 'react';

import { TTeam } from 'store/slices/teamsSlice/type';
import Avatar from 'components/shared/Avatar';

const Createteam: FC<{
  createTeam: any;
  allTeams: TTeam[];
  setViewTeamMeber: any;
  getTeamMembers: any;
  teamName: string;
  setActiveIndex: any;
  activeIndex: number;
  userWritePermission?: boolean;
  onDeleteTeam: (id: number) => void;
}> = ({
  createTeam,
  allTeams,
  setViewTeamMeber,
  activeIndex,
  setActiveIndex,
  userWritePermission,
  onDeleteTeam,
}) => {
  const gotoViewTeamMemberView = (id: number, name: string, index: number) => {
    setActiveIndex(index);
    setViewTeamMeber(true);
  };

  return (
    <div className='flex flex-wrap gap-2 mb-3'>
      {allTeams?.length > 0 &&
        allTeams?.slice(0, 6).map((team, index: any) => (
          <button
            type='button'
            key={team?.id}
            className={`btn-primary hover:bg-accent hover:text-white group dark:text-white dark:bg-btn-dark gap-2 py-1 ${
              activeIndex === index ? 'bg-accent' : 'bg-[#e8e8e8]'
            }  ${activeIndex === index ? 'text-white' : 'text-gray'}  rounded-3xl`}
            onClick={() => gotoViewTeamMemberView(team?.id, team?.name, index)}
          >
            <div className='flex gap-1 sm:gap-2 items-center -ml-4 mr-4'>
              <Avatar
                mediaFolder='teams'
                mediaId={team.image_url_id}
                alt='team mascot'
                className='w-10 h-6 rounded-full'
              />
              <p>{team.name}</p>
              <button
                className='text-red-400 ml-2 opacity-0 group-hover:opacity-100 transition-opacity font-semibold'
                onClick={async (e) => {
                  e.stopPropagation();
                  onDeleteTeam(team?.id);
                }}
              >
                x
              </button>
            </div>
          </button>
        ))}
      {allTeams?.length > 0 && userWritePermission && (
        <div className='bg-[#e5e5e5] h-0.5 lg:h-10  lg:w-0.5 w-full lg:ml-5 '> </div>
      )}
      {userWritePermission && (
        <button
          onClick={() => createTeam(true)}
          type='button'
          className={`btn-primary group  gap-2 bg-accent dark:bg-btn-dark text-white dark:text-white ${
            allTeams?.length > 0 && 'lg:ml-5'
          }`}
          id='addTeam'
        >
          <AddIcon fontSize='small' /> Add Team
        </button>
      )}
    </div>
  );
};

export default Createteam;
