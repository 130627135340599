import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import demoImage from "../../../src/assets/images/movement.svg";
import { devicesPlaceholderImg } from "assets/images";
import CustomModal from "components/Modal";

interface Point {
  x: number;
  y: number;
}

const ObjectDetector = () => {
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [points, setPoints] = useState<Point[]>([]);
  const [drawingPolygon, setDrawingPolygon] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const galleryRef = useRef<HTMLDivElement | null>(null);

  // Fetch images
  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await axios.get<string[]>("/api-endpoint"); // Replace with actual endpoint
        if (Array.isArray(response.data)) {
          setImages(response.data);
        } else {
          console.error("Invalid API response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
        
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const drawImageOnCanvas = useCallback(() => {
    if (!canvasRef.current || !imageRef.current) return;

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    if (drawingPolygon && points.length > 1) {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      points.forEach((point, index) => {
        if (index === 0) return;
        ctx.lineTo(point.x, point.y);
      });
      ctx.closePath();
      ctx.strokeStyle = "red";
      ctx.lineWidth = 3;
      ctx.stroke();
    }
  }, [points, drawingPolygon]);

  useEffect(() => {
    const img = new Image();
    const imageToUse = selectedImage || devicesPlaceholderImg || demoImage;
    img.src = imageToUse;
    img.onload = () => {
      imageRef.current = img;
      if (canvasRef.current) {
        canvasRef.current.width = img.naturalWidth;
        canvasRef.current.height = img.naturalHeight;
      }
      drawImageOnCanvas();
    };
  }, [selectedImage, drawImageOnCanvas]);

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (points.length > 0 && Math.abs(x - points[0].x) < 10 && Math.abs(y - points[0].y) < 10) {
      setPoints((prevPoints) => [...prevPoints, { x: points[0].x, y: points[0].y }]);
      setDrawingPolygon(false);
    } else {
      setPoints((prevPoints) => [...prevPoints, { x, y }]);
    }
  };

  const togglePolygonDrawing = useCallback(() => {
    setDrawingPolygon((prev) => !prev);
  }, []);

  const resetPolygon = useCallback(() => {
    setPoints([]);
    setDrawingPolygon(false);
  }, []);

  const scrollGallery = (direction: "left" | "right") => {
    galleryRef.current?.scrollBy({
      left: direction === "left" ? -100 : 100,
      behavior: "smooth",
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}
    className="w-full rounded-lg flex justify-center items-center py-10"
    >
    
        <div className="w-full flex flex-col items-center p-4 space-y-4 z-[100] relative">
          <button
            onClick={handleCloseModal}
            className="absolute -top-4 right-0 text-xl border w-[50px] h-[50px] rounded-full flex justify-center items-center hover:border-primary hover:bg-primary hover:text-white transition-colors"
          >
            x
          </button>

          <h1 className="text-2xl font-bold">Object Detection</h1>

          <div className="flex flex-col items-center space-y-4">
            <div className="flex justify-center items-center space-x-4">
              <div className="bg-slate-500">
                <img
                  className="object-cover w-full h-96 rounded-lg shadow-xl"
                  src={selectedImage || devicesPlaceholderImg}
                  alt="Main Media"
                />
              </div>

              <div className="flex flex-col gap-2 px-10">
                <button className="bg-[#0054AA] text-white p-3" onClick={togglePolygonDrawing}>
                  {drawingPolygon ? "Detect Objects " : "Detect Objects"}
                </button>
                <button className="bg-[#0054AA] text-white p-3" onClick={resetPolygon}>
                  Reset Object Detection 
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center w-full">
            <button
              onClick={() => scrollGallery("left")}
              className="py-1 px-4 bg-gray-200 dark:bg-gray-700 rounded-lg shadow hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              &lt;
            </button>
            {loading ? (
              <p>Loading images...</p>
            ) : (
              <div
                id="thumbnailGallery"
                ref={galleryRef}
                className="carousel-container flex space-x-4 mt-4 overflow-x-auto"
              >
                {images.length > 0 ? (
                  images.map((image, index) => (
                    <img
                      key={index}
                      className="object-cover w-32 h-32 rounded-lg cursor-pointer border-2 hover:border-blue-500"
                      src={image || devicesPlaceholderImg}
                      alt={`Image ${index + 1}`}
                      onClick={() => setSelectedImage(image || devicesPlaceholderImg)}
                    />
                  ))
                ) : (
                  <p className="text-black">No images available.</p>
                )}
              </div>
            )}
            <button
              onClick={() => scrollGallery("right")}
              className="py-1 px-4 bg-gray-200 dark:bg-gray-700 rounded-lg shadow hover:bg-gray-300 dark:hover:bg-gray-600"
            >
              &gt;
            </button>
          </div>
{/* 
          <canvas
            ref={canvasRef}
            onClick={handleCanvasClick}
            className="border rounded-md mt-4"
          ></canvas> */}
        </div>
    
    </CustomModal>
  );
};

export default ObjectDetector;
