import * as yup from 'yup';

export const organizationCreate = yup.object().shape({
  industryVertical: yup.string().trim(),
  phoneNumber: yup
    .string()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    })
    .nullable(),
  email: yup
    .string()
    .trim()
    .lowercase()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    .required('Email is required'),
  location: yup.string().trim(),
  ownerLastName: yup.string().trim().required('Owner last name is required'),
  ownerFirstName: yup.string().trim().required('Owner first name is required'),
  organization: yup.string().trim().required('Organization name is required'),

  typeOfBusiness: yup.string().required('Type of business is required').min(1),
  zip: yup
    .string()
    .trim()
    .test({
      name: 'zip-validation',
      test: function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^\d{5}(-\d{4})?$/.test(value);
      },
      message: 'Enter a valid zip code',
    }),
});

export const paymentSchema = yup.object().shape({
  name: yup
    .string()
    .required('Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name must only contain letters and spaces'),

  state: yup.string().trim().required('State is required'),

  address: yup.string().trim().required('Address is required'),

  zip: yup
    .string()
    .trim()
    .required('Zip code is required')
    .matches(/^\d{5}(-\d{4})?$/, 'Zip code must be a valid 5-digit format'),
});

export const inviteUserSchema = yup.object().shape({
  invitedUsers: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Enter a valid email address'),
      fullName: yup.string().trim().required('Full name is required'),
      role: yup.string().trim(),
    }),
  ),
});

export const vendorCreateSchema = yup.object().shape({
  name: yup.string().trim().required('Item name is required'),
  vendor_link: yup.string().trim().notRequired(),
  address: yup.string().trim(),
  city: yup.string().trim(),
  state: yup.string().trim(),
  zip: yup
    .string()
    .trim()
    .test({
      name: 'zip-validation',
      test: function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^\d{5}(-\d{4})?$/.test(value);
      },
      message: 'Enter a valid zip code',
    }),
  email: yup
    .string()
    .trim()
    .lowercase()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address',
    )
    .required('Email is required'),
  phone: yup
    .string()
    .trim()
    .required('Phone number is required')
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number'),
  contact_person: yup.string().trim().required('Contact name is required'),
});

export const profileSettingsSchema = yup.object().shape({
  name: yup.string().trim().required('Full Name is required'),
  address: yup.string().trim(),
  city: yup.string().trim(),
  state: yup.string().trim(),
  designation: yup.string().trim(),
  zip_code: yup
    .string()
    .trim()
    .test({
      name: 'zip-validation',
      test: function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^\d{5}(-\d{4})?$/.test(value);
      },
      message: 'Enter a valid zip code',
    }),

  email: yup
    .string()
    .trim()
    .matches(/^(?!.*@[^,]*,)/, 'Enter a valid Email'),

  mobile_number: yup
    .string()
    .trim()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
});

export const organizationSettingsSchema = yup.object().shape({
  name: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .matches(/^(?!.*@[^,]*,)/, 'Enter a valid Email'),
  address: yup.string().trim(),
  city: yup.string().trim(),
  state: yup.string().trim(),
  phone: yup
    .string()
    .trim()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
});

export const createPartSchema = yup.object().shape({
  name: yup.string().trim().required('Item name is required'),
  item_location: yup.string().trim().notRequired(),
  item_type: yup.string().trim().required('Item type is required'),
  brand: yup.string().trim().notRequired(),
  description: yup.string().trim().required('Description is required'),
  preferred_vendor: yup.object().shape({
    name: yup.string().trim().notRequired(),
  }),

  sku: yup
    .string()
    .trim()
    .test('upcOrSku', 'Invalid UPC or SKU format', (value) => {
      // Check if the value is defined
      if (value) {
        // Regular expression to validate UPC (12 numerical digits)
        const upcRegex = /^[0-9]{12}$/;
        // Regular expression to validate SKU (10 to 20 alphanumeric characters)
        const skuRegex = /^[a-z0-9]{10,20}$/i;

        // Return true if the value matches either UPC or SKU format
        return upcRegex.test(value) || skuRegex.test(value);
      }
      // Return true if the value is undefined
      return true;
    }),

  manufacturer: yup.string().trim().required('Manufacturer is required'),

  cost: yup
    .string()
    .trim()
    .required('Cost is required')
    .matches(/^[1-9][0-9]*$/, 'Cost must be a natural number greater than 0'),

  qty: yup
    .string()
    .trim()
    .required('Quantity is required')
    .matches(/^[1-9][0-9]*$/, 'Quantity must be a natural number greater than 0')
    .test('is-greater-than-initial', 'Quantity must be smaller than 1000000', function (value) {
      if (Number(value) >= 1000000) {
        return false;
      } else {
        return true;
      }
    }),
});

export const agreementSchema = yup.object().shape({
  agreement_no: yup.number().notRequired(),
  name: yup.string().trim().required('Estimate Name is required'),
  salesAmount: yup.number().required('Sales amount is required'),
  commission: yup.number().notRequired(),
  note: yup.string().trim().notRequired(),

  priority: yup.string().trim().required('Priority level is required'),
  laborCost: yup.number().required('Labor cost is required'),
  startDate: yup.date().nullable().notRequired(),
  endDate: yup.date().notRequired(),
  startTime: yup.string().trim().required('Start time is required'),
  expectedHours: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired(),
  customerData: yup.object().shape({
    customer: yup.string().trim().notRequired(),
    phone: yup
      .string()
      .trim()
      .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
        const { path, createError } = this;
        if (value && value.length > 0) {
          if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
            return createError({
              path,
              message: 'Phone Number must be a 10-digit number',
            });
          }
        }
        return true;
      }),
  }),
});

export const updateWorkOrderSchema = yup.object().shape({
  jobTypes: yup.array().notRequired(),
  Priority: yup.string().trim().required(),
  StartTime: yup.string().trim().required(),
  ServiceDates: yup.string().trim().required(),
  LaborCost: yup.number().required().positive(),
  SalesAmount: yup.number().required().positive(),

  note: yup.string().trim().notRequired(),
  BillTo: yup.string().trim().notRequired(),
  SalesRep: yup.string().trim().notRequired(),
  TransactionDate: yup.string().trim().notRequired(),
  DueDate: yup.string().trim().notRequired(),
  TaxGroup: yup.string().trim().notRequired().nullable(),

  customerData: yup.object().shape({
    email: yup.string().trim(),
    phone: yup.string().trim().notRequired(),
    address: yup.string().trim().notRequired(),
    id: yup.number().required('Labor cost is required'),
    customer: yup.string().trim().required('Customer name is required'),
  }),
});

const technicianSchema = yup.object().shape({
  techName: yup.string().trim().notRequired(),
  techPhone: yup
    .string()
    .trim()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
  managerName: yup.string().trim().notRequired(),
  managerPhone: yup
    .string()
    .trim()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
});

export const createWorkOrderSchema = yup.object().shape({
  technicianDetails: yup.array().of(
    yup.lazy((value) => {
      if (!value) {
        return yup.mixed().notRequired();
      }
      return technicianSchema;
    }),
  ),

  customer_id: yup.number(),
  customerData: yup.object().shape({
    customer: yup.string().trim(),
    phone: yup.string().trim().required(),
    email: yup
      .string()
      .trim()
      .matches(/^(?!.*@[^,]*,)/, 'Enter a valid Email')
      .notRequired(),
    address: yup.string().trim(),
    id: yup.number(),
  }),
  priority: yup.string().trim().required('Priority level is required'),
  salesAmount: yup.number().required('Sales amount is required'),
  laborCost: yup.number().required('Labor cost is required'),
  startDate: yup.date().required('Start date is required'),
  endDate: yup.date().required('End date is required'),
  startTime: yup.string().trim().required('Start time is required'),
  expectedHours: yup.number().required('Expected hours are required'),

  taxGroup: yup
    .number()
    .notRequired()
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
  transDates: yup.date().notRequired(),
  dueDates: yup.date().notRequired(),
  salesRep: yup.string().trim().notRequired(),
  bill: yup.string().trim().notRequired(),
  agreement_no: yup.number().notRequired(),
  commission: yup.number().notRequired(),
  note: yup.string().trim().notRequired(),
  jobType: yup.array().notRequired(),
  task: yup.string().trim().required('Task is required'),

  phone: yup
    .string()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
});

export const updateBillingDetailsSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  phone: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number')
    .required('Phone number is required'),
  address: yup.string().trim().required('Billing address is required'),
  state: yup.string().trim().required('Billing state is required'),
  city: yup.string().trim().required('Billing city is required'),
  zip: yup
    .string()
    .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
    .required('Billing ZIP code is required'),
});

export const addPaymentMethodSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  phone: yup
    .string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number')
    .required('Phone number is required'),
  address: yup.string().trim().required('Billing address is required'),
  state: yup.string().trim().required('Billing state is required'),
  zip: yup
    .string()
    .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
    .required('Billing ZIP code is required'),
});

export const createCustomerSchema = yup.object().shape({
  customer: yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    notes: yup.string().trim().notRequired(),
    address: yup.string().trim().required('Address is required'),
    state: yup.string().trim().required('State is required'),
    city: yup.string().trim().required('City is required'),
    zip: yup
      .string()
      .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
      .required('ZIP code is required'),
    email: yup
      .string()
      .trim()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email address',
      )
      .required('Email is required'),
    website_url: yup
      .string()
      .trim()

      .test('is-valid-url', 'Url is not correct', function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      }),

    phone_number: yup
      .string()
      .trim()
      .required('Phone number is required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number'),
    type: yup.string().required('Type is required'),
    contacts: yup.array().of(
      yup.object().shape({
        name: yup.string().trim().required('Contact name is required'),
        email: yup
          .string()
          .trim()
          .lowercase()
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Enter a valid email address',
          )
          .required('Contact email is required'),
        phone_number_1: yup
          .string()
          .required('Phone number is required')
          .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number')
          .required('Phone number is required'),

        phone_number_2: yup
          .string()
          .trim()
          .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
            const { path, createError } = this;
            if (value && value.length > 0) {
              if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
                return createError({
                  path,
                  message: 'Phone Number must be a 10-digit number',
                });
              }
            }
            return true;
          }),
        address: yup.string().trim().required('Contact address is required'),
        state: yup.string().trim().required('Contact state is required'),
        city: yup.string().trim().required('Contact city is required'),
        zip: yup
          .string()
          .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
          .required('Contact ZIP code is required'),
      }),
    ),

    billing: yup.array().of(
      yup.object().shape({
        bill_to: yup.string().required('Billing information is required'),
        email: yup
          .string()
          .trim()
          .lowercase()
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Enter a valid email address',
          )
          .required('Billing email is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number')
          .required('Phone number is required'),
        address: yup.string().trim().required('Billing address is required'),
        state: yup.string().trim().required('Billing state is required'),
        city: yup.string().trim().required('Billing city is required'),
        zip: yup
          .string()
          .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
          .required('Billing ZIP code is required'),
      }),
    ),
  }),
});

export const createCustomerSchemaIndividual = yup.object().shape({
  customer: yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    notes: yup.string().trim().notRequired(),
    address: yup.string().trim().required('Address is required'),
    state: yup.string().trim().required('State is required'),
    city: yup.string().trim().required('City is required'),
    zip: yup
      .string()
      .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
      .required('ZIP code is required'),
    email: yup
      .string()
      .trim()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email address',
      )
      .required('Email is required'),
    website_url: yup
      .string()
      .trim()

      .test('is-valid-url', 'Url is not correct', function (value) {
        if (!value || value.length === 0) {
          return true;
        }
        return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
      }),

    phone_number: yup
      .string()
      .trim()
      .required('Phone number is required')
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number'),
    type: yup.string().required('Type is required'),

    billing: yup.array().of(
      yup.object().shape({
        bill_to: yup.string().required('Billing information is required'),
        email: yup
          .string()
          .trim()
          .lowercase()
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Enter a valid email address',
          )
          .required('Billing email is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number')
          .required('Phone number is required'),
        address: yup.string().trim().required('Billing address is required'),
        state: yup.string().trim().required('Billing state is required'),
        city: yup.string().trim().required('Billing city is required'),
        zip: yup
          .string()
          .matches(/^\d{5}(?:-\d{4})?$/, 'Invalid ZIP code format')
          .required('Billing ZIP code is required'),
      }),
    ),
  }),
});

export const updateAgreementSchema = yup.object().shape({
  customerData: yup.object().shape({
    customer: yup.string().trim().required('Customer name is required'),
    phone: yup
      .string()
      .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
        const { path, createError } = this;
        if (value && value.length > 0) {
          if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
            return createError({
              path,
              message: 'Phone Number must be a 10-digit number',
            });
          }
        }
        return true;
      }),
    email: yup
      .string()
      .trim()
      .lowercase()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Enter a valid email address',
      )
      .required('Email is required'),
    address: yup.string().trim().required('Address is required'),
    id: yup.number().required('Labor cost is required'),
  }),

  Priority: yup.string().trim().required(),
  SalesAmount: yup.number().required().positive(),
  LaborCost: yup.number().required().positive(),
  StartTime: yup.string().trim().required(),
  note: yup.string().trim().notRequired(),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),
});

export const createInvoiceSchema = yup.object().shape({
  customer: yup.string().trim().required(),
  subtotal: yup
    .string()
    .trim()
    .required()
    .matches(/^[0-9]+$/, 'Must contain only digits'),
  tax: yup
    .string()
    .trim()
    .required()
    .matches(/^[0-9]+$/, 'Must contain only digits'),
  discount: yup
    .string()
    .trim()
    .required()
    .matches(/^[0-9]+$/, 'Must contain only digits'),
  shippingFees: yup
    .string()
    .trim()
    .required()
    .matches(/^[0-9]+$/, 'Must contain only digits'),
  notes: yup.string().trim().notRequired(),
  issueDate: yup.date().nullable().notRequired(),
  dueDate: yup.date().nullable().notRequired(),
  invoiceItems: yup.array().of(
    yup.object().shape({
      part_name: yup.string().trim().required(),

      quantity: yup
        .string()
        .trim()
        .required()
        .matches(/^[0-9]+$/, 'Quantity must contain only numbers'),
      unit_price: yup
        .string()
        .trim()
        .required()
        .matches(/^[1-9][0-9]*$/, 'Unit Price must be a positive number greater than 0'),
    }),
  ),
});

export const createCatalogAllValidation = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  category: yup.string().trim().required('Category is required'),
  description: yup.string().trim().notRequired(),
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().trim(),
      description: yup.string().trim().notRequired(),
      items: yup.array().of(
        yup.object().shape({
          qty_required: yup
            .string()
            .test('is-number', 'Quantity must be a number', function (value) {
              const { path, createError } = this;
              if (value && value.length > 0) {
                if (!/^\d+$/.test(value)) {
                  return createError({
                    path,
                    message: 'Quantity must be a number',
                  });
                }
              }
              return true;
            }),
          price: yup.string().test('is-number', 'Price must be a number', function (value) {
            const { path, createError } = this;
            if (value && value.length > 0) {
              if (!/^\d+$/.test(value)) {
                return createError({
                  path,
                  message: 'Price must be a number',
                });
              }
            }
            return true;
          }),

          vendor: yup.string().trim().nullable(),
          part_name: yup.string().trim().notRequired(),
          manufacturer: yup.string().trim().nullable(),
          description: yup.string().trim().notRequired(),
        }),
      ),
    }),
  ),
});

export const createTeamSchema = yup.object().shape({
  name: yup.string().trim().required('Full Name is required'),
  media: yup.string().trim(),
  position: yup.string().trim(),
  avaliability: yup.string().trim(),
  contact_info: yup.string().trim(),
});

export const createTeamMemberSchema = yup.object().shape({
  name: yup.string().trim().required('Full Name is required'),
  position: yup.string().trim(),
  avaliability: yup.string().trim(),
  contact_info: yup.string().trim(),
});

export const invitesValidationSchema = yup.object().shape({
  full_name: yup.string().trim().required('Full Name is required'),
  email: yup
  .string()
  .email('Please enter a valid email address')
  .required('Email is required')
  .transform((value) => value.replace(/\s+/g, '')),
  role_id: yup.number(),
});

export const editBoundarySchema = yup.object().shape({
  name: yup.string().trim().required(' is required'),
  description: yup.string().trim().required('is required'),
  entity_type: yup.string().trim().required(' is required'),
});

export const createEventSchema = yup.object().shape({
  event_title: yup.string().trim().required('Event title is required'),
  repeat_type: yup.string().trim().required('Repeat Type is required'),
  duration: yup.number().required('Event Duration is required'),
  //event_interval: yup.string().trim().required('Event Interval is required'),
  start_time: yup.string().trim(),
  end_time: yup.string().trim(),
  start_date: yup.string().trim(),
  end_date: yup.string().trim(),
  inference_type: yup.string().trim().required('Inference Type is required'),
  trigger_interval: yup.string().required('Trigger Interval is required'),
  // device_ids: yup.number().required(),
});
export const updateEventSchema = yup.object().shape({
  event_title: yup.string().trim().required('Event title is required'),
  repeat_type: yup.string().trim().required('Repeat Type is required'),
  duration: yup.number().required('Event Duration is required'),
  //event_interval: yup.string().trim().required('Event Interval is required'),
  start_time: yup.string().trim(),
  end_time: yup.string().trim(),
  start_date: yup.string().trim(),
  end_date: yup.string().trim(),
  //inference_type: yup.string().trim().required('Inference Type is required'),
  trigger_interval: yup.string().required('Trigger Interval is required'),
  // device_ids: yup.number().required(),
});

export const devicesSchema = yup.object().shape({
  device_model: yup.string().trim().required('Device model is required'),
  device_name: yup.string().trim().required('Device name is required'),
  section_name: yup.string().trim().required('Section name is required'),
  section_capacity: yup
    .string().trim()
    .matches(/^[0-9,.]+$/, 'Only numbers and commas are allowed')
    .required('Section capacity is required'),
});

export const contactFormSchema = yup.object().shape({
  first_name: yup.string().trim().required('First Name is required'),
  last_name: yup.string().trim().required('Last Name is required'),
  email: yup.string().trim().email('Invalid email').required('Email Address is Required'),
  phone_number: yup
    .string()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    })
    .required(),
  message: yup.string().required('Message is required'),
});
