import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FC, useState, useEffect } from 'react';
import { useDarkMode } from 'hooks';

export const BarGraph: FC<{
  data: any;
  emptyStateData?: any;
  options: any;
}> = ({ data, options, emptyStateData }) => {
  const dataLength = data?.labels?.length || 0;
  const { darkMode } = useDarkMode();

    const getBaseFontSize = () => {
      if (dataLength > 25) return 6;
      if (dataLength > 20) return 7;
      if (dataLength > 15) return 9;
      if (dataLength > 10) return 10;
      return 11;
    };
  
    const [fontSize, setFontSize] = useState(getBaseFontSize());
    const getFontSizeModifier = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 2560) {
        return 3; 
      } else if (screenWidth >= 1920) {
        return 2; 
      } else if (screenWidth >= 1280) {
        return 1; 
      } else if (screenWidth >= 1010) {
        return -1; 
      } else if (screenWidth >= 768) {
        return 1; 
      }
      return 1; 
    };
  
    useEffect(() => {
      const handleResize = () => {
        const baseFontSize = getBaseFontSize(); 
        const finalFontSize = baseFontSize + getFontSizeModifier(); 
        setFontSize(finalFontSize); 
      };
  
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [dataLength]); 
  
    const FinalFontSize = fontSize;

  const updatedOptions = {
    ...options,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          align: 'center',
          text: 'Days',
          color: darkMode ? '#ffffff' : '#000000',
          font: {
            size: FinalFontSize,
          },
        },
        ticks: {
          autoSkip: false,
          color: darkMode ? '#ffffff' : '#000000',
          font: {
            size: FinalFontSize,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',
        },
        title: {
          display: true,
          align: 'center',
          text: 'Count',
          color: darkMode ? '#ffffff' : '#000000',
        },
      },
    },
  };

  return (
    <div className='flex gap-2 relative px-2 w-full h-full'>
      <div className='w-full h-full'>
        {!data && emptyStateData && (
          <h5 style={{ position: 'absolute', top: '30%', left: '50%' }}>No Data</h5>
        )}
        <Bar data={data ?? emptyStateData} options={updatedOptions} />
      </div>
    </div>
  );
};
