import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import classNames from 'classnames';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { BarGraph } from 'components/analytics/BarGraph';
import { useAppDispatch, useAppSelector, useDarkMode, useUserOrganizationsList } from 'hooks';
import { TSumHeadCountParams, TSumHeadCountResponse } from 'store/slices/dashboardSlice/type';
import { getAnalyticsByDateTime, getSumHeadCount } from 'store/thunks';
import { TDevice } from 'store/slices/devicesSlice/type';
import { Colors } from 'types';
import { TBarGraphData } from 'components/analytics/types';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { getTotalHeadCountReportRequest } from 'api/dashboard';
import { downloadBlob } from 'helpers/utils';
import { AnalyticsToastMessages } from 'constants/ToastMessages';
import { Routes } from 'types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Chart.register(ChartDataLabels, annotationPlugin);

const analyticsDateFilter = [
  { label: 'Last 7 days', days: 7 },
  { label: '1 month ago', days: 30 },
  { label: 'Custom', days: undefined },
];

const emptyStateGraphData = {
  labels: ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
  datasets: [
    {
      label: 'No Data',
      data: [5, 8, 7, 6, 2, 4, 3],
      borderColor: '#0082dc',
      backgroundColor: Colors.LIGHT_GRAYISH_SILVER,
      fill: true,
      tension: 0.3,
    },
  ],
};

export default function HeadCountsGraph({
  title = 'Analytics',
  hideTitle = false,
  showSeeAllButton = false,
  devices,
  onPressFullScreen,
  isFullScreen,
  showDownloadButton = false,
  containerStyle,
}: Readonly<{
  title?: string;
  hideTitle?: boolean;
  showSeeAllButton?: boolean;
  devices?: TDevice[];
  onPressFullScreen: () => void;
  isFullScreen: boolean;
  showDownloadButton?: boolean;
  containerStyle?: CSSProperties;
}>) {
  const { darkMode } = useDarkMode();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentOrganization } = useUserOrganizationsList();
  const [analyticsData, setAnalyticsData] = useState<TBarGraphData>();
  const [selectedDateFilter, setSelectedDateFilter] = useState<{
    label: string;
    days: number | undefined;
  }>(analyticsDateFilter[0]);
  const [activeDeviceFilterIndex, setActiveDeviceFilterIndex] = useState<number>(0);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const deviceFilters = useMemo(
    () =>
      devices
        ? [{ id: 0, device_name: 'All' }, ...devices].map(({ id, device_name }) => ({
            text: device_name,
            id,
          }))
        : [],
    [devices],
  );

  const analyticsOptions = useMemo(() => {
    const screenWidth = window.innerWidth;
    let fontSize = 5;
    if (screenWidth < 580) {
      fontSize = 5;
    } else if (screenWidth >= 580 && screenWidth < 768) {
      fontSize = 5;
    } else if (screenWidth < 1024) {
      fontSize = 5;
    }

    return {
      maintainAspectRatio: false,
      lineWidth: 0,
      plugins: {
        legend: { display: false },
        borderJoinStyle: 'round',
        annotation: {
          annotations: {
            weekLine2: {
              type: 'line',
              scaleID: 'x',
              value: 7,
              borderColor: 'rgba(54, 162, 235, 0.5)',
              borderWidth: 2,
              label: {
                display: true,
                content: 'Week 2',
                position: 'start',
              },
            },
            weekLine3: {
              type: 'line',
              scaleID: 'x',
              value: 14,
              borderColor: 'rgba(54, 162, 235, 0.5)',
              borderWidth: 2,
              label: {
                display: true,
                content: 'Week 3',
                position: 'start',
              },
            },
            weekLine4: {
              type: 'line',
              scaleID: 'x',
              value: 21,
              borderColor: 'rgba(54, 162, 235, 0.5)',
              borderWidth: 2,
              label: {
                display: true,
                content: 'Week 4',
                position: 'start',
              },
            },
            weekLine5: {
              type: 'line',
              scaleID: 'x',
              value: 28,
              borderColor: 'rgba(54, 162, 235, 0.5)',
              borderWidth: 2,
              label: {
                display: true,
                content: 'Week 5',
                position: 'start',
              },
            },
          },
        },
        title: {
          display: false,
          text: 'Headcounts',
        },
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          color: 'grey',
          font: {
            size: 10,
          },
          formatter: (value: any) => value,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            align: 'center',
            text: 'Days',
            color: darkMode ? '#ffffff' : '#000000',
            font: {
              size: fontSize,
            },
          },
          ticks: {
            autoSkip: false,
            color: darkMode ? '#ffffff' : '#000000',
            font: {
              size: fontSize,
            },
            padding: { top: 10 }
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
          ticks: {
            color: darkMode ? '#ffffff' : '#000000',
          },
          title: {
            display: true,
            align: 'center',
            text: 'Count',
            color: darkMode ? '#ffffff' : '#000000',
          },
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
    };
  }, [darkMode]);

  <BarGraph data={analyticsData} emptyStateData={emptyStateGraphData} options={analyticsOptions} />;

  const getHeadCountsPerDevice = useCallback(
    async (args: TSumHeadCountParams) => {
      try {
        const response: TSumHeadCountResponse = await dispatch(getSumHeadCount(args)).unwrap();

        refineHeadCountsData(response);
      } catch (err: any) {
        // Handle error
      }
    },
    [dispatch],
  );

  const refineHeadCountsData = (response: TSumHeadCountResponse | undefined) => {
      const getAbbreviatedDay = (day: string) => {
        const dayMap: { [key: string]: string } = {
          mon: "M",
          tue: "TU",
          wed: "W",
          thu: "TH",
          fri: "F",
          sat: "SA",
          sun: "SU",
        };
        return dayMap[day.toLowerCase()] || day; 
      };
    
      const labels = response?.stats.map((stat) => {
        const abbreviatedDay = getAbbreviatedDay(stat.day_of_week); 
        const dateForDay = dayjs(stat.date).format('MM/DD');
        return `${abbreviatedDay} ${dateForDay}`;
      });
    
    const data = response?.stats.map((stat) => stat.count);

    const graphData = {
      labels,
      datasets: [
        {
          label: '',
          data,
          borderColor: '#0082dc',
          backgroundColor: '#4472c4',
          fill: true,
          tension: 0.3,
        },
      ],
    };

    setAnalyticsData(graphData);
  };

  function goToNextDevice() {
    if (activeDeviceFilterIndex === deviceFilters.length - 1) {
      setActiveDeviceFilterIndex(0);

      return;
    }

    setActiveDeviceFilterIndex((index) => index + 1);
  }

  function downloadTotalHeadCountReport() {
    setIsDownloadingReport(true);
    const toastId = toast.loading(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_START);

    getTotalHeadCountReportRequest({
      deviceId: deviceFilters[activeDeviceFilterIndex]?.id as number,
      days: selectedDateFilter.days,
    })
      .then((res) => {
        toast.success(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_SUCCESS, { id: toastId });
        downloadBlob(
          res.data,
          'text/csv',
          `${currentOrganization?.name ?? 'HawkeyeVisionTek'}_total_headcount_report_${dayjs(
            new Date(),
          ).format('YYYY-MM-DD_hh:mm_a')}`,
        );
      })
      .catch(() => {
        toast.error(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_FAILURE, { id: toastId });
      })
      .finally(() => {
        setIsDownloadingReport(false);
      });
  }

      const getHeadCountRequest = async (data: {
        start_date: Date;
        end_date: Date;
        device_id?: number;
      }) => {
        const response: TSumHeadCountResponse | undefined = await dispatch(
          getAnalyticsByDateTime(data),
        ).unwrap();

        refineHeadCountsData({ stats: response?.stats || response } as any);
      };

  useEffect(() => {
    if (selectedDateFilter.days) {
      const data = {
        days: selectedDateFilter.days,
        deviceId: deviceFilters[activeDeviceFilterIndex]?.id || undefined,
      };

      getHeadCountsPerDevice(JSON.parse(JSON.stringify(data)));
    }

    if (startDate && endDate) {
      const start_date = dayjs(startDate).format('YYYY-MM-DD') as unknown as Date;

      const end_date = dayjs(endDate).format('YYYY-MM-DD') as unknown as Date;

      const data = {
        start_date,
        end_date,
        device_id: deviceFilters[activeDeviceFilterIndex]?.id || undefined,
      };

      getHeadCountRequest(JSON.parse(JSON.stringify(data)));
    }
  }, [selectedDateFilter.days, startDate, endDate, activeDeviceFilterIndex]);

  const goToAnalytics = useCallback(() => {
    navigate(Routes.DashboardAnalytics);
  }, [navigate]);

  const handleDateChange = (date: Date | null, type: 'startDate' | 'endDate') => {
    if (type === 'startDate') {
      setStartDate(date);

      setEndDate(null);
    }

    if (type === 'endDate') setEndDate(date);
  };

  const onChangeDateFilter = (event: SelectChangeEvent<string>) => {
    const selectedLabel = event.target.value;

    const dateFilter = analyticsDateFilter.find((filter) => filter.label === selectedLabel);

    setSelectedDateFilter(dateFilter!);

    setStartDate(null);

    setEndDate(null);
  };

  const handleDeviceFilter = (index: number) => {
    if (activeDeviceFilterIndex !== index) setActiveDeviceFilterIndex(index);
  };

  const maxEndDate = startDate ? dayjs(startDate).add(1, 'month').toDate() : null;
  return (
    <div
      className='bg-white p-5 md:p-10 rounded-[14px] w-full h-full flex flex-col gap-6 shadow-md md:shadow-none dark:bg-dark-card-bg'
      id='analyticsDetails'
      style={containerStyle}
    >
      {/* Title and Buttons */}
      <div className='flex justify-between items-center flex-wrap gap-4'>
        <p
          className={classNames('font-extrabold text-[20px] md:text-[24px]', {
            visible: !hideTitle,
            invisible: hideTitle,
          })}
        >
          {title}
        </p>
        {showSeeAllButton && (
          <button
            className='text-accent font-semibold text-[14px] md:text-[15px] cursor-pointer dark:text-dark-text'
            onClick={goToAnalytics}
          >
            See all
          </button>
        )}
  
        <div className='flex gap-2 md:gap-4 items-center'>
          <IconButton disableRipple onClick={onPressFullScreen} className='invisible md:visible'>
            {isFullScreen ? <FullscreenExitIcon color='info' /> : <FullscreenIcon color='info' />}
          </IconButton>
          {showDownloadButton && (
            <PrimaryButton
              icon={<FileDownloadOutlinedIcon className='text-white' />}
              onClick={downloadTotalHeadCountReport}
              variant='secondary'
              id='requestReport'
              disabled={isDownloadingReport}
            >
              <p className='hidden md:flex'>Export</p>
            </PrimaryButton>
          )}
        </div>
      </div>
  
      {/* Filters and Date Pickers */}
      <div className='flex items-center justify-between flex-wrap gap-3'>
        <div className='flex gap-3 items-center flex-wrap' id='deviceInHeadcount'>
          {deviceFilters.map((filter, index) => (
            <button
              type='button'
              onClick={() => handleDeviceFilter(index)}
              className={classNames(
                'p-1 md:p-2 rounded-[7px] border border-[#E6E6E6] cursor-pointer',
                {
                  'bg-[#D2E1F9] dark:text-darkBg': index === activeDeviceFilterIndex,
                },
              )}
              key={filter.text}
            >
              <p className='opacity-80 font-semibold text-[12px] md:text-[15px]'>{filter.text}</p>
            </button>
          ))}
          {devices?.length as number > 1 && (
            <button
              type='button'
              onClick={goToNextDevice}
              className='p-1 md:p-2 rounded-[7px] border border-[#E6E6E6] cursor-pointer hover:bg-[#D2E1F9]'
            >
              <p className='opacity-80 font-semibold text-[12px] md:text-[15px]'>{'>>'}</p>
            </button>
          )}
        </div>
  
        {/* Date Filter Dropdown and Custom Date Pickers */}
        <div className='flex items-center gap-3 flex-wrap-reverse'>
          <FormControl sx={{ m: 1, width: 140 }}>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              value={selectedDateFilter.label}
              onChange={onChangeDateFilter}
              sx={{
                color: darkMode ? Colors.WHITE : Colors.ENIGMATIC_MIDNIGHT,
                background: 'transparent',
                border: darkMode ? '1px solid #ffffff' : '',
                '.MuiSvgIcon-root': {
                  color: darkMode ? 'white' : '',
                },
              }}
            >
              {analyticsDateFilter.map((dateFilter) => (
                <MenuItem key={dateFilter.label} value={dateFilter.label}>
                  {dateFilter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
  
          {selectedDateFilter.label === 'Custom' && (
            <div className='flex items-center gap-2 flex-col sm:flex-row'>
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => handleDateChange(date, 'startDate')}
                dateFormat='MM-dd-yyyy'
                maxDate={new Date()}
                placeholderText='Start date'
                className='p-2 bg-gray-100 border-gray-300 rounded-md text-xs w-full sm:w-32 md:w-auto'
              />
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => handleDateChange(date, 'endDate')}
                dateFormat='MM-dd-yyyy'
                minDate={startDate as Date}
                maxDate={maxEndDate as Date}
                placeholderText='End date'
                className='p-2 bg-gray-100 border-gray-300 rounded-md text-xs w-full sm:w-32 md:w-auto'
              />
            </div>
          )}
        </div>
      </div>
  
      {/* Bar Graph */}
      <div
        id='headCount'
        className='w-full h-[250px] overflow-hidden'
      >
        <BarGraph
          data={analyticsData}
          emptyStateData={emptyStateGraphData}
          options={analyticsOptions}
        />
      </div>
    </div>
  );
}  