import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { useDarkMode } from 'hooks';
import { Colors } from 'types';
import { API_FILTER, MONTHLY_DURATION } from 'constants/SubscriptionPlans';

import styles from './DurationTabs.module.scss';

import type { TPlanDurationPrices } from 'types/global/durationTypes';
import type { TDurationTabsProps } from './types';

const DurationTabs: FC<TDurationTabsProps> = ({
  durations,
  subscriptionType,
  selectedPlan,
  activeDuration,
  activeCardForSend,
  setActiveCardForSend,
  changeActiveDuration,
}) => {
  const { darkMode } = useDarkMode();

  useEffect(() => {
    if (subscriptionType === API_FILTER) {
      changeActiveDuration?.(durations?.[0] as TPlanDurationPrices);
    }
  }, [changeActiveDuration, durations, subscriptionType]);

  const filteredDurations =
    subscriptionType === API_FILTER
      ? durations?.filter((duration) => duration.duration === MONTHLY_DURATION)
      : durations;

  const handleChangeActiveValue = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    duration: TPlanDurationPrices,
  ) => {
    event.stopPropagation();
    changeActiveDuration?.(duration as TPlanDurationPrices);

    const renderedPrice =
      duration.duration === MONTHLY_DURATION
        ? selectedPlan?.monthlyPrice
        : selectedPlan?.yearlyPrice;

    setActiveCardForSend?.({
      ...activeCardForSend,
      duration: { ...duration, price: renderedPrice } as TPlanDurationPrices | string,
    });
  };

  return (
    <Box className={styles.container}>
      <Box
        className={styles.container__border}
        sx={{ background: darkMode ? Colors.DARK_MODE_BG : '' }}
      >
        {filteredDurations?.map((duration) => {
          const buttonClasses = classNames(styles.container__button, {
            [styles.container__button_active]: activeDuration?.duration === duration.duration,
          });

          return (
            <button
              type='button'
              key={duration.id}
              className={`${buttonClasses} `}
              onClick={(event) => handleChangeActiveValue(event, duration)}
            >
              <p className={styles.container__button__text}>{duration.duration}</p>
            </button>
          );
        })}
      </Box>
    </Box>
  );
};

export default DurationTabs;
