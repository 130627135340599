import { RootState } from 'types';

export const deviceHistorySelector = (state: RootState) => state.devices.devicesHistory;
export const mediaHistorySelector = (state: RootState) => state.devices.media_Url;
export const devicesSelector = (state: RootState) => state.devices.devices;
export const devicesLoadingSelector = (state: RootState) => state.devices.loadingDevices;
export const devicesCountsSelector = (state: RootState) => ({
  total_count: state.devices.total_count2,
  offline_count: state.devices.offline_count,
  online_count: state.devices.online_count,
});
export const startStreamSelector = (state: RootState) => state.devices.startStreamResponse;
export const endStreamSelector = (state: RootState) => state.devices.endStreamResponse;
export const batchUrlSelector = (state: RootState) => state.devices.batchUrls;
export const streamKeySelector = (state: RootState) => state.devices.streamKey;
export const controlsSelector = (state: RootState) => state.devices.controls;
