import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { orgranizationsApi } from 'api';
import dataStructure from 'constants/options';
import {
  OrganizationInformationUpdateToastMessages,
  OrganizationMemberToastMessages,
} from 'constants/ToastMessages';
import { RootState } from 'types';
import { TGetWithParams } from 'types/global/getWithParams';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TParams } from '../activitiesSlices/types';
import { getNewToken } from '../authSlice/authThunks';

import {
  TGetOrganizationMembersParams,
  TRemoveOrganizationMember,
  TUpdateEmployeeStatus,
} from './types';

export const createNewOrganization = createAsyncThunk(
  'organizationsSlice/createNewOrganization',
  async (options: FormData, { dispatch }) => {
    try {
      const response = await orgranizationsApi.createOrganizationRequest(options);

      dispatch(getNewToken());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const createNewSubscription = createAsyncThunk(
  'organizationsSlice/createNewSubscription',
  async (options: any, { dispatch }) => {
    try {
      const response = await orgranizationsApi.createSubscriptionRequest(options);

      dispatch(getNewToken());
      BrowserStorageService.set(
        BrowserStorageKeys.subscriptionClientSecret,
        JSON.stringify(response?.data?.client_secret),
      );

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateOrganization = createAsyncThunk(
  'organizationsSlice/updateOrganization',
  async (data: FormData, { dispatch }) => {
    try {
      const response = await orgranizationsApi.updateOrganizationRequest(data);
      dispatch(getAllOrganisations());

      return response.data;
    } catch (error) {
      const err = error as AxiosError;

      toast.error(OrganizationInformationUpdateToastMessages.ORGANIZATION_UPDATE_FAILURE);

      throw err;
    }
  },
);

export const getAllOrganisations = createAsyncThunk(
  'organizationsSlice/getAllOrganisation',
  async () => {
    try {
      const response = await orgranizationsApi.getUserAllOrganizationsRequest();

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getOrganizationById = createAsyncThunk(
  'organizationsSlice/getOrganizationById',
  async (org_id: number, { dispatch }) => {
    try {
      const response = await orgranizationsApi.getOrganizationByIdRequest(org_id);
      dispatch(getAllOrganisations);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getOrganizationMembers = createAsyncThunk(
  'organizationsSlice/getOrganizationMembers',
  async (params: TGetOrganizationMembersParams, { getState }) => {
    try {
      const response = await orgranizationsApi.getOrganizationMembersRequest({
        limit: params?.limit,
        offset: params?.offset,
      });

      return response.data;
    } catch (error) {
      return {
        dataStructure,
      };
    }
  },
);

export const removeOrganizationMemberById = createAsyncThunk(
  'organizationsSlice/removeOrganizationMemberById',
  async (options: TRemoveOrganizationMember) => {
    const start = toast.loading(OrganizationMemberToastMessages.ORGANIZATION_MEMBER_REMOVAL_START);

    try {
      const response = await orgranizationsApi.removeOrganizationMemberRequest(
        options.status,
        options.member_id,
      );

      toast.success(OrganizationMemberToastMessages.ORGANIZATION_MEMBER_REMOVAL_SUCCESS, {
        id: start,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(OrganizationMemberToastMessages.ORGANIZATION_MEMBER_REMOVAL_FAILURE, {
        id: start,
      });

      throw Error;
    }
  },
);

export const updateOrganizationMemberStatus = createAsyncThunk(
  'organizationsSlice/updateOrganizationMemberStatus',
  async (options: TUpdateEmployeeStatus, { dispatch, getState }) => {
    const start = toast.loading(OrganizationMemberToastMessages.MEMBER_UPDATE_START);

    try {
      const response = await orgranizationsApi.updateOrganizationMemberStatusRequest(
        options.status,
        options.member_id,
      );

      const {
        organizations: {
          allUsersLimit,
          allUsersOffset,
          organizationMembersLimit,
          organizationMembersOffset,
        },
      } = getState() as RootState;

      dispatch(
        getOrganizationMembers({
          limit: organizationMembersLimit,
          offset: organizationMembersOffset,
          org_id: options.org_id,
        }),
      );
      dispatch(getAllUsers({ limit: allUsersLimit, offset: allUsersOffset }));

      toast.success(OrganizationMemberToastMessages.MEMBER_UPDATE_SUCCESS, {
        id: start,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(OrganizationMemberToastMessages.MEMBER_UPDATE_FAIL, {
        id: start,
      });

      throw Error;
    }
  },
);

export const updateOrganizationMember = createAsyncThunk(
  'organizationsSlice/updateOrganizationMember',
  async (body: any, { dispatch, getState }) => {
    const start = toast.loading(OrganizationMemberToastMessages.MEMBER_UPDATE_START);

    try {
      const response = await orgranizationsApi.updateOrganizationMemberRequest(body);

      const {
        organizations: { allUsersLimit, allUsersOffset },
      } = getState() as RootState;

      dispatch(getAllUsers({ limit: allUsersLimit, offset: allUsersOffset }));

      toast.success(OrganizationMemberToastMessages.MEMBER_UPDATE_SUCCESS, {
        id: start,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.error(OrganizationMemberToastMessages.MEMBER_UPDATE_FAIL, {
        id: start,
      });

      throw Error;
    }
  },
);

export const getInvitationByOrgId = createAsyncThunk(
  'auth/getInvitationByOrgId',
  async (options: TGetWithParams<{ org_id: number }, 'org_id'>, { getState }) => {
    const {
      organizations: { organizationInvitedUsersLimit, organizationInvitedUsersOffset },
    } = getState() as RootState;

    const {
      limit = organizationInvitedUsersLimit,
      offset = organizationInvitedUsersOffset,
      ...rest
    } = options ?? {};

    try {
      const response = await orgranizationsApi.getInvitationByOrgIdRequest({
        limit,
        offset,
        ...rest,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllUsers = createAsyncThunk(
  'auth/getAllUsers',
  async (options: TParams, { getState }) => {
    const {
      organizations: { allUsersLimit, allUsersOffset },
    } = getState() as RootState;

    const { limit = allUsersLimit, offset = allUsersOffset } = options ?? {};

    try {
      const response = await orgranizationsApi.getAllUsersRequest({
        limit,
        offset,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getInvitedUsers = createAsyncThunk(
  'auth/getInvitedUsers',
  async (options: TParams, { getState }) => {
    const {
      organizations: { allUsersLimit, allUsersOffset },
    } = getState() as RootState;

    const { limit = allUsersLimit, offset = allUsersOffset } = options ?? {};

    try {
      const response = await orgranizationsApi.getInvitedPendingUsers({
        limit,
        offset,
      });

      return response.data;
    } catch (error) {
      return dataStructure;
    }
  },
);

export const updateDefaultOrganization = createAsyncThunk(
  'auth/updateDefaultOrganization',
  async (default_org_id: number) => {
    try {
      const response = await orgranizationsApi.updateDefaultOrganizationRequest(default_org_id);
      // Save default organisation
      BrowserStorageService.set(
        BrowserStorageKeys.CurrentOrganizationId,
        JSON.stringify(default_org_id),
      );
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const sendOrganizationResources = createAsyncThunk(
  'organizationsSlice/sendOrganizationResources',
  async ({ org_id, subscription_id }: { org_id: number; subscription_id: string }) => {
    try {
      const response = await orgranizationsApi.sendOrganizationResourcesRequest(
        org_id,
        subscription_id,
      );

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      throw axiosError;
    }
  },
);
