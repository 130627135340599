import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';

import 'pages/dashboard/styles/dashboard.scss';

import { TDevice } from 'store/slices/devicesSlice/type';
import { getSignedMediaUrl } from 'api/media';
import { devicesPlaceholderImg } from 'assets/images';
import { TDeviceModalProps } from 'components/Modal/DeviceControlModal';
import { EditIcon, HistoryIcon } from 'components/icon';

const enableEdit = false;

export const DeviceCard: FC<{
  device: TDevice;
  setShowEditDevice?: any;
  totalCount?: number;
  setShowAllDevices?: any;
  setDeviceHistoryInfoId?: any;
  getEditDeviceInfo?: any;
  getAllDevicesHistory?: any;
  fromDashboard?: boolean;
  onOpenDeviceModal: (args: TDeviceModalProps & { modalType: 'view' | 'edit' }) => void;
}> = ({
  device,
  setShowEditDevice,
  totalCount,
  setDeviceHistoryInfoId,
  setShowAllDevices,
  getEditDeviceInfo,
  fromDashboard = false,
  onOpenDeviceModal,
}) => {
  const [mediaUrl, setMediaUrl] = useState<string>();

  const navigate = (device: any) => {
    setDeviceHistoryInfoId(device);
    setShowAllDevices(false);
  };

  const onEdit = (device: TDevice) => {
    getEditDeviceInfo(device);
    setShowEditDevice(true);
  };

  const openDeviceModal = useCallback(
    (modalType: 'edit' | 'view') => {
      onOpenDeviceModal({
        deviceId: device.id,
        deviceName: device.device_name,
        mediaUrl,
        modalType,
      });
    },
    [device.device_name, device.id, mediaUrl, onOpenDeviceModal],
  );

  useEffect(() => {
    if (device?.latest_image_url_id) {
      getSignedMediaUrl({ folder: 'analytics', media_url_id: device?.latest_image_url_id })
        .then((response) => {
          setMediaUrl(response.data.url);
        })
        .catch((e) => {});
    }
  }, [device?.latest_image_url_id]);

  return (
    <div className='p-3 flex flex-col gap-3 bg-white rounded-[5px] w-fill  md:max-w-[250px] dark:bg-dark-card-bg'>
      <div className='flex flex-col'>
        <p className='capitalize font-semibold text-[20px] truncate'>{device?.device_name}</p>
      </div>

      <div className='relative flex gap-2 flex-col items-center'>
        <img
          src={mediaUrl || devicesPlaceholderImg}
          onClick={() => {
            openDeviceModal('view');
          }}
          style={{ objectFit: 'cover' }}
          className='cursor-pointer h-40 w-full rounded-md'
        />

        <div className='absolute top-2 left-2 flex items-center gap-1 bg-white dark:bg-black dark:bg-opacity-80 bg-opacity-70 px-2 py-1 rounded-md'>
          <div
            className={cn('size-3 rounded-full', {
              'bg-red-600': device.status === 'offline',
              'bg-green-600': device.status === 'online',
            })}
          />
          <p className='capitalize text-[14px] lg:text-[12px] opacity-70'>{device.status}</p>
        </div>

        <div className='flex justify-between w-full absolute bottom-0 p-2'>
          <button
            className='capitalize font-semibold text-gray-600 hover:text-black cursor-pointer dark:text-dark-text dark:hover:text-gray-200 flex items-center gap-2 bg-white/70 p-2 rounded-md transition-colors duration-300 hover:bg-white/90'
            onClick={() => navigate(device)}
            title='History'
          >
            <HistoryIcon className='w-4 h-4'/>
          </button>
          <button
            className='capitalize font-semibold text-gray-600 hover:text-black cursor-pointer dark:text-dark-text dark:hover:text-gray-200 flex items-center gap-2 bg-white/70 p-2 rounded-md transition-colors duration-300 hover:bg-white/90'
            onClick={() => openDeviceModal('edit')}
            title='Configuration'
          >
            <EditIcon className='w-4 h-4'/>
          </button>
        </div>
      </div>

      {/* bottom section */}
      {!fromDashboard && (
        <div className='flex justify-between items-center'>
          {enableEdit ? (
            <div className='flex items-center'>
              <p className='capitalize font-semibold text-[15px]'>Update:</p>
              <BorderColorOutlinedIcon
                onClick={() => onEdit(device)}
                className='text-[#464646]'
                fontSize='small'
                sx={{ fontSize: '15px' }}
              />
            </div>
          ) : totalCount ? (
            <div className='flex items-center gap-1'>
              <p className='capitalize font-semibold text-[15px] opacity-50 '>Total Count:</p>
              <p className='capitalize font-semibold text-[15px]'>{totalCount}</p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
