import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { Box, FormControlLabel, Radio, Stack } from '@mui/material';

import { useAppSelector } from 'hooks';
import {
  userSubscriptionsLoaderSelector,
  currentSubscriptionPlanLoadingSelector,
} from 'store/slices/subscriptionSlice/selectors';

import ListItem from '../../views/ListItem';

import { checkBoxCheckedStyles } from './styles';
import styles from './SubscriptionPlanCard.module.scss';

import type { TSubscriptionPlanCardProps } from './types';

const SubscriptionPlanCard: FC<TSubscriptionPlanCardProps> = ({
  plan,
  isActive,
  duration,
  className,
  getCardOptions,
  activePlanTitle,
  setActiveDuration,
  activePlanInterval,
  subscriptionType,
  onClick,
}) => {
  const userSubscriptionsLoader = useAppSelector(userSubscriptionsLoaderSelector);
  const createdSubscriptionPlan = useAppSelector(currentSubscriptionPlanLoadingSelector);

  const listItems = plan.options.map((listItem) => (
    <ListItem isActive={isActive} key={listItem} title={listItem} />
  ));

  const activeCardClasses = classNames(styles.container, className, {
    [styles.container_active]: isActive,
  });

  useEffect(() => {
    if (!userSubscriptionsLoader && !createdSubscriptionPlan) {
      const matchingDuration = plan.durations.find(
        (duration) => duration.duration === activePlanInterval,
      );
      if (matchingDuration) {
        setActiveDuration?.(matchingDuration);
      }
    }
  }, [
    plan.durations,
    activePlanTitle,
    setActiveDuration,
    activePlanInterval,
    userSubscriptionsLoader,
    createdSubscriptionPlan,
  ]);

  const handleTitleChange = () => {
    if (duration) {
      const price = duration.duration === 'Monthly' ? plan.monthlyPrice : plan.yearlyPrice;
      getCardOptions?.({
        title: plan.title,
        duration: duration.duration,
        amount: price,
        subscription_type: subscriptionType,
      });
    }
  };
  const controlCheckBox = (
    <Radio checked={isActive} onChange={handleTitleChange} sx={{ display: 'none' }} />
  );

  return (
    <Box onClick={handleTitleChange}>
      <Box>
        <Box className='bg-accent mb-1 text-white p-1 rounded-md'>
          <FormControlLabel
            label=''
            value={plan.title}
            control={controlCheckBox}
            sx={checkBoxCheckedStyles(isActive)}
            className={styles.container__head__label}
          />
          <div>
            <p className={`-mt-4 mb-1 ${styles.container__head__text}`}>{plan.title}</p>
          </div>
        </Box>
        <Stack
          className={` flex border shadow-md rounded-md py-4 gap-4 px-2 min-h-[330px] w-[260px] cursor-pointer ${
            isActive ? styles.container_active : ''
          }`}
        >
          {listItems}
        </Stack>
      </Box>
      {/* 
      <Box className={styles.container__price}>
        <PriceValueBox
          isActive={isActive}
          price={cardPrice}
          duration={duration?.duration as string}
        />
      </Box> */}
    </Box>
  );
};

export default SubscriptionPlanCard;
