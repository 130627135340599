import { TOrganization } from '../organizationsSlice/types';

export type TInitialState = {
  currentInvitation: TInvitation[];
  currentInvitationLoading: boolean;
  currentInvitationError: Error | null | string;
  userInvites: TUserInvite[];
  pendingUserInvites: TUserInvite[];
  invitedUsers: TInvitedUser[];
};

export type TInvitation = {
  id: number;
  email: string;
  org_id: number;
  status: string;
  role_id: number;
  full_name: string;
  updated_at: string;
  created_at: string;
  team_id: null | number;
};

export type TCreateInvitation = {
  email: string;
  team_id: null;
  org_id: number;
  role_id: number;
  full_name: string;
};

export type TPendingInvitation = {
  id: number;
  email: string;
  org_id: number;
  status: string;
  role_id: number;
  full_name: string;
  created_at: string;
  updated_at: string;
};

export type TInviteStatus = 'pending' | 'accepted' | 'rejected' | 'revoked' | 'deactivated';
export enum EInviteStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  REVOKED = 'revoked',
}

export type TUpdateInvitationParams = {
  id: number;
  status: TInviteStatus;
};

export type TUpdateEmailInvitationParams = {
  invitation_token: string;
  status: Omit<TInviteStatus, 'pending' | 'revoked'>;
  user_id?: number;
};

export type TUserInvite = TInvitedUser & { organization: TOrganization };

export type TUserInvitesResponse = {
  data: TUserInvite[];
  total_count: number;
};

export type TInvitedUser = {
  email: string;
  full_name: string;
  role_id: number;
  created_at: string;
  updated_at: string;
  id: number;
  org_id: number;
  status: TInviteStatus;
};
export type TInviteUser = TInviteUserValue[];

export type TInviteUserValue = {
  email: string;
  full_name: string;
} & Partial<{
  role_id: number;
  org_id: number;
}>;

export type TGetInviteParams = {
  limit: number;
  offset: number;
};

export type TGetAllInvitedUsersResponse = {
  data: TInvitedUser[];
  total_count: number;
};

export type TUpdateUserBody = {
  name: string;
  mobile_number: string;
  office_number: string;
  address: string;
  state: string;
  city: string;
  country: string;
  zip_code: string;
  is_active: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  in_app_notification: boolean;
  tour: {
    enabled: boolean;
    pages: {
      overview: boolean;
      events: boolean;
      devices: boolean;
      team: boolean;
      activity_feed: boolean;
    };
  };
};

export type TUpdateUserResponse = {
  email: string;
  name: string;
  is_active: boolean;
  id: number;
  oauth_provider: string;
  mobile_number: string;
  address: string;
  state: string;
  city: string;
  country: string;
  zip_code: string;
  profile_image_url_id: string;
  designation: string;
  default_org_id: number;
  tour: {
    enabled: boolean;
    pages: {
      overview: boolean;
      events: boolean;
      devices: boolean;
      team: boolean;
      activity_feed: boolean;
    };
  };
  created_at: string;
  updated_at: string;
  email_notification: boolean;
  sms_notification: boolean;
  in_app_notification: boolean;
  is_first_time_user: boolean;
};
