import { FC, useCallback, useMemo, useRef } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Badge, IconButton, Modal } from '@mui/material';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import cn from 'classnames';
import { Popover, SvgIconProps } from '@mui/material';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import classNames from 'classnames';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tour from 'reactour';
import ReactJoyride, { CallBackProps, STATUS, ACTIONS } from 'react-joyride';

import { Colors, Routes, TChangeInputEvent } from 'types';
import {
  useAppDispatch,
  useAppSelector,
  useClearStorage,
  useDarkMode,
  useTopLoader,
  useUserOrganizationsList,
} from 'hooks';
import {
  getAllNotifications,
  getCurrentUser,
  getUserInvites,
  updateProfileImage,
  updateUser,
} from 'store/thunks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import Avatar from 'components/shared/Avatar';
import { OrganizationItem } from 'containers/organizationsList';
import NotificationsCard from 'components/Cards/NotificationsCard';
import { getPendingUserInvitesSelector } from 'store/slices/invitationsSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { notificationsSelector } from 'store/slices/activitiesSlices/selector';
import { organizationsSelector } from 'store/slices/organizationsSlice/selectors';
import ToggleButton from 'components/buttons/ToggleButton';
import CustomModal from 'components/Modal';
import UserAvatar from 'components/shared/UserAvatar';
import {
  activityFeedTourSteps,
  analyticsTourSteps,
  billingsSettingsSteps,
  dashboardSteps,
  defaultSteps,
  deviceTourStep,
  eventTourStep,
  inviteProfileSteps,
  notificationTabSteps,
  settingsOrgSteps,
  settingsProfileSteps,
  teamsTourSteps,
} from 'constants/tourSteps';
import { TUpdateUserTour } from 'store/slices/authSlice/types';
import { TourPathName } from 'constants/tourPathName';
import { EInviteStatus } from 'store/slices/invitationsSlice/types';

import Container from '../../../components/shared/Container';
import { NavItem } from '../../../components/shared/NavItem';
import HawkEyeLogo from '../../../components/shared/HawkEyeLogo';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';

import './styles/layout.scss';
import type { TLayoutProps } from './type';
import { handleImageUpload } from 'helpers/utils';

import ChatAgent from 'pages/dashboard/chats';

const HIDE = true;

export type TRoutes = {
  title: string;
  icon?: React.FC<SvgIconProps>;
  isLogout?: boolean;
  route?: string;
  action?: () => void;
  id?: string;
};

const navRoutes: TRoutes[] = [
  {
    title: 'Overview',
    icon: DashboardOutlinedIcon,
    route: Routes.Dashboard,
    id: 'dashboardLink',
  },
  {
    title: 'Analytics',
    icon: BarChartIcon,
    route: Routes.DashboardAnalytics,
    id: 'analyticsLink',
  },
  /*{
    title: 'Seat Mapping',
    icon: AirlineSeatReclineNormalIcon,
    route: Routes.SeatMapping,
    id: 'seatMappingLink',
  },
  */
  {
    title: 'Events',
    icon: BubbleChartOutlinedIcon,
    route: Routes.DashboardEvents,
    id: 'eventLink',
  },
  {
    title: 'Devices',
    icon: DevicesOtherOutlinedIcon,
    route: Routes.DashboardDevices,
    id: 'devicesLink',
  },
  {
    title: 'Team',
    icon: GroupsOutlinedIcon,
    route: Routes.DashboardTeam,
    id: 'teamLink',
  },
  {
    title: 'Activity Feed',
    icon: TextsmsOutlinedIcon,
    route: Routes.ActivityFeed,
    id: 'activityLink',
  },
  {
    title: 'Settings',
    icon: SettingsOutlinedIcon,
    route: Routes.ProfileSettings,
    id: 'settingsLink',
  },
];

const bottomRoutes: TRoutes[] = [
  /*{
    id: 'helpAndSupportLink',
    title: 'Help and Support',
    icon: HeadphonesOutlinedIcon,
    route: Routes.Help,
  },*/
  {
    id: 'logoutLink',
    title: 'Logout',
    icon: LogoutOutlinedIcon,
    isLogout: true,
    route: Routes.Login,
  },
];

const Layout: FC<TLayoutProps> = ({ children }) => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const userData = useAppSelector(userDataSelector);
  const userOrganizations = useAppSelector(organizationsSelector);
  const { currentOrganization, createdOrganizations } = useUserOrganizationsList();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isShowingNotifications, setIsShowingNotifications] = useState<boolean>(false);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>();
  const pendingUserInvites = useAppSelector(getPendingUserInvitesSelector);
  const getAllUserNotifications = useAppSelector(notificationsSelector);
  const { clearStorage } = useClearStorage(false);
  const [isShowingUserPopOver, setIsShowingUserPopOver] = useState<boolean>(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();
  const haveActiveOrganization =
    BrowserStorageService.get(BrowserStorageKeys.HaveCreatedOrganization) === 'true';

  const updateTourStatus = async (status: string) => {
    const { pages } = userData?.member_info?.tour ?? {};
    const tourSteps = {
      overview: status === TourPathName.DASHBOARD ? false : pages?.overview,
      events: status === TourPathName.EVENT ? false : pages?.events,
      devices: status === TourPathName.DEVICES ? false : pages?.devices,
      team: status === TourPathName.TEAMS ? false : pages?.team,
      activity_feed: status === TourPathName.ACTIVITY ? false : pages?.activity_feed,
    };
    const options: TUpdateUserTour = {
      name: userData?.member_info?.name || '',
      tour: {
        pages: tourSteps,
      },
    };
    dispatch(updateUser({ showError: false, ...options }));
  };

  const clearCredentials = () => {
    clearStorage();
    window.location.href = `${window.location.origin}${Routes.Login}`;
  };

  async function getInvitesReceived() {
    await dispatch(getUserInvites({ limit: 10, offset: 0 }));
  }

  const getNotifications = useCallback(() => {
    dispatch(getAllNotifications({ limit: 10, offset: 0 }));
  }, [dispatch]);

  const onCloseOptions = useCallback(() => {
    setPopoverAnchorElement(null);
    setIsShowingNotifications(false);
  }, []);

  const token = JSON.parse(BrowserStorageService.get(BrowserStorageKeys.AccessToken) as string);
  const socketUrl = `${process.env.REACT_APP_SOCKET_BASE_URL}/${token}`;

  const { lastJsonMessage, readyState } = useWebSocket(socketUrl);

  const onClickOptions = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElement(event.currentTarget);
    setIsShowingNotifications(true);
  }, []);

  const onClickUserPopOver = useCallback(() => {
    setIsShowingUserPopOver(true);
  }, []);

  const onCloseUserOptions = useCallback(() => {
    setIsShowingUserPopOver(false);
  }, []);

  const organisationsList = useMemo(
    () =>
      createdOrganizations.map((organisation) => ({
        id: organisation.id,
        value: organisation.id,
        label: organisation.name,
        media_url_id: organisation.media_url_id,
        name: organisation.name,
        resource_status: organisation.resource_status,
      })),
    [createdOrganizations],
  );

  const toggleMenu = () => {
    setMenuOpen((t) => !t);
  };

  const unreadNotificationsCount = useMemo(
    () =>
      pendingUserInvites.length +
      getAllUserNotifications.data.filter((notification) => !notification?.is_read).length,
    [getAllUserNotifications.data, pendingUserInvites.length],
  );

  const isRouteActive = (route: string) => {
    return location.pathname === route;
  };

  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInvitesReceived();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Websocket connection is open and there's a new message
    if (readyState === ReadyState.OPEN && lastJsonMessage) {
      getNotifications();
    }
  }, [getNotifications, lastJsonMessage, readyState]);

  const { darkMode } = useDarkMode();

  const tourSteps = useMemo(() => {
    switch (location?.pathname) {
      case Routes.Dashboard:
        return dashboardSteps;
      case Routes.DashboardAnalytics:
        return analyticsTourSteps;
      case Routes.DashboardEvents:
        return eventTourStep;
      case Routes.DashboardDevices:
        return deviceTourStep;
      case Routes.DashboardTeam:
        return teamsTourSteps;
      case Routes.ActivityFeed:
        return activityFeedTourSteps;
      case Routes.ProfileSettings:
        return settingsProfileSteps;
      case Routes.InvitesSettings:
        return inviteProfileSteps;
      case Routes.OrganizationSettings:
        return settingsOrgSteps;
      case Routes.NotificationSettings:
        return notificationTabSteps;
      case Routes.BillingSettings:
        return billingsSettingsSteps;
      case Routes.DashboardSettings:
        return settingsProfileSteps;

      default:
        return defaultSteps;
    }
  }, [location?.pathname]);

  const { pages } = userData?.member_info?.tour ?? {};

  useEffect(() => {
    const path = location?.pathname;
    if (pages?.overview && path === Routes.Dashboard) {
      setIsTourOpen(true);
      updateTourStatus(TourPathName.DASHBOARD);
    }
    if (pages?.events && path === Routes.DashboardEvents) {
      setIsTourOpen(true);
      updateTourStatus(TourPathName.EVENT);
    }
    if (pages?.activity_feed && path === Routes.ActivityFeed) {
      setIsTourOpen(true);
      updateTourStatus(TourPathName.ACTIVITY);
    }
    if (pages?.team && path === Routes.DashboardTeam) {
      setIsTourOpen(true);
      updateTourStatus(TourPathName.TEAMS);
    }
    if (pages?.devices && path === Routes.DashboardDevices) {
      setIsTourOpen(true);
      updateTourStatus(TourPathName.DEVICES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, pages]);

  useEffect(() => {
    const invitationToken = BrowserStorageService.get(BrowserStorageKeys.InvitationToken);
    const invitationStatus = BrowserStorageService.get(BrowserStorageKeys.InvitationStatus);
    const invitationEmail = BrowserStorageService.get(BrowserStorageKeys.InvitationEmail);

    const userWasInvitedToAnOrganisation =
      invitationToken &&
      invitationStatus === EInviteStatus.ACCEPTED &&
      invitationEmail === userData?.general_user_info.email;

    // If user has no organisations, and no invite, go to process form
    if (
      !haveActiveOrganization &&
      currentPath !== Routes.ProcessForm &&
      !userWasInvitedToAnOrganisation
    ) {
      navigate(Routes.ProcessForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  function goToLandingPage() {
    navigate(Routes.Landing);
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data;

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsTourOpen(false);
    }

    if (action === ACTIONS.CLOSE) {
      setIsTourOpen(false);
    }
  };

  return (
    <ChatAgent>
      <div className='h-screen w-screen overflow-hidden relative'>
        <div className='flex justify-center w-full'>
          <div className=' flex items-center justify-between xlg:shadow-xl p-2 fixed w-screen bg-white z-30 dark:bg-darkBg'>
            <div className='flex items-center '>
              <div className='block xlg:hidden'>
                <IconButton onClick={toggleMenu}>
                  <MenuOutlinedIcon className='dark:text-white' />
                </IconButton>
              </div>
              <div className='flex items-center '>
                <button className='scale-75' onClick={goToLandingPage}>
                  {!currentOrganization?.media_url_id ? (
                    <HawkEyeLogo isDarkMode={darkMode} />
                  ) : (
                    <Avatar
                      fullName={currentOrganization?.name}
                      mediaId={currentOrganization?.media_url_id as string}
                      mediaFolder='organization'
                      className='max-w-[130px] max-h-[60px]'
                      onClick={() => {}}
                    />
                  )}
                </button>

                {currentOrganization && createdOrganizations ? (
                  <div className='flex flex-row items-center gap-4 md:ml-[2em]' id='switch-org'>
                    <div
                      className={classNames(
                        'flex flex-row max-w-32 md:max-w-full gap-1 items-center bg-white rounded-md px-3 py-1 hover:[#EDEFF1] border border-btn-dark transition-all dark:hover:bg-dark-card-bg-old dark:bg-dark-card-bg-old',
                        { 'cursor-pointer': userOrganizations?.total_count > 1 },
                      )}
                      onClick={userOrganizations?.total_count > 1 ? handleOpen : () => {}}
                    >
                      <p
                        className={`text-${Colors.ENIGMATIC_MIDNIGHT} capitalize font-semibold whitespace-nowrap text-[14px] truncate tracking-wider dark:text-white`}
                      >
                        {currentOrganization?.name?.toUpperCase()}
                      </p>
                      <ArrowDropDownIcon />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {HIDE ? null : (
              <div className='md:w-[500px] xlg:block hidden'>
                <div className='w-full'>
                  <TextField
                    size='small'
                    fullWidth
                    autoComplete='off'
                    placeholder='Search'
                    InputProps={{
                      startAdornment: <SearchIcon />,
                      sx: { borderRadius: '10px' },
                    }}
                  />
                </div>
              </div>
            )}
            <div className='flex items-center gap-3 md:gap-8 px-3 '>
              <div
                className='sm:flex hidden items-center bg-[#F0F7FF] rounded-md p-2 gap-2 cursor-pointer hover:border-brand border transition-all'
                onClick={() => setIsTourOpen(true)}
              >
                <p
                  className='text-accent font-semibold whitespace-nowrap text-[11px] tracking-wider'
                  title={'Tour'}
                >
                  TAKE A TOUR
                </p>
                <SlowMotionVideoIcon className='text-brand' />
              </div>
              {/* )} */}

              <Badge badgeContent={unreadNotificationsCount} color='error' onClick={onClickOptions}>
                <NotificationsNoneIcon className='text-black cursor-pointer dark:text-white' />
              </Badge>
              <div className='relative'>
                <div
                  className='rounded-full flex gap-1 xlg:gap-3 items-center cursor-pointer'
                  onClick={onClickUserPopOver}
                >
                  <UserAvatar
                    fullName={userData?.member_info?.name}
                    mediaId={userData?.member_info?.profile_image_url_id}
                    alt='profile image'
                  />
                  <div className='xlg:block hidden'>
                    <p className='font-semibold text-[16px]  whitespace-nowrap'>
                      {userData?.member_info?.name
                        ? userData.member_info.name.charAt(0).toUpperCase() +
                          userData.member_info.name.slice(1)
                        : ''}
                    </p>
                    <p className='text-[12px] whitespace-nowrap capitalize'>
                      {userData?.member_info?.designation}
                    </p>
                  </div>
                  <IconButton onClick={onClickUserPopOver}>
                    <KeyboardArrowDownIcon
                      aria-describedby='userDropdownElem'
                      className='text-black dark:text-white'
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='xlg:flex h-full bg-[#F7F7F7] relative'>
          <div
            className={cn(
              'pt-12 w-[253px] md:pt-20 -translate-x-[100%] xlg:translate-x-0 dark:bg-darkBg bg-white transition-all absolute xlg:absolute  h-screen overflow-y-auto z-20',
              {
                '!-translate-x-0  ': menuOpen,
              },
            )}
            id='clearSidebarScrollbar'
          >
            <nav className=' h-full flex flex-col justify-between dark:bg-darkBg'>
              <ul className='my-12 md:my-20 flex flex-col gap-2 md:gap-3 '>
                {navRoutes.map(({ title, icon: Icon, route, id }, index) => (
                  <NavItem
                    key={index}
                    title={title}
                    icon={Icon}
                    route={route}
                    id={id}
                    active={route ? isRouteActive(route) : false}
                    action={() => toggleMenu()}
                  />
                ))}
              </ul>
              <ul className='mb-10'>
                {bottomRoutes.map(({ title, icon: Icon, isLogout, route }, index) => (
                  <NavItem
                    key={index}
                    title={title}
                    icon={Icon}
                    route={route}
                    isLogout={isLogout}
                    active={route ? isRouteActive(route) : false}
                    action={isLogout ? () => clearCredentials() : null}
                  />
                ))}
              </ul>
            </nav>
          </div>
          <div className='pt-[4.5em] h-screen w-full' onClick={() => setMenuOpen(false)}>
            <div className=' h-full pt-10 w-full overflow-y-scroll no-scrollbar dark:bg-darkBgTransparent xlg:pr-[253px] xlg:ml-[253px]'>
              <Container className='max-w-[1700px] h-[91vh] flex flex-col justify-between pt-4 '>
                <section>{children}</section>
                <div className='py-7 mb-0'>
                  <div className='flex flex-col md:flex-row gap-4 md:items-center justify-center'>
                    <p className='text-black text-[12px] lg:text-right text-center dark:text-white'>
                      Copyright {new Date().getFullYear()} HawkEyeVisionTek LLC. All Rights
                      Reserved.
                    </p>
                    <div className='flex items-center gap-4 lg:justify-start justify-center'>
                      <Link
                        to='/'
                        className='cursor-pointer text-black text-[12px] border-r border-footer-text pr-4 dark:text-white'
                      >
                        Terms & Conditions
                      </Link>
                      <Link
                        to='/'
                        className='cursor-pointer  text-black text-[12px] dark:text-white'
                      >
                        Privacy Policy
                      </Link>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {open && (
        <CustomModal
          isOpen={open}
          onClose={handleClose}
          className='max-w-max max-h-max dark:bg-darkBg'
        >
          <div className='flex flex-col items-center md:justify-center md:p-12'>
            <HawkEyeLogo isDarkMode={darkMode} />
            <h1 className='text-center lg:text-[30px] text-[36px] font-normal xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide transition-opacity ease-in duration-700 opacity-100 dark:text-white'>
              Switch Organization
            </h1>
            <p className='text-main-sub-text font-semibold text-center dark:text-white'>
              Please choose an organization that you would like to switch to
            </p>
            <div className='mt-5 flex flex-row justify-center flex-wrap gap-5'>
              {organisationsList.map(({ id, media_url_id, name, resource_status }) => (
                <OrganizationItem
                  key={id}
                  id={id}
                  mediaUrlId={media_url_id}
                  name={name}
                  resource_status={resource_status}
                  fromNavbar={true}
                  handleClose={handleClose}
                />
              ))}
            </div>
          </div>
        </CustomModal>
      )}
      <Popover
        open={isShowingNotifications}
        onClose={onCloseOptions}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NotificationsCard />
      </Popover>

      <Modal
        open={isShowingUserPopOver}
        onClose={onCloseUserOptions}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        <div className='absolute right-4 top-20 transition-all'>
          {' '}
          <UserDropdown
            name={userData?.member_info?.name}
            designation={userData?.member_info?.designation}
            mediaId={userData?.member_info?.profile_image_url_id}
            onCloseUserOptions={onCloseUserOptions}
          />
        </div>
      </Modal>

      <ReactJoyride
        steps={tourSteps}
        run={isTourOpen}
        continuous={true}
        scrollToFirstStep={true}
        showSkipButton
        showProgress
        callback={handleJoyrideCallback}
        disableOverlayClose={false}
        styles={{
          options: {
            arrowColor: '#0054AA',
            backgroundColor: '#fff',
            textColor: '#000',
            primaryColor: '#0054AA',
            overlayColor: 'rgba(0, 0, 0, 0.6)',
          },
          tooltip: {
            fontSize: '16px',
            fontWeight: '600',
            color: '#333',
          },
          buttonBack: {
            fontSize: '14px',
          },
          buttonNext: { fontWeight: '300', fontSize: '14px' },
          buttonClose: {
            appearance: 'none',
            color: 'white',
          },
        }}
      />
    </ChatAgent>
  );
};

const UserDropdown = ({
  name = '',
  mediaId,
  designation,
  onCloseUserOptions,
}: {
  name?: string;
  mediaId?: string;
  designation?: string;
  onCloseUserOptions: () => void;
}) => {
  const navigate = useNavigate();
  const { clearStorage } = useClearStorage(false);
  const profileImageUploadInput = useRef<HTMLInputElement>(null);
  const { loaderHandler } = useTopLoader();

  const dispatch = useAppDispatch();

  function onClickSettings() {
    navigate(Routes.ProfileSettings);
    onCloseUserOptions();
  }

  const onProfileImageUpload = (event: TChangeInputEvent) => {
    handleImageUpload(event, (file) => {
      const formData = new FormData();
      formData.append('profile_image', file);
      loaderHandler(dispatch(updateProfileImage(formData)));
    });
  };

  function onClickProfileImage() {
    profileImageUploadInput?.current?.click();
  }

  const clearCredentials = () => {
    clearStorage();
    window.location.href = `${window.location.origin}${Routes.Login}`;
  };

  return (
    <div className='flex flex-col bg-white shadow-md rounded-lg overflow-hidden dark:bg-darkBg dark:text-white w-[200px] sm:w-[250px] '>
      {/* Header Section */}
      <div className='relative flex flex-col items-center p-6'>
        <button className='absolute top-4 right-4 flex items-center justify-center p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-all'>
          <ToggleButton />
        </button>
        <>
          <UserAvatar
            fullName={name}
            mediaId={mediaId}
            mediaFolder='profile-info'
            onClick={onClickProfileImage}
            alt='Profile Image'
            className='w-20 h-20 rounded-full mb-4 cursor-pointer'
          />
          <input
            ref={profileImageUploadInput}
            hidden
            accept='image/*'
            type='file'
            onChange={onProfileImageUpload}
          />
        </>
        <p className='pr-1 pl-1 text-sm sm:text-lg font-semibold text-center truncate'>
          {name ?? 'User Name'}
        </p>

        <p className='text-sm text-gray-600 dark:text-gray-400 text-center capitalize'>
          {designation}
        </p>
      </div>
      <hr className='border-t border-gray-200 dark:border-gray-700' />
      <div className='p-4 flex flex-col gap-4'>
        <button
          className='flex items-center justify-between px-4 py-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-all'
          onClick={onClickSettings}
        >
          <span className='flex items-center gap-3'>
            <SettingsOutlinedIcon />
            <p className='text-sm font-medium'>Settings</p>
          </span>
        </button>
        <button
          className='flex items-center justify-between px-4 py-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700  dark:text-red-400 transition-all'
          onClick={clearCredentials}
        >
          <span className='flex items-center gap-3'>
            <LogoutOutlinedIcon />
            <p className='text-sm font-medium'>Logout</p>
          </span>
        </button>
      </div>
    </div>
  );
};
export default Layout;
