import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { Popover, SelectChangeEvent } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from 'react-router-dom';
import { batch } from 'react-redux';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { ChartData } from 'chart.js';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import CustomModal from 'components/Modal';
import HeadCountsGraph from 'pages/dashboard/headCountsGraph';
import { devicesCountsSelector, devicesSelector } from 'store/slices/devicesSlice/selectors';
import {
  getAllEvents,
  getCurrentEvents,
  getCurrentHeadCount,
  getDevices,
  getDevicesCounts,
  getEventByID,
  getHeadCountAtIntervals,
  getSeatingDensity,
} from 'store/thunks';
import { OverViewCard } from 'components/Cards/OverViewCard';
import { Colors, Routes, VoidCallback } from 'types';
import { LineGraph } from 'components/analytics/LineGraph';
import { useDarkMode, useUserOrganizationsList, useWindowSize } from 'hooks';
import Dropdown, { TDropdownItem } from 'components/shared/Dropdown';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { downloadBlob, getDateInTimeZone } from 'helpers/utils';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { eventsSelector } from 'store/slices/eventsSlice/selectors';
import {
  THeadCountIntervalResponse,
  TSeatingDensitySectionData,
} from 'store/slices/dashboardSlice/type';
import { getHeadCountIntervalReportRequest } from 'api/dashboard';
import { AnalyticsToastMessages } from 'constants/ToastMessages';
import { TEvent } from 'api/events/types';

const emptyLineGraphData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: Colors.LIGHT_GRAYISH_SILVER,
      fill: false,
      tension: 0,
    },
  ],
};

type TContainerProps = {
  children: React.ReactNode;
  className?: string;
};

function Container({ children, className }: TContainerProps) {
  return (
    <div
      className={classNames('bg-white dark:bg-dark-card-bg p-5 md:p-10 rounded-[14px]', className)}
    >
      {children}
    </div>
  );
}

type TTitleProps = { text?: string; className?: string; children?: string };

function Title({ text, className, children }: TTitleProps) {
  return (
    <p className={classNames('font-extrabold text-[24px] pb-3', className)}>{children ?? text}</p>
  );
}
type TDownloadOptionProps = { text: string; onClick: VoidCallback; style?: CSSProperties };

const DENSITY_COLOURS = ['#D4EEFF', '#B2D3F5', '#90B9EA', '#6E9EE0', '#4C83D5'];

function getColourShade(density: number) {
  switch (true) {
    case density >= 0 && density <= 0.2:
      return '#D4EEFF';
    case density > 0.2 && density <= 0.4:
      return '#B2D3F5';
    case density > 0.4 && density <= 0.6:
      return '#90B9EA';
    case density > 0.6 && density <= 0.8:
      return '#6E9EE0';
    default:
      return '#4C83D5';
  }
}

const downloadOptions = [
  { text: 'Excel', onClick: () => {} },
  { text: 'PDF', onClick: () => {} },
];

const DownloadOption = ({ text, onClick, style }: TDownloadOptionProps) => {
  return (
    <button className='flex flex-row gap-4 p-2' onClick={onClick} style={style}>
      <p>{text}</p>
    </button>
  );
};

type TYData = Record<string, { count: number; percentageOccupancy: number; details: string }[]>;

type TSeatingDensityOverTimeProps = {
  xLabels: string[];
  yLabels?: string[];
  yData: TYData;
  className?: string;
};

const defaultXLabels = [
  'Today',
  'Last week',
  '2 Weeks ago',
  '3 Weeks ago',
  '4 Weeks ago',
  '5 Weeks ago',
  '6 Weeks ago',
  '7 Weeks ago',
];

const mediumScreenLabels = [
  'Today',
  '1 week',
  '2 weeks',
  '3 weeks',
  '4 weeks',
  '5 weeks',
  '6 weeks',
  '7 weeks',
];

const smallScreenLabels = ['Today', '1W', '2W', '3W', '4W', '5W', '6W', '7W'];

const defaultYLabels = [
  'Section 1',
  'Section 2',
  'Section 3',
  'Section 4',
  'Section 5',
  'Section 6',
  'Section 7',
];

const defaultYData = {
  ['Section 1' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 2' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 3' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 4' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 5' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 6' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
  ['Section 7' as string]: [
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
    { count: 0, percentageOccupancy: 0, details: '' },
  ],
};

function SeatingDensityOverTime({ xLabels, yData, className }: TSeatingDensityOverTimeProps) {
  if (!xLabels.length) {
    xLabels = defaultXLabels;
  }

  const numberOfLabels = xLabels.length;

  return (
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${numberOfLabels + 1}, fit-content)`,
        gap: '1px',
      }}
    >
      {/** Weeks */}
      <div
        className={`grid grid-cols-subgrid`}
        style={{ gridColumnStart: 1, gridColumnEnd: numberOfLabels + 2 }}
      >
        <div />
        {xLabels.map((xLabel, index) => {
          const labelText =
            window.innerWidth < 640
              ? smallScreenLabels[index]
              : window.innerWidth >= 640 && window.innerWidth < 1000
              ? mediumScreenLabels[index]
              : xLabel;

          const showTooltip = window.innerWidth < 1000;

          return (
            <div key={xLabel + index} className='flex items-center justify-center'>
              <div className='flex flex-row justify-center items-center gap-1'>
                <p
                  className={`text-[10px] sm:text-xs lg:text-sm xl:text-[14px] 2xl:text-base ml-2 sm:ml-0 p-0 lg:p-1`}
                  title={showTooltip ? xLabel : ''}
                >
                  {labelText}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {Object.keys(yData).map((label, index0) => (
        <>
          {/** Sections */}
          <div
            key={label}
            className='flex flex-row justify-center items-center bg-[#F0F7FF] h-fit md:h-[80px] lg:h-[90px] mr-1'
          >
            <p className='text-xs lg:text-sm p-1 text-accent min-w-fit'>{label}</p>
          </div>

          {/** Percentage densities */}
          {yData[label].map((item, index1) => {
            const densityId = 'density' + index0 + index1;
            const tooltipId = 'tooltip' + index0 + index1;

            return (
              <div
                key={densityId}
                id={densityId}
                className={classNames(
                  `bg-accent h-[20px] w-[20px] md:h-[80px] lg:h-[90px] sm:w-[5.8vw] md:w-[8vw] md:max-w-[90px] lg:w-[10vw] lg:max-w-[60px] xl:w-[10vw] xl:max-w-[90px] flex ml-4 md:ml-0 md:mr-1 justify-center items-center`,
                )}
                style={{ backgroundColor: getColourShade(Number(item.percentageOccupancy) / 100) }}
                onMouseOver={() => {
                  const tooltipEl = document.getElementById(tooltipId);

                  if (tooltipEl) {
                    tooltipEl.classList.remove('hidden');
                    tooltipEl.classList.add('block');
                  }
                }}
                onMouseOut={() => {
                  const tooltipEl = document.getElementById(tooltipId);
                  if (tooltipEl) {
                    tooltipEl.classList.remove('block');
                    tooltipEl.classList.add('hidden');
                  }
                }}
              >
                <div
                  id={tooltipId}
                  className='hidden lg:text-sm text-black bg-white w-auto p-2 absolute top-[3vh] text-xs  z-20 rounded-sm'
                >
                  <p>{item.details}</p>
                  <p>{item.count} people</p>
                  <p>{`${item.percentageOccupancy}% filled`}</p>
                </div>
              </div>
            );
          })}
        </>
      ))}
    </div>
  );
}

export default function Analytics() {
  const navigate = useNavigate();
  const devices = useAppSelector(devicesSelector);
  const {
    total_count: devicesCount,
    online_count: onlineDevicesCount,
    offline_count: offlineDevicesCount,
  } = useAppSelector(devicesCountsSelector);
  const { currentOrganization } = useUserOrganizationsList();
  const userData = useAppSelector(userDataSelector);
  const dispatch = useAppDispatch();
  const events = useAppSelector(eventsSelector);
  const [isHeadCountsGraphFullScreen, setIsHeadCountsGraphFullScreen] = useState<boolean>(false);
  const [headCountPercentageChange, setHeadCountPercentageChange] = useState<number>(0);
  const [headCount, setHeadCount] = useState<number>(0);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLButtonElement | null>();
  const [isShowingDownloadOptions, setIsShowingDownloadOptions] = useState<boolean>(false);
  const { darkMode } = useDarkMode();
  const { width: windowWidth } = useWindowSize();
  const attendanceIntervalRef = useRef<NodeJS.Timeout>();
  const [headCountIntervalData, setHeadCountIntervalData] =
    useState<ChartData<'line', number[], string>>();
  const { pastEvents, currentEvents } = useAppSelector(eventsSelector);
  const [selectedSeatingDensityEvent, setSelectedSeatingDensityEvent] = useState<TDropdownItem>();
  const [selectedRealtimeEvent, setSelectedRealtimeEvent] = useState<TDropdownItem>();
  const [seatingDensityData, setSeatingDensityData] = useState<TSeatingDensitySectionData[]>([]);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const [showEditEvent, setEditEvent] = useState(false);
  const [eventOption, setEventOption] = useState<string | null>(null);
  const [lastEventName, setLastEventName] = useState('');
  const [lastEventDate, setLastEventDate] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEventOption(event.target.value);
  };

  const formatEvents = (event: TEvent) => ({
    title: event.event_title,
    dateTime: event.start_date + 'T' + event.start_time + 'Z',
    totalHeadCount: event.total_head_count,
    img: undefined,
    event_id: event.id,
  });

  const getEventByIdEdit = async (id: number) => {
    setEditEvent(true);
    await dispatch(getEventByID(id));
  };
  function toggleHeadCountsGraphFullScreen() {
    setIsHeadCountsGraphFullScreen((prevState) => !prevState);
  }

  const lineGraphOptions = {
    maintainAspectRatio: false,
    lineWidth: 0,
    plugins: {
      legend: { display: false },
      borderJoinStyle: 'round',
      title: {
        display: false,
        text: '% Sitting Occupancy',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',
        },
      },

      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',
        },
        title: {
          display: true,
          align: 'center',
          text: 'Occupancy %',
          color: darkMode ? '#ffffff' : '#000000',
        },
      },
    },
  };

  const xLabels = useMemo(
    () => defaultXLabels.slice(0, Object.keys(seatingDensityData?.[0]?.weekly_data ?? {}).length),
    [seatingDensityData],
  );

  const yLabels = useMemo(
    () => seatingDensityData?.map((section) => section.section_name),
    [seatingDensityData],
  );

  const yData = useMemo(() => {
    const tempYData: TYData = {};

    for (const section of seatingDensityData) {
      const weeklyDataArray = Object.values(section.weekly_data);

      for (const weeklyData of weeklyDataArray) {
        if (tempYData[section.section_name]) {
          tempYData[section.section_name].push({
            count: weeklyData.max_headcount ?? 0,
            percentageOccupancy: weeklyData.seating_density ?? 0,
            details: weeklyData.notes?.join(',') ?? '',
          });
        } else {
          tempYData[section.section_name] = [
            {
              count: weeklyData.max_headcount ?? 0,
              percentageOccupancy: weeklyData.seating_density ?? 0,
              details: weeklyData.notes?.join(',') ?? '',
            },
          ];
        }
      }
    }
    return !Object.keys(tempYData).length ? defaultYData : tempYData;
  }, [seatingDensityData]);

  const allEvents = useMemo(
    () => [...currentEvents.data, ...pastEvents.data],
    [currentEvents.data, pastEvents.data],
  );

  const eventsDropdownItems: TDropdownItem[] = useMemo(
    () =>
      allEvents.map((event) => ({
        label: event.event_title,
        value: String(event.id),
      })),
    [allEvents],
  );

  const currentEventsOptions = useMemo(() => currentEvents.data, [currentEvents.data]);

  const currentEventsDropdownItems: TDropdownItem[] = useMemo(
    () =>
      currentEventsOptions?.map((event: any) => ({
        label: event.event_title,
        value: String(event.id),
      })),
    [currentEventsOptions],
  );

  const getLastEvent = useCallback(async () => {
    try {
      const response = await dispatch(
        getAllEvents({
          timeframe: 'Past',
          limit: 1,
          offset: 0,
          sort_by: 'start_date',
          asc: false,
        }),
      ).unwrap();

      if (response?.data && response.data.length > 0) {
        const lastEvent = response.data[0];
        setLastEventName(lastEvent.event_title);
        setSelectedRealtimeEvent({ label: lastEvent.event_title, value: String(lastEvent.id) });

        const lastEventDate = new Date(lastEvent.start_date);
        const formattedDate = `${
          lastEventDate.getMonth() + 1
        }-${lastEventDate.getDate()}-${lastEventDate.getFullYear()}`;
        setLastEventDate(formattedDate);
      }
    } catch (error) {
      console.error('Error fetching last event:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    getLastEvent();
  }, [getLastEvent]);

  const getHeadCount = useCallback(async () => {
    try {
      const response = await dispatch(getCurrentHeadCount()).unwrap();
      setHeadCount(response?.current_count ?? 0);
      setHeadCountPercentageChange(
        Number(parseFloat(String(response?.percent_change ?? 0)).toFixed(2)),
      );
    } catch (error: any) {
      const err = error as Error;
    }
  }, []);

  function onClickDownload(event: React.MouseEvent<HTMLButtonElement>) {
    setPopoverAnchorElement(event.currentTarget);
    setIsShowingDownloadOptions(true);
  }

  function onCloseDownloadOptions() {
    setPopoverAnchorElement(null);
    setIsShowingDownloadOptions(false);
  }

  function onChangeSeatingDensityEvent(event: SelectChangeEvent<string>) {
    const selectedLabel = event.target.value;
    const selectedEvent = eventsDropdownItems.find((item) => item.label === selectedLabel);

    if (selectedEvent) {
      setSelectedSeatingDensityEvent(selectedEvent);
    } else if (eventsDropdownItems.length) {
      setSelectedSeatingDensityEvent(eventsDropdownItems[0]);
    }
  }

  function onChangeRealtimeCountEvent(event: SelectChangeEvent<string>) {
    const selectedLabel = event.target.value;

    const selectedEvent = currentEventsDropdownItems.find((item) => item.label === selectedLabel);

    if (selectedEvent) setSelectedRealtimeEvent(selectedEvent);

    fetchRealtimeCountData(selectedEvent?.value);
  }

  const getHeadCounts = useCallback(() => {
    const selectedEvent =
      currentEvents?.data.find((event) => event.id === Number(selectedRealtimeEvent?.value)) ||
      pastEvents?.data?.[pastEvents?.data.length - 1];

    if (selectedEvent && 'trigger_interval' in selectedEvent) {
      const interval = selectedEvent.trigger_interval;

      dispatch(getHeadCountAtIntervals({ interval }))
        .unwrap()
        .then((response) => {
          refineGetHeadCountsData(response);
        })
        .catch((e) => {
          console.error('Error fetching headcounts:', e);
        });
    }
  }, [currentEvents, pastEvents, selectedRealtimeEvent, dispatch]);

  const refineGetHeadCountsData = (response: THeadCountIntervalResponse | undefined) => {
    const labels = response?.data.map((intervalData) => {
      const now = new Date();
      const times = intervalData?.time?.split?.(':') ?? [];
      const hours = Number(times[0] ?? '0');
      const mins = Number(times[1] ?? '0');
      now.setHours(hours, mins, 0);

      return dayjs(getDateInTimeZone(now)).format('h:mma');
    }); // x-axis
    const data = response?.data.map((intervalData) => intervalData.count) as number[]; // y-axis
    const graphData = {
      labels,
      datasets: [
        {
          label: '',
          data,
          borderColor: '#0082dc',
          backgroundColor: '#4472c4',
          fill: true,
          tension: 0.3,
        },
      ],
    };
    setHeadCountIntervalData(graphData);
  };

  const fetchRealtimeCountData = async (value: string | undefined) => {
    try {
      const params = value ? { event_ids: value } : {};

      const resp = await dispatch(getHeadCountAtIntervals(params)).unwrap();

      refineGetHeadCountsData(resp);
    } catch (error) {
      const err = error as Error;
    }
  };

  const fetchSeatingDensityData = async () => {
    const response = await dispatch(
      getSeatingDensity(Number(selectedSeatingDensityEvent?.value)),
    ).unwrap();

    if (response?.sections) {
      setSeatingDensityData(response.sections);
    }
  };

  function downloadHeadCountIntervalReport() {
    setIsDownloadingReport(true);
    const toastId = toast.loading(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_START);
    getHeadCountIntervalReportRequest({ interval: 5, hours: 2 })
      .then((res) => {
        toast.success(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_SUCCESS, { id: toastId });
        downloadBlob(
          res.data,
          'text/csv',
          `${currentOrganization?.name ?? 'HawkeyeVisionTek'}_headcount_interval_report_${dayjs(
            new Date(),
          ).format('YYYY-MM-DD_hh:mm_a')}`,
        );
      })
      .catch(() => {
        toast.error(AnalyticsToastMessages.HEADCOUNT_REPORT_DOWNLOAD_FAILURE, { id: toastId });
      })
      .finally(() => {
        setIsDownloadingReport(false);
      });
  }

  useEffect(() => {
    // Get headcounts every 5 mins
    attendanceIntervalRef.current = setInterval(() => {
      getHeadCounts();
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearInterval(attendanceIntervalRef.current);
    };
  }, [getHeadCounts, userData?.member_info]);

  useEffect(() => {
    // Automatically load seating density data for the first item in the dropdown if none is selected
    if (!selectedSeatingDensityEvent && eventsDropdownItems.length) {
      setSelectedSeatingDensityEvent(eventsDropdownItems[0]);
    }
  }, [eventsDropdownItems, selectedSeatingDensityEvent]);

  useEffect(() => {
    // If an event is selected from the events dropdown, fetch seating density data for that event
    if (selectedSeatingDensityEvent?.value) {
      fetchSeatingDensityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatingDensityEvent?.value]);

  useEffect(() => {
    if (userData?.member_info) {
      batch(() => {
        getHeadCounts();
        dispatch(getDevicesCounts());
        dispatch(getDevices({ limit: 10, offset: 0 }));
        getHeadCount();
        dispatch(
          getCurrentEvents({
            datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            sort_by: 'start_time',
            limit: 5,
          }),
        );
        dispatch(
          getAllEvents({
            timeframe: 'Past',
            sort_by: 'start_date',
            limit: 5,
          }),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.member_info]);

  return (
    <div className='relative max-w-screen overflow-x-auto no-scrollbar'>
      <div className='mb-10 flex items-center justify-between '>
        <p className='text-[24px] font-semibold'>Analytics</p>
      </div>

      {/** Graphs */}
      <div className='flex flex-col my-10 gap-6 md:gap-12'>
        <section className='flex flex-col md:flex-row gap-6 w-full '>
          {(windowWidth as number) <= 400 ? null : (
            <div className='flex flex-row md:flex-col gap-6 w-[243px] '>
              <div id='countOverview'>
                <OverViewCard
                  title='Count'
                  icon={GroupOutlinedIcon}
                  value={headCount}
                  growth={{
                    value: headCountPercentageChange,
                    time: '',
                  }}
                />
              </div>
              <div id='devicesOverview'>
                <OverViewCard
                  title='Devices'
                  icon={DevicesOtherOutlinedIcon}
                  value={devicesCount || 0}
                  detail={{
                    positive: onlineDevicesCount ? `${onlineDevicesCount} Online` : '',
                    negative: offlineDevicesCount ? `${offlineDevicesCount} Offline` : '',
                  }}
                  onClick={() => navigate(Routes.DashboardDevices)}
                />
              </div>
            </div>
          )}
          <Container className='grow md:h-[400px]'>
            <div className='flex flex-row w-full justify-between '>
              <div className='w-full mb-4 '>
                <Title>Realtime Count </Title>
                <div className='w-full flex justify-between items-center'>
                  {currentEvents?.data && currentEvents.data.length > 0 ? (
                    <Dropdown
                      displayEmpty
                      renderValue={(value: any) => value || 'Select an event'}
                      value={selectedRealtimeEvent?.label as string}
                      onChange={onChangeRealtimeCountEvent}
                      items={currentEventsDropdownItems}
                      width={200}
                    />
                  ) : (
                    <p></p>
                  )}

                  <div className='w-fit flex gap-4 justify-between items-center ml-auto -mt-4'>
                    {/* Display the name of the selected event or the last event if no current events */}
                    <div>
                      <h4 className='text-gray-600 dark:text-gray-400 pr-2'>
                        {currentEvents?.data?.length > 0
                          ? selectedRealtimeEvent?.label
                          : pastEvents?.data?.length > 0
                          ? pastEvents.data[pastEvents.data.length - 1]?.event_title
                          : 'No last event available'}
                      </h4>
                      <p className='text-gray-400 dark:text-gray-300 pr-2 text-xs'>
                        {currentEvents?.data && currentEvents.data.length > 0
                          ? selectedRealtimeEvent?.label
                          : lastEventDate || ''}
                      </p>
                    </div>

                    <PrimaryButton
                      icon={<FileDownloadOutlinedIcon className='text-white' />}
                      onClick={downloadHeadCountIntervalReport}
                      variant='secondary'
                      id='requestReport'
                      disabled={isDownloadingReport}
                    >
                      <p className='hidden md:flex'>Export</p>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>

            <div className='relative h-fit w-full px-2 ' id='realtimeCount'>
              <LineGraph
                data={headCountIntervalData}
                options={lineGraphOptions}
                emptyStateData={emptyLineGraphData}
                className='h-[200px] mt-4'
              />
            </div>
          </Container>
        </section>
        <Container>
          <div id='seatingDensity'>
            <div className='flex flex-row justify-between items-center mb-6 '>
              <Title className='mb-4'>Seating Density</Title>
              <Dropdown
                displayEmpty
                renderValue={(value: any) => value || eventsDropdownItems[0]?.label || 'No Events'}
                value={selectedSeatingDensityEvent?.label as string}
                onChange={onChangeSeatingDensityEvent}
                items={eventsDropdownItems}
                width={200}
              />
            </div>
            {yLabels.length ? (
              <div className='flex flex-row gap-6 w-[100%]'>
                <SeatingDensityOverTime xLabels={xLabels} yLabels={yLabels} yData={yData} />

                <div className='flex flex-col w-fit items-center justify-center text-sm md:text-base  pr-2 ml-auto'>
                  <p className='font-semibold md:mb-4'>Density</p>

                  <div className='flex '>
                    <div className='flex flex-col justify-between items-center'>
                      <p>0%</p>
                      <div className=' flex flex-col'>
                        {DENSITY_COLOURS.map((backgroundColor) => (
                          <div
                            key={backgroundColor}
                            className='w-4 h-4 md:h-12 lg:h-14'
                            style={{ backgroundColor }}
                          />
                        ))}
                      </div>
                      <p>100%</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h4>No Data</h4>
            )}
          </div>
        </Container>

        <Container className='p-0 md:p-0'>
          <HeadCountsGraph
            title='Headcounts'
            showDownloadButton
            isFullScreen={isHeadCountsGraphFullScreen}
            devices={devices}
            onPressFullScreen={toggleHeadCountsGraphFullScreen}
            containerStyle={{ padding: 0 }}
          />
        </Container>
      </div>

      <Popover
        open={isShowingDownloadOptions}
        onClose={onCloseDownloadOptions}
        anchorEl={popoverAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='flex flex-col'>
          {downloadOptions.map((option) => (
            <DownloadOption
              key={option.text}
              style={{ width: document.getElementById('download_btn')?.clientWidth }}
              {...option}
            />
          ))}
        </div>
      </Popover>
      <CustomModal
        isOpen={isHeadCountsGraphFullScreen}
        onClose={toggleHeadCountsGraphFullScreen}
        className='h-[80vh]'
      >
        <HeadCountsGraph
          hideTitle
          showDownloadButton
          isFullScreen={isHeadCountsGraphFullScreen}
          devices={devices}
          onPressFullScreen={toggleHeadCountsGraphFullScreen}
        />
      </CustomModal>
    </div>
  );
}
