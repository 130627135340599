import React, { useState } from 'react';

export interface Seat {
  x: string;
  y: number;
  occupied: boolean;
  section: string;
}

interface SeatMapProps {
  seatPositions: Seat[];
  toggleSeatStatus: (index: number) => void;
  onSeatClick: (seat: Seat) => void;
}

const SeatMap: React.FC<SeatMapProps> = ({ seatPositions, toggleSeatStatus, onSeatClick }) => {
  const [selectedSeat, setSelectedSeat] = useState<Seat | null>(null);

  const handleSeatClick = (index: number) => {
    const seat = seatPositions[index];
    setSelectedSeat(seat);
    toggleSeatStatus(index);
    onSeatClick(seat);
  };

  return (
    <div className='flex'>
      <div className='w-1/2'>
        <div className='grid grid-cols-6 gap-2 mt-4'>
          {seatPositions.map((seat, index) => (
            <div
              key={index}
              className={`w-10 h-10 flex items-center justify-center cursor-pointer rounded ${
                seat.occupied ? 'bg-red-500' : 'bg-green-500'
              }`}
              onClick={() => handleSeatClick(index)}
            >
              {seat.x}
              {seat.y}
            </div>
          ))}
        </div>
      </div>

      <div className='w-1/2 shadow-lg p-5 bg-white rounded-lg'>
        <p className='text-[20px] font-semibold mb-4 text-gray-700'>Seat Info</p>

        {selectedSeat ? (
          <>
            <div className='mb-4'>
              <p className='text-sm font-medium text-gray-600'>Seat Name</p>
              <div className='flex justify-between items-center'>
                <p className='text-lg font-semibold text-gray-800'>
                  Seat {`${selectedSeat.y}${selectedSeat.x}`}
                </p>
              </div>
              <hr className='my-2 border-gray-300' />
            </div>

            <div className='mb-4'>
              <p className='text-sm font-medium text-gray-600'>Position</p>
              <div className='flex justify-between items-center'>
                <p className='text-base font-semibold text-gray-800'>Row: {selectedSeat.y}</p>
                <p className='text-base font-semibold text-gray-800'>Column: {selectedSeat.x}</p>
              </div>
              <hr className='my-2 border-gray-300' />
            </div>

            <div className='mb-4'>
              <p className='text-sm font-medium text-gray-600'>Section</p>
              <div className='flex justify-between items-center'>
                <p className='text-base font-semibold text-gray-800'>{selectedSeat.section}</p>
              </div>
              <hr className='my-2 border-gray-300' />
            </div>

            <div className='mb-4'>
              <p className='text-sm font-medium text-gray-600'>Availability</p>
              <div className='flex justify-between items-center'>
                <p className='text-base font-semibold text-gray-800'>
                  {selectedSeat.occupied ? 'Occupied' : 'Available'}
                </p>
              </div>
            </div>
          </>
        ) : (
          <p className='text-gray-500'>Click on a seat to view details.</p>
        )}
      </div>
    </div>
  );
};

export default SeatMap;
