import { FC, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { useAppSelector, useWindowSize } from 'hooks';
import { ControlledSelect, CustomTextField } from 'components';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types/global/colors';
import { FontNames } from 'types/global/fonts';

import { breakPoints } from '../../../constants';

import styles from './InviteUsers.module.scss';
import { TInviteUserItemProps } from './types';

const InviteUserItem: FC<TInviteUserItemProps> = ({
  idx,
  isOnlyItem,
  errors,
  control,
  roles,
  removeUserInfo,
  setIsInvitingYourself,
}) => {
  const { width } = useWindowSize();
  const currentUser: any = useAppSelector(userDataSelector);

  const [inviteValidationError, setInviteValidationError] = useState<string>('');

  const invitationHandler = (value?: string) => {
    if (value?.trim() === currentUser?.email) {
      setInviteValidationError("You can't invite yourself");
      setIsInvitingYourself?.(true);
    } else {
      setInviteValidationError('');
      setIsInvitingYourself?.(false);
    }
  };

  const stackDirection = Number(width) <= breakPoints.TABLET_M ? 'column' : 'row';

  return (
    <Box className={styles.container__content}>
      <Stack
        width='100%'
        direction={stackDirection}
        useFlexGap
        gap='16px'
        {...(stackDirection === 'row' ? { justifyContent: 'center', marginBottom: 2 } : {})}
      >
        <Controller
          control={control}
          name={`invitedUsers[${idx}].fullName`}
          defaultValue=''
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              label='Full Name'
              borderRadius='10px'
              withHelperText={false}
              placeholder='Full Name:'
              error={!!errors?.fullName}
              fontFamily={FontNames.AVENIR_NEXT_PRO}
            />
          )}
        />

        <Controller
          control={control}
          name={`invitedUsers[${idx}].email`}
          render={({ field: { value, onChange } }) => {
            if (value?.trim()) {
              invitationHandler(value?.trim());
            }

            return (
              <div className={styles.container__content__email}>
                <CustomTextField
                  required
                  label='Email'
                  onChange={onChange}
                  borderRadius='10px'
                  placeholder='Email:'
                  value={value?.trim()}
                  withHelperText={false}
                  error={!!errors?.email}
                  fontFamily={FontNames.AVENIR_NEXT_PRO}
                />
                <p className={styles.container__content__email__error}>
                  {inviteValidationError || errors?.email?.message}
                </p>
              </div>
            );
          }}
        />
        <ControlledSelect
          name={`invitedUsers[${idx}].role`}
          defaultValue=''
          label='Role'
          control={control}
          options={roles}
          backgroundColor={Colors.PLATINUM_GRAY}
        />
      </Stack>
      {!isOnlyItem && (
        <button onClick={removeUserInfo}>
          <IconButton>
            <RemoveCircleOutlineIcon className='dark:text-white' />
          </IconButton>
        </button>
      )}
    </Box>
  );
};

export default InviteUserItem;
