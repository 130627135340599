import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Stack } from '@mui/material';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/CancelRounded';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { TCreateEventForm } from 'api/events/types';
import { createEventSchema } from 'constants/Schemas';
import ControlledInput from 'components/shared/ControlledInput';
import { Colors, FontNames } from 'types';
import { useAppDispatch } from 'app/hooks';
import { createEvent, getDevices } from 'store/thunks';
import { useAppSelector, useDarkMode } from 'hooks';
import { devicesSelector } from 'store/slices/devicesSlice/selectors';
import { Select } from 'components';
import {
  eventDurationOptions,
  eventRepeatTypeOptions,
  inferenceTypeOptions,
} from 'constants/eventOptions';
import { EventCustomCalender, ICustomCalendarData } from './EventCustomCalender';
import CustomModal from 'components/Modal';
import Slider from '@mui/material/Slider';
interface AddEventProps {
  onClose: () => void;
}

type InferenceTypeOption = {
  id: number;
  label: string;
  value: string;
};
const AddEvents: React.FC<AddEventProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | string>();
  const [endDate, setEndDate] = useState('');
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [deviceId, setdeviceId] = useState<string>('');
  const [inferenceValue, setiInferenceValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const devices = useAppSelector(devicesSelector);
  const [eventInterval, setEventInterval] = useState('None');
  const [eventDuration, setEventDuration] = useState(2);
  const [eventDays, setEventDays] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customCalenderData, setCustomCalenderData] = useState({} as ICustomCalendarData);
  const [repeatEvery, setRepeatEvery] = useState<number>(1);
  const [triggerInterval, setTriggerInterval] = useState<string>('5');
  const [triggerIntervalError, setTriggerIntervalError] = useState<string | null>(null);

  const handleTriggerIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!value || isNaN(numericValue)) {
      setTriggerIntervalError('Please enter a valid number');
    } else if (numericValue < 1 || numericValue > 60) {
      setTriggerIntervalError('Value must be between 1 and 60');
    } else {
      setTriggerIntervalError(null);
    }

    setTriggerInterval(value);
  };

  const {
    control: eventssControl,
    handleSubmit: eventsSubmit,
    formState: { errors },
    watch,
  } = useForm<TCreateEventForm>({
    resolver: yupResolver(createEventSchema),
  });

  const { darkMode } = useDarkMode();

  const addEventFields = watch();

  const onCloseModal = () => {
    setIsModalOpen(false);
    setEventInterval('None');
    setCustomCalenderData({} as ICustomCalendarData);
  };

  const applyCustomRepeat = (data: any) => {
    const { repeatEvery, eventDays, endDate, customCalenderData } = data;

    setEndDate(endDate);

    setEventDays(eventDays);

    setCustomCalenderData(customCalenderData);

    setEventInterval(customCalenderData.interval);

    setIsModalOpen(false);

    setRepeatEvery(repeatEvery);
  };

  const isSubmitButtonDisabled = useMemo(
    () =>
      !!errors.event_title ||
      !!errors.event_days ||
      !!errors.device_ids ||
      startDateError ||
      endDateError ||
      startTimeError ||
      endTimeError ||
      !selectedItems.length,
    [
      endDateError,
      endTimeError,
      errors.device_ids,
      errors.event_days,
      errors.event_title,
      selectedItems.length,
      startDateError,
      startTimeError,
    ],
  );

  const onCreateEvent = async (values: TCreateEventForm) => {
    const obj = {
      event_title: values?.event_title,
      repeat_type: eventInterval === 'None' ? 'Oneoff' : 'Recurring',
      event_interval: eventInterval,
      trigger_interval: +values?.trigger_interval,
      start_date: dayjs(startDate).format('YYYY-MM-DD'),
      start_time: dayjs(startDate).format('HH:mm'),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      inference_type: selectedTypes,
      duration: eventDuration,
      event_days: eventDays.join(','),
      device_ids: selectedItems,
      end_date: endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : dayjs(startDate).format('YYYY-MM-DD'),
      repeatEvery: repeatEvery,
    };

    if (!isSubmitButtonDisabled) {
      try {
        await dispatch(createEvent(obj));
        onClose();
      } catch (err: any) {
        // console.log(err);
      }
    }
  };

  const getAllDevices = async () => {
    await dispatch(getDevices({ sort_by: 'created_at', limit: 100 }));
  };

  useEffect(() => {
    getAllDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const devicesOptions = devices?.map((device: any, index: number) => {
    return { id: index + 1, label: device.device_name, value: device.id };
  });

  const devicesSelected = useCallback(
    (id: number) => {
      if (!selectedItems.includes(id)) {
        setSelectedItems([...selectedItems, id]);
        console.log(selectedItems);
      }
    },
    [selectedItems],
  );
  const TypeSelected = useCallback(
    (id: string) => {
      if (!selectedTypes.includes(id)) {
        setSelectedTypes([...selectedTypes, id]);
      }
    },
    [selectedTypes],
  );

  const [filteredDevicesOptions, setFilteredDevicesOptions] = useState([]);
  useEffect(() => {
    if (devicesOptions) {
      setFilteredDevicesOptions(devicesOptions);
    }
  }, []);
  const [filteredInferenceTypeOptions, setFilteredInferenceTypeOptions] = useState<
    InferenceTypeOption[]
  >([]);
  useEffect(() => {
    setFilteredInferenceTypeOptions(
      inferenceTypeOptions.filter((option: any) => !selectedTypes.includes(option.value)),
    );
  }, [selectedTypes]);
  const handleDelete = (chipToDelete: number) => () => {
    setSelectedItems(selectedItems.filter((it) => it !== chipToDelete));
    setdeviceId('');
  };
  const handleSelectedTypesDelete = (chipToDelete: string) => () => {
    setSelectedTypes(selectedTypes.filter((it) => it !== chipToDelete));
    setiInferenceValue('');
  };

  const handleStartDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setEventInterval(value);

    if (value === 'None') {
      setEndDate('');
      setEventDays([]);
    } else if (value === 'Custom') {
      handleOpenCustomCalender();
    }
  };
  useEffect(() => {
    if (devicesOptions) {
      setFilteredDevicesOptions((prev) =>
        devicesOptions.filter((device: any) => !selectedItems.includes(device.id)),
      );
    }
  }, [selectedItems, devicesOptions]);
  const handleOpenCustomCalender = () => setIsModalOpen(true);

  useEffect(() => {
    if (!selectedItems?.length) {
      setdeviceId('');
    }
  }, [selectedItems]);
  useEffect(() => {
    if (!selectedTypes?.length) {
      setiInferenceValue('');
    }
  }, [selectedTypes]);

  const handleSliderChange = (e: any) => setEventDuration(e.target.value);

  const formatDuration = (duration: any) => {
    const hours = Math.floor(duration);
    const minutes = Math.round((duration - hours) * 60);
    return `${hours} hours ${minutes > 0 ? minutes + ' minutes' : ''}`;
  };

  return (
    <>
      <div className='flex flex-col justify-between h-full'>
        <div className='mb-4'>
          <div className='flex justify-between'>
            <p className='font-semibold text-[24px]'>Create New Event</p>
            <div className='flex items-center pr-4 gap-6'>
              <XMarkIcon className='w-6 font-bold dark:text-white' onClick={onClose} />
            </div>
          </div>

          <div className='mt-7 px-2'>
            <ControlledInput
              required
              control={eventssControl}
              name='event_title'
              label='Event Title'
              error={!!errors.event_title}
              placeholder='Event Title*'
              helperText={(errors?.event_title?.message as string) ?? ''}
            />

            <div className='mt-7'>
              <div className='grid grid-cols-2 md:grid-cols-6 gap-6 -mt-4'>
                <div className={`md:col-span-${eventInterval !== 'None' ? 2 : 4}`}>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Starts
                  </label>
                  <input
                    type='datetime-local'
                    value={startDate as string}
                    onChange={handleStartDateTimeChange}
                    required
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '14px',
                      color: '#6B7280 ',
                      fontWeight: '500',
                    }}
                    className='mt-1 px-2 py-5 block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300 font-medium'
                  />
                </div>

                {eventInterval !== 'None' && (
                  <div className='md:col-span-2'>
                    <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                      Ends
                    </label>
                    <input
                      type='date'
                      value={endDate}
                      onChange={handleEndDateChange}
                      style={{
                        textTransform: 'uppercase',
                        fontSize: '14px',
                        color: '#6B7280 ',
                        fontWeight: '500',
                      }}
                      className='mt-1 px-2 py-4 text-base block w-full border bg-transparent rounded-md focus:outline-none focus:ring focus:ring-blue-300 font-medium'
                    />
                  </div>
                )}

                <div className='grid-cols-1 md:col-span-2'>
                  <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                    Repeats
                  </label>

                  <div className='relative'>
                    <select
                      value={eventInterval}
                      onChange={handleSelectChange}
                      className='mt-1 block w-full border bg-transparent rounded-md px-2 py-4 focus:outline-none focus:ring focus:ring-blue-300 min-w-fit'
                    >
                      {eventRepeatTypeOptions.map((val) => (
                        <option
                          key={val.id}
                          value={val.value}
                          className='text-gray-800 w-full bg-gray-300'
                        >
                          {val.label}
                        </option>
                      ))}
                    </select>

                    {eventInterval === 'Custom' && (
                      <PencilIcon
                        onClick={handleOpenCustomCalender}
                        className='absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500 cursor-pointer'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='grid gap-8 mt-7'>
              <div className='relative'>
                <ControlledInput
                  required
                  value={triggerInterval}
                  defaultValue='5'
                  control={eventssControl}
                  name='trigger_interval'
                  label='Image Capture Interval'
                  error={!!triggerIntervalError}
                  placeholder='1 - 60'
                  rightIcon='Mins'
                  helperText={triggerIntervalError ?? ''}
                  className={`mt-1 block w-full rounded-md border px-3 py-2 shadow-sm ${
                    triggerIntervalError
                      ? 'border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:ring-blue-500'
                  }`}
                  onChange={handleTriggerIntervalChange}
                />

                <div className='absolute bottom-12 right-0 transform -translate-y-1/2 flex items-center bg-gray-200 rounded-full h-4 w-4 mb-1'>
                  <div className='relative group flex justify-center items-center w-full h-full '>
                    <span className='text-[10px] text-gray-500 cursor-pointer leading-none'>?</span>
                    <div className='absolute hidden group-hover:block bg-gray-600 text-white text-[10px] rounded py-0.5 px-1 -left-16 top-full mt-0.5 z-10 w-[100px]'>
                      Trigger interval can be between 1 to 60 minutes.
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700 dark:text-white'>
                  Event Duration
                </label>
                <p className='flex justify-end text-sm text-gray-500 -mt-2'>
                  {formatDuration(eventDuration)}
                </p>
                <div className='relative bg-gray-100 rounded-sm px-2'>
                  <span className='absolute right-0 text-[10px] text-gray-500 pr-2'>hrs</span>
                  <Slider
                    value={eventDuration}
                    onChange={handleSliderChange}
                    min={0}
                    max={24}
                    step={0.25}
                    valueLabelDisplay='auto'
                    valueLabelFormat={(value) => `${value}`}
                    sx={{
                      color: '#bfdbfe',
                      height: 6,
                      '& .MuiSlider-thumb': {
                        backgroundColor: '#0054aa',
                        borderRadius: '50%',
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: '#e5e7eb',
                      },
                    }}
                  />

                  {/* Markers */}
                  <div className='flex justify-between mt-1 text-[10px] text-gray-500 relative -top-4'>
                    {[...Array(25)].map((_, index) => (
                      <span
                        key={index}
                        className={`block text-center min-w-fit ${
                          index % 2 === 0 ? 'font-medium' : 'font-light'
                        }`}
                        style={{ width: '2%', textAlign: 'center' }}
                      >
                        {index % 2 === 0 ? index : ''}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-5 mb-2'>
              <Controller
                control={eventssControl}
                name='device_ids'
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      onChange={(event: SelectChangeEvent) => {
                        onChange(event.target.value);
                        if (event.target.value) {
                          devicesSelected(Number(event?.target?.value));
                          setdeviceId(event?.target?.value);
                        }
                      }}
                      defaultValue=''
                      borderRadius='5px'
                      label='Select Device*'
                      options={filteredDevicesOptions}
                      fontFamily={FontNames.AVENIR_NEXT_PRO}
                      error={!!errors.device_ids}
                      value={deviceId}
                      className='w-full'
                    />
                  );
                }}
              />
              <Stack direction='row' spacing={1} className='mt-2 mb-6'>
                {selectedItems.map((item: any) => {
                  const device = devicesOptions.find((device: any) => device.id === item);
                  return (
                    <Chip
                      label={device?.label || `Device ${item - 1}`}
                      key={item}
                      onDelete={handleDelete(item)}
                      deleteIcon={<DeleteIcon className='dark:text-white' />}
                      sx={{
                        color: darkMode ? Colors.WHITE : '',
                        '.MuiSvgIcon-root': {
                          color: darkMode ? 'white' : '',
                        },
                      }}
                      variant='outlined'
                    />
                  );
                })}
              </Stack>
            </div>
            <div className='mt-7 mb-2'>
              <Controller
                control={eventssControl}
                name='inference_type'
                render={({ field: { onChange } }) => {
                  return (
                    <Select
                      onChange={(event: SelectChangeEvent) => {
                        onChange(event.target.value);
                        if (event.target.value) {
                          TypeSelected(event?.target?.value);
                          setiInferenceValue(event?.target?.value);
                        }
                      }}
                      defaultValue={''}
                      borderRadius='5px'
                      label='Inference Type*'
                      options={filteredInferenceTypeOptions}
                      fontFamily={FontNames.AVENIR_NEXT_PRO}
                      error={!!errors.inference_type}
                      value={inferenceValue}
                      className='w-full'
                    />
                  );
                }}
              />
              <Stack direction='row' spacing={1} className='mt-2 mb-6'>
                {selectedTypes?.length > 0 &&
                  selectedTypes?.map((item: any) => {
                    const inferenceType = inferenceTypeOptions?.find(
                      (option: any) => option.value === item,
                    );
                    return (
                      <Chip
                        label={inferenceType?.label || `inferenceType ${item - 1}`}
                        key={item}
                        onDelete={handleSelectedTypesDelete(item)}
                        deleteIcon={<DeleteIcon />}
                        variant='outlined'
                        sx={{
                          color: darkMode ? Colors.WHITE : '',
                          '.MuiSvgIcon-root': {
                            color: darkMode ? 'white' : '',
                          },
                        }}
                      />
                    );
                  })}
              </Stack>
            </div>
          </div>
        </div>
        <PrimaryButton
          className='w-full md:w-fit self-end mt-2 '
          variant='neutral'
          onClick={() => onCreateEvent(addEventFields)}
          disabled={isSubmitButtonDisabled}
        >
          Create Event
        </PrimaryButton>
      </div>

      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          className='w-96 absolute top-20 right-12 rounded-xl p-6 object-cover border-0 bg-white'
          useClassNameOnly={true}
        >
          <EventCustomCalender
            close={onCloseModal}
            startDate={startDate}
            applyCustomRepeat={applyCustomRepeat}
            customCalendarData={customCalenderData}
            setNum={setRepeatEvery}
          />
        </CustomModal>
      )}
    </>
  );
};

export default AddEvents;
