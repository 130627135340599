import { useEffect, useState } from 'react';
import Slider from 'react-slick';

import Container from '../../components/shared/Container';
import CallToAction from '../../components/shared/CallToAction';
import Badge from '../../components/shared/Badge';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const OtherFeatures = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dotsClass: 'industries-dots',
  };

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(document.body.classList.contains('dark'));
  }, []);

  const analyticsInfo = [
    {
      image: isDarkMode ? 'images/darkpeople.svg' : 'images/users.svg',
      title: 'People Counting & Management',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
    },
    {
      image: isDarkMode ? 'images/darkmovement.svg' : 'images/movement.svg',
      title: 'Movement Detection',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
    },
    {
      image: isDarkMode ? 'images/darkobject.svg' : 'images/object.svg',
      title: 'Object Detection & Recognition',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
    },
    {
      image: isDarkMode ? 'images/darksecurity.svg' : 'images/privacy.svg',
      title: 'Privacy Protection',
      description:
        'We utilize advanced AI techniques for accurate counting, crowd flow analysis, object detection, and insightful analytics',
    },
  ];

  return (
    <Container className='flex flex-col gap-x-10 gap-y-10 xl:py-[70px] lg:py-16 py-14'>
      <div>
        <div className='center-child flex-col'>
          <Badge title='Features' />
          <h3 className='text-accent dark:text-dark-text md:text-[40px] text-[18px] font-semibold capitalize font-Avenir-Next-Pro'>
            <span className='text-main-text dark:text-white'>Other Features,</span> you’ll enjoy
          </h3>
          <p className='text-[16px] font-light text-sub-title dark:text-[#D1D1D1] md:w-[800px] text-center '>
            HawkEyeVisionTek Cutting-Edge AI-Powered People Counting Solutions: Unleash the Power of
            Data and Insights with Our Technology Core Features
          </p>
        </div>

        <div className='my-10'>
          <Slider {...settings}>
            <div>
              <CallToAction
                image={analyticsInfo[0].image}
                title={analyticsInfo[0].title}
                description={analyticsInfo[0].description}
                isBg
              />
            </div>
            <div>
              <CallToAction
                image={analyticsInfo[1].image}
                title={analyticsInfo[1].title}
                description={analyticsInfo[1].description}
                isBg
              />
            </div>
            <div>
              <CallToAction
                image={analyticsInfo[2].image}
                title={analyticsInfo[2].title}
                description={analyticsInfo[2].description}
                isBg
              />
            </div>
            <div>
              <CallToAction
                image={analyticsInfo[3].image}
                title={analyticsInfo[3].title}
                description={analyticsInfo[3].description}
                isBg
              />
            </div>
          </Slider>
        </div>
      </div>
    </Container>
  );
};

export default OtherFeatures;
